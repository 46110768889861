import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DomainService {
  // private readonly AUS_DOMAIN_URL = 'https://ausmilowidget.tech-active.com';
  // private readonly IND_DOMAIN_URL = 'https://smilowidget.tech-active.com';

  /* Updated DEV Domain Url */
  private readonly AUS_DOMAIN_URL = 'https://app-d1.smilo.ai';
  private readonly IND_DOMAIN_URL = 'https://app-d1.smilo.health';
  public currentDomain: any;
  // Get the current URL
  public fullUrl = window.location.href;
  // Create a new URL object and Extract the base URL (origin)
  public currentDomainUrl = new URL(this.fullUrl).origin;
  public local = 'http://localhost'; // for developing purpose,add this variable in regionDomain methods to check aus code     

  constructor() {
  }

  public isAusRegionDomain(domainUrl: any) {
    this.currentDomain = domainUrl != '' ? domainUrl : this.currentDomainUrl;
    // if (this.currentDomain.includes(this.AUS_DOMAIN_URL) || this.currentDomain.includes(this.local)) {
    if (this.currentDomain.includes(this.AUS_DOMAIN_URL)) {
      return true;
    } else {
      return false;
    }
  }
}

import { Component, ChangeDetectionStrategy, Inject, ViewChild, AfterViewInit, OnInit, ChangeDetectorRef } from '@angular/core';
import { StyleRenderer, WithStyles, lyl, ThemeRef, ThemeVariables } from '@alyle/ui';
import { LyDialogRef, LY_DIALOG_DATA } from '@alyle/ui/dialog';
import { STYLES as SLIDER_STYLES } from '@alyle/ui/slider';
import { Services } from '../../helpers/Services';
import { Router, ActivatedRoute } from '@angular/router';
import {
  ImgResolution,
  LyImageCropper,
  ImgCropperConfig,
  ImgCropperEvent,
  ImgCropperErrorEvent,
  ImgCropperLoaderConfig
} from '@alyle/ui/image-cropper';

const STYLES = (_theme: ThemeVariables, ref: ThemeRef) => {
  ref.renderStyleSheet(SLIDER_STYLES);
  const slider = ref.selectorsOf(SLIDER_STYLES);
  return {
    cropper: lyl`{
      // max-width: 400px
      height: 400px
    }`,
    sliderContainer: lyl`{
      position: relative
      ${slider.root} {
        position: absolute
        left: 0
        right: 0
        margin: auto
        top: -32px
      }
    }`,
    slider: lyl`{
      padding: 1em
    }`
  };
};

@Component({
  selector: 'app-aus-cropper-dialog',
  templateUrl: './aus-cropper-dialog.component.html',
  styleUrls: ['./aus-cropper-dialog.component.css']
})
export class AusCropperDialogComponent implements OnInit {
  appservices: Services;
  readonly classes = this.sRenderer.renderSheet(STYLES);
  scale: number;
  @ViewChild(LyImageCropper, { static: true }) cropper: LyImageCropper;
  myConfig: ImgCropperConfig = {
    width: 300,
    height: 300,
    type: 'image/png',
    keepAspectRatio: false,
    output: {
      width: 700,
      height: 700
    },
    resizableArea: true
  };

  constructor(
    @Inject(LY_DIALOG_DATA) private event: Event,
    readonly sRenderer: StyleRenderer,
    public dialogRef: LyDialogRef, 
    private router: Router, 
    private route: ActivatedRoute,
    private _cd: ChangeDetectorRef
  ) {
    this.appservices = new Services;
    if (router.url.search('/upload-teeth-photos') >= 0 || router.url.search("/upload-teeth-images") >= 0) {
      this.myConfig = {
        width: 300, height: 300, 
        keepAspectRatio: false,
        resizableArea: true, 
        type: 'image/png',
      };
    } else if (router.url.search('/upload-smile-photo') >= 0) {
      this.myConfig = {
        width: 300, height: 300, keepAspectRatio: true,
        // width: 500, height: 350, keepAspectRatio: true,
        resizableArea: true, type: 'image/png',
        output: {
          width: 700,
          height: 700
        },
      };
    }
  }
  ngOnInit(): void {
    if (this.router.url == "/upload-teeth-photos" || this.router.url == "/upload-teeth-images") {
      this.currentOhrImage = this.appservices.getSession(
        "currentOhrImage",
        false
      );
      this.currentOhrImageCoordinates = this.appservices.getSession(
        "currentOhrImageCoordinates",
        false
      );

      this.uploadedImageHeight = this.appservices.getSession(
        "uploadedImageHeight",
        false
      );
      this.uploadedImageWidth = this.appservices.getSession(
        "uploadedImageWidth",
        false
      );
    }
  }
  
  /**
  * ngAfterViewInit - Load image when dialog animation has finished
  * @since   1.0
  * @returns {String}  base64
  */
  // ngAfterViewInit() {
  //   // Load image when dialog animation has finished
  //   this.dialogRef.afterOpened.subscribe(() => {
  //     this.cropper.selectInputEvent(this.event);
  //   });
  // }
  currentOhrImageCoordinates: any;
  currentOhrImage: any;
  detectionClass: any;
  detectionMessage: any;
  heightIssue: boolean = false;
  uploadedImageHeight: any;
  uploadedImageWidth: any;
  currentSelectedRow: any;
  
  ngAfterViewInit() {
    if (this.router.url != "/upload-teeth-photos" && this.router.url != "/upload-teeth-images") {
      this.dialogRef.afterOpened.subscribe(() => {
        this.cropper.selectInputEvent(this.event);
      });
      return;
    }

    if (
      this.currentOhrImageCoordinates != "" &&
      this.currentOhrImageCoordinates != undefined
    )
      this.currentOhrImageCoordinates = JSON.parse(
        this.currentOhrImageCoordinates
      );

    if (
      this.currentOhrImageCoordinates == "" ||
      !this.currentOhrImageCoordinates.status
    ) {
      //this.detectionClass = "warning-msg dialogue_box_msg";
      this.detectionClass = this.currentOhrImageCoordinates.message;
      // this.detectionMessage = "Sorry! We could not able to identify face / tooth part, please move the cropping part manually.";
      this.dialogRef.afterOpened.subscribe(() => {
        this.cropper.selectInputEvent(this.event);
      });
    }
    else {
      //this.detectionClass = "success-msg dialogue_box_msg";
      this.detectionClass = this.currentOhrImageCoordinates.message;
      // this.detectionMessage = "We have identified the tooth part, Please feel free to move the cropping part.";
      var areaWidth = 0;
      var areaHeight = 0;
      var xOrigin = 0;

      var result = this.currentOhrImageCoordinates.result;
      var leftMouth = result["mouth"]["left"];
      var rightMouth = result["mouth"]["right"];
      var centerMouth = result["mouth"]["center"];

      var upperLips = result["lips"]["upper"];
      var lowerLips = result["lips"]["lower"];

      var centerMinusLeftX = centerMouth.x - leftMouth.x;
      var centerMinusLeftY = centerMouth.y - leftMouth.y;
      var centerMinusRightX = rightMouth.x - centerMouth.x;
      var centerMinusRightY = rightMouth.y - centerMouth.y;

      areaWidth = rightMouth.x - leftMouth.x;
      areaHeight = lowerLips.y - upperLips.y;
      if (areaWidth < 200 || areaHeight < 200) {
        areaWidth += 20;
        areaHeight += 20;
        this.heightIssue = true;
      }

      if (screen.width > 450) {
        xOrigin = 10;
      }
      var localURL = this.currentOhrImage.toString();
      let scale = 1;

      if (Number(this.uploadedImageWidth) > 1000) {
        scale = 0.5;
        if (screen.width < 450) {
          scale = 0.25;
          areaWidth = areaWidth / 3;
          areaHeight = areaHeight / 3;
        } else {
          areaWidth = areaWidth / 2;
          areaHeight = areaHeight / 2;
        }
      }
      const config: ImgCropperLoaderConfig = {
        //scale: 0.745864772531767,
        scale: scale,
        xOrigin: centerMouth.x - xOrigin,
        yOrigin: centerMouth.y,
        areaWidth: areaWidth,
        areaHeight: areaHeight,
        rotation: 0,
        originalDataURL: JSON.parse(localURL),
      };
      this._cd.detectChanges();
      setTimeout(() => {
        this.cropper.loadImage(config);
      }, 100);
    }

    if (this.router.url != "/upload-teeth-photos" && this.router.url != "/upload-teeth-images") {
      this.detectionMessage = "";
      this.detectionClass = "";
    }
    this._cd.detectChanges();
  }

  onReady(e: ImgCropperEvent) {
    let url = this.router.url;
    if (url.search('/upload-teeth-photos') >= 0 && e.width >= 700) {
      this.myConfig = {
        width: 700, height: 700, keepAspectRatio: false,
        resizableArea: true, type: 'image/png',
        output: {
          width: 700,
          height: 700
        }
      };
    } else if (url.search('/upload-smile-photo') >= 0 && e.width >= 700) {
      this.myConfig = {
        width: 700, height: 700, keepAspectRatio: true,
        resizableArea: true, type: 'image/png',
        // output: ImgResolution.OriginalImage
        output: {
          width: 700,
          height: 700
        }
      };
    }
  }

  /**
  * onCropped - Method after cropped Image finished
  * @since   1.0
  * @returns {String}
  */
  onCropped(e: ImgCropperEvent) {
    // console.log(e)
    // this.showDebugMessage('cropped img: '+ e);
  }
  /**
  * onLoaded - Method  Image loaded for crop
  * @since   1.0
  * @returns {String} 
  */
  onLoaded(e: ImgCropperEvent) {
    // console.log(e);
    let imgObj = { width: e.width, height: e.height };
    this.appservices.setSession("imgObj", imgObj, true);
    // console.log(imgObj)
    // GlobalHelper.showDebugMessage('img loaded'+ e);
  }
  /**
  * onError - Method  Image cropped error triggered
  * @since   1.0
  * @returns {String} 
  */
  onError(e: ImgCropperErrorEvent) {
    console.log(e);
    console.warn(`'${e.name}' is not a valid image`, e);
    // Close the dialog if it fails
    this.dialogRef.close();
  }

}


<div class="dynamic_wrapper">
    <div class="vs-messages"></div>
    <div class="dms_wrapper">
        <app-back-to-home></app-back-to-home>
        <div class="profile-update-msg text-center success-msg" [ngClass]="profileUpdatedSuccessfully">Profile has been
            updated successfully</div>
        <div class="profile-update-msg text-center err-msg" [ngClass]="profileUpdatedError">{{profileUpdatedErrorMsg}}
        </div>
        <div class="profile-update-msg text-center success-msg" [ngClass]="deleted_msg_class">Your Account has been
            deleted successfully</div>

        <div class="profile-update-msg text-center success-msg" [ngClass]="passwordUpdatedSuccessfully">Your password
            has been updated successfully</div>
        <div class="profile-update-msg text-center success-msg" [ngClass]="patientInfoUpdatedSuccessfully">Patient Info
            has been updated successfully</div>
        <div class="profile-update-msg text-center err-msg" [ngClass]="passwordUpdatedError">{{passwordUpdatedErrorMsg}}
        </div>
        <div class="profile-update-msg text-center err-msg" [ngClass]="patientInfoUpdatedError">
            {{patientInfoUpdatedErrorMsg}}</div>
        <div class="profile-update-msg text-center success-msg" [ngClass]="MedicalHistoryFormSUpdatedSuccessfully">
            Medical History form has been updated successfully</div>
        <div class="profile-update-msg text-center err-msg" [ngClass]="MedicalHistoryFormSUpdatedError">
            {{MedicalHistoryFormSUpdatedErrorMsg}}</div>

        <div class="dms_content_profile">
            <div class="mb-2" *ngIf="showSuccessPage">
                <a href="javascript:;" class="back-to-home" (click)="BackToHome()">
                    < Back to Home</a>
            </div>

            <div class="medical-history-form-submitted" *ngIf="showSuccessPage">
                <div class="row">
                    <div class="col-md-12  mt-5">
                        <img height="75" class="congratulations-img" src="assets/images/green_tick.png" />
                        <div class="mt-2 text-center">
                            <h1> Thank you! </h1>
                        </div>
                        <div class="mt-2 text-center">
                            <p> Your medical history form submitted successfully. </p>
                        </div>
                    </div>

                    <div class="col-md-12  mt-5">
                        <div class="mt-5 text-center">
                            <p class="explore-service-text"> Explore our other services</p>
                        </div>

                        <div class="row px-4 pb-md-0">
                            <div class="col-md-6 mhf-btns mt-3">
                                <img (click)="navigateToOHR()" src="assets/images/OHR-button.png" />
                            </div>

                            <div class="col-md-6 mhf-btns mt-3 pb-4">
                                <img (click)="navigateToDMS()" src="assets/images/DMS-button.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="loader_sec" *ngIf="medicalHistoryFormLoading&&!showSuccessPage">
                <div class="loading_div analysing_sec">
                    <div class="img_section text-center position-relative">
                        <div class="analyzing_text">
                            <div class="title_sec mt-4">
                            </div>
                        </div>

                        <div class="loader_img_sec">
                            <img src="assets/images/loader.gif" />
                        </div>
                        <div class="analyzing_text mb-4">
                            <p class="thank_you_head"><b>Please wait while the Medical History form Loads</b></p>
                            <p>Please do not press back button or refresh button</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="sec_inner_content1 p-0" *ngIf="!medicalHistoryFormLoading&&!showSuccessPage">
                <div class="body_content">
                    <div class="profile_content_sec">
                        <ul class="nav nav-tabs app_content_sidebar_menu myprofile_tabs clearfix">
                            <li class="tablinks active" (click)="show_medical_history_forms(false)">
                                <a href="#patient_info" #myProfile data-toggle="tab" class="active">Patient Info</a>
                            </li>
                            <li class="tablinks" (click)="show_medical_history_forms(true)">
                                <a href="#medical_history" id="medical_history_form_href" data-toggle="tab">Medical
                                    History</a>
                            </li>
                        </ul>
                        <div id="profile_validation_msgs" class="status_msg visibility_hidden"></div>
                        <div>
                            <div class="tab-content tab_content_sec clearfix">
                                <div class="patient_info tab-pane active" id="patient_info">
                                    <div class="card-body tab-content" *ngIf="!showMedicalHistoryForms">
                                        <form autocomplete="off" [formGroup]="patientInfoForm"
                                            (ngSubmit)="onSubmit(patientInfoForm,'patientInfoForm')"
                                            class="contact_form" action="" enctype="multipart/form-data" method="post"
                                            id="patientInfoForm" name="patientInfoForm">
                                            <div class="tab-pane active" id="tabc" role="tabpanel">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group common_selectfield">
                                                            <label for="title"
                                                                class="margin_bottom_5 label_input">Title</label>
                                                            <div class="input-field position-relative">
                                                                <select (change)="onUpdateTitle($event)" name="title"
                                                                    id="title" class="form-control"
                                                                    formControlName="title">
                                                                    <option value="Mr" [selected]='title=="Mr"'>Mr
                                                                    </option>
                                                                    <option value="Miss" [selected]='title=="Miss"'>Miss
                                                                    </option>
                                                                    <option value="Mrs" [selected]='title=="Mrs"'>Mrs
                                                                    </option>
                                                                    <option value="Prof" [selected]='title=="Prof"'>Prof
                                                                    </option>
                                                                    <option value="Dr" [selected]='title=="Dr"'>Dr
                                                                    </option>
                                                                    <option value="Ms" [selected]='title=="Ms"'>Ms
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="first_name"
                                                                class="margin_bottom_5 label_input">First Name <span
                                                                    class="err-msg">*</span> </label>
                                                            <div class="input-field">
                                                                <input id="first_name" formControlName="first_name"
                                                                    (keyup)="onUpdateProfileEnterData($event)"
                                                                    (focusout)="validateInput($event)" name="first_name"
                                                                    type="text"
                                                                    class="form-control firstletter_capital shadow-none"
                                                                    value="{{firstName}}" maxlength="15"
                                                                    placeholder="" />
                                                            </div>
                                                            <div class="err-msg"
                                                                *ngIf="ispatientInfoFormSubmitted===true&& patientInfoForm.get('first_name').hasError('required')">
                                                                Please enter the First Name
                                                            </div>
                                                            <div class="err-msg"
                                                                *ngIf="ispatientInfoFormSubmitted===true&& patientInfoForm.get('first_name').hasError('minlength')">
                                                                First Name should be Min 3 characters
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="last_name"
                                                                class="margin_bottom_5 label_input">Last Name <span
                                                                    class="err-msg">*</span> </label>
                                                            <div class="input-field">
                                                                <input id="last_name" formControlName="last_name"
                                                                    (keyup)="onUpdateProfileEnterData($event)"
                                                                    (focusout)="validateInput($event)" name="last_name"
                                                                    type="text"
                                                                    class="form-control firstletter_capital shadow-none"
                                                                    value="{{lastName}}" maxlength="15"
                                                                    placeholder="" />
                                                            </div>
                                                            <div class="err-msg"
                                                                *ngIf="ispatientInfoFormSubmitted===true&& patientInfoForm.get('last_name').hasError('required')">
                                                                Last Name should be Min 1 character(s)
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 form-group mb-6">
                                                        <label for="dob">Date of Birth <span class="err-msg">*</span>
                                                        </label>
                                                        <div class="input-group">
                                                            <input id="dob" readonly class="form-control" placeholder=""
                                                                name="dob" [maxDate]="maxDate" ngbDatepicker
                                                                [minDate]="{year: 1900, month: 1, day: 1}"
                                                                #date="ngbDatepicker" [(ngModel)]="dob"
                                                                formControlName="dob">
                                                            <div class="input-group-append">
                                                                <button class="btn btn-fill-color icon-Calendar"
                                                                    (click)="date.toggle()" type="button">
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="err-msg"
                                                            *ngIf="ispatientInfoFormSubmitted===true&& patientInfoForm.get('dob').hasError('required')">
                                                            DOB should not be Empty
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="home_phone"
                                                                class="margin_bottom_5 label_input">Home phone</label>
                                                            <div class="input-field">
                                                                <input (keyup)="onEnterData($event)" maxlength="10"
                                                                    onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))"
                                                                    (keydown.space)="$event.preventDefault();"
                                                                    (focusout)="validateInput($event)"
                                                                    class="form-control" type="text" ng2TelInput
                                                                    (hasError)="signInHasError($event)"
                                                                    (ng2TelOutput)="signInGetNumber($event)"
                                                                    (intlTelInputObject)="hometelInputObject($event)"
                                                                    (countryChange)="homePhoneOnCountryChange($event)"
                                                                    [value]="patientInfoForm.get('home_phone').value"
                                                                    (enableAutoCountrySelect)="true" placeholder=""
                                                                    formControlName="home_phone">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="email_address"
                                                                class="margin_bottom_5 label_input">Email
                                                                Address</label>
                                                            <div class="input-field">
                                                                <input id="email_address" formControlName="email"
                                                                    (keyup)="onUpdateProfileEnterData($event)"
                                                                    (focusout)="validateInput($event)"
                                                                    name="email_address" type="text"
                                                                    class="form-control shadow-none" readonly />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="mobile_number"
                                                                class="margin_bottom_5 label_input">Mobile
                                                                number</label>
                                                            <div class="input-field">
                                                                <input (keyup)="onEnterData($event)" maxlength="10"
                                                                    onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))"
                                                                    (keydown.space)="$event.preventDefault();"
                                                                    (focusout)="validateInput($event)"
                                                                    class="form-control" type="text" ng2TelInput
                                                                    (hasError)="signInHasError($event)"
                                                                    (ng2TelOutput)="signInGetNumber($event)"
                                                                    (intlTelInputObject)="MobileNumberInputObject($event)"
                                                                    (countryChange)="MobileNumberOnCountryChange($event)"
                                                                    [value]="" placeholder=""
                                                                    formControlName="mobile_number">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group common_selectfield">
                                                            <label for="gender"
                                                                class="margin_bottom_5 label_input">Gender</label>
                                                            <div class="input-field position-relative">
                                                                <select (change)="onUpdateGender($event)" name="gender"
                                                                    id="gender" class="form-control"
                                                                    formControlName="gender">
                                                                    <option value="m" [selected]='gender=="m"'>Male
                                                                    </option>
                                                                    <option value="f" [selected]='gender=="f"'>Female
                                                                    </option>
                                                                    <option value="o" [selected]='gender=="o"'>Others
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label for="address" class="margin_bottom_5 label_input">Home
                                                            Address</label>
                                                        <div class="text_box">
                                                            <textarea
                                                                class="form-control home_address firstletter_capital date_pos input_text_field form-control mx-auto"
                                                                autocomplete="off" maxlength="100" rows="4" trim="blur"
                                                                placeholder="" (keyup)="onUpdateHomeAddress($event)"
                                                                [value]="home_address"
                                                                formControlName="home_address">{{home_address}}</textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="occupation"
                                                                class="margin_bottom_5 label_input">Occupation</label>
                                                            <div class="input-field">
                                                                <input id="occupation"
                                                                    (keyup)="onUpdateOccupation($event)"
                                                                    (focusout)="validateInput($event)" name="occupation"
                                                                    type="text"
                                                                    class="form-control firstletter_capital shadow-none"
                                                                    [value]="occupation" maxlength="30" placeholder=""
                                                                    formControlName="occupation" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 pt-3">
                                                        <div class="form-group">
                                                            <label for="emergency_contact_name"
                                                                class="margin_bottom_5 label_input">Emergency contact
                                                                person</label>
                                                            <div class="input-field ">
                                                                <input id="emergency_contact_name"
                                                                    (keyup)="onUpdateEmergencyContact($event)"
                                                                    (focusout)="validateInput($event)"
                                                                    name="emergency_contact_name" type="text"
                                                                    class="form-control firstletter_capital shadow-none"
                                                                    value="{{emergency_contact_name}}" maxlength="15"
                                                                    placeholder=""
                                                                    formControlName="emergency_contact_name" />
                                                            </div>
                                                            <div class="input-field pb-3">
                                                                <input (keyup)="onEnterData($event)"
                                                                    onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))"
                                                                    (keydown.space)="$event.preventDefault();"
                                                                    (focusout)="validateInput($event)"
                                                                    class="form-control firstletter_capital" type="text"
                                                                    ng2TelInput (hasError)="signInHasError($event)"
                                                                    (ng2TelOutput)="signInGetNumber($event)"
                                                                    (intlTelInputObject)="EmergencyContactInputObject($event)"
                                                                    (countryChange)="EmergencyContactOnCountryChange($event)"
                                                                    [value]="" placeholder=""
                                                                    formControlName="emergency_contact">
                                                            </div>
                                                            <label for="doctor"
                                                                class="margin_bottom_5 label_input">Doctor</label>
                                                            <div class="input-field">
                                                                <input id="doctor" (keyup)="onUpdateDoctor($event)"
                                                                    (focusout)="validateInput($event)" name="doctor"
                                                                    type="text"
                                                                    class="form-control firstletter_capital shadow-none"
                                                                    value="{{doctor}}" maxlength="15" placeholder=""
                                                                    formControlName="doctor" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6  pt-3 health-fund-wrapper">
                                                        <div class="">
                                                            <label for="health_fund"
                                                                class="margin_bottom_5 label_input">Health Fund</label>
                                                            <div class="input-field position-relative">
                                                                <select name="health_fund" id="health_fund"
                                                                    (change)="onUpdateHealthFund($event)"
                                                                    class="form-control" formControlName="health_fund">
                                                                    <option value="no"
                                                                        [selected]="healthFundStatus=='no'">No I dont
                                                                        have any insurance </option>
                                                                    <option value="yes"
                                                                        [selected]="healthFundStatus=='yes'">Australian
                                                                        Unity Health Limited</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="row d-block"
                                                            [ngClass]="healthFundStatus=='yes' ? 'visibility_block' : 'visibility_none'">
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <!-- <label for="insurance_number" class="margin_bottom_5 label_input">Enter Insurance Number</label> -->
                                                                    <div class="input-field">
                                                                        <input id="insurance_number"
                                                                            (keyup)="onUpdateInsuranceNumber($event)"
                                                                            (focusout)="validateInput($event)"
                                                                            name="insurance_number" type="text"
                                                                            class="form-control firstletter_capital shadow-none"
                                                                            value="{{insurance_number}}" maxlength="20"
                                                                            placeholder=""
                                                                            formControlName="insurance_number"
                                                                            placeholder="Insurance Number" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label for="health_unique_identified_number"
                                                                    class="margin_bottom_5 label_input">Enter Health
                                                                    Unique Identified Number</label>
                                                                <div class="input-field">
                                                                    <input id="health_unique_identified_number"
                                                                        (keyup)="onUpdateProfileEnterData($event)"
                                                                        (focusout)="validateInput($event)"
                                                                        name="health_unique_identified_number"
                                                                        type="text"
                                                                        class="form-control firstletter_capital shadow-none"
                                                                        value="{{health_unique_identified_number}}"
                                                                        maxlength="20" placeholder=""
                                                                        formControlName="health_unique_identified_number" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row float-right">
                                                    <div class="button_sec">
                                                        <div
                                                            class="button-sec-ul d-flex align-items-center justify-content-between">
                                                            <div class="d-flex align-items-center responsive_btns">
                                                                <div class="default_btn default_btn_bg p-0">
                                                                    <a href="javascript:;" class="d-block"><input
                                                                            id="save_patient_btn" type="submit"
                                                                            class="btn button_links btn_width_2 continue_btn"
                                                                            value="Save & Next" /></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane" id="tabb" role="tabpanel"
                                                *ngIf="showMedicalHistoryForms">
                                                <div class="row">
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div class="medical_history tab-pane" id="medical_history">
                                    <!-- <div class="change-password-text">
                                        <p class="font_12">You can change your password by entering your current password and a new password in the relevant boxes.</p>
                                    </div> -->

                                    <div class="sec_inner_content1 p-0" *ngIf="showMedicalHistoryForms">
                                        <form autocomplete="off" [formGroup]="updateMedicalHistoryForm"
                                            (ngSubmit)="onSubmit(updateMedicalHistoryForm,'updateMedicalHistoryForm')"
                                            class="contact_form" action="" enctype="multipart/form-data" method="post"
                                            id="update_medical_history_form" name="update_medical_history_form">
                                            <div class="body_content">
                                                <div class="profile_content_sec no-border-radius">
                                                    <div class="tab-content tab_content_sec clearfix">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="col-md-12">
                                                                    <h4 class="Nunito-Bold">Medical History</h4>
                                                                    <p class="mt-1 font_12 text_grey">(Please answer
                                                                        this section as accurately as possible. All
                                                                        records are confidential)</p>
                                                                </div>

                                                                <div class="col-md-12 mt-4">
                                                                    <div class="row">
                                                                        <div class="col-md-4">
                                                                            <div class="form-group">
                                                                                <label for="high_frquency_sugar"
                                                                                    class="margin_bottom_5 label_input">High
                                                                                    Sugar Frequency?</label>
                                                                                <ul class="health-fund text-center">
                                                                                    <li>
                                                                                        <input type="radio"
                                                                                            id="high_frquency_sugar_yes"
                                                                                            (change)="high_frquency_sugar('yes')"
                                                                                            name="high_frquency_sugar"
                                                                                            [checked]="highFrequencySugar=='yes'"
                                                                                            value="Yes" />
                                                                                        <label
                                                                                            for="high_frquency_sugar_yes">YES</label>
                                                                                    </li>
                                                                                    <li>
                                                                                        <input type="radio"
                                                                                            id="high_frquency_sugar_no"
                                                                                            (change)="high_frquency_sugar('no')"
                                                                                            name="high_frquency_sugar"
                                                                                            [checked]="highFrequencySugar=='no'"
                                                                                            value="No" />
                                                                                        <label
                                                                                            for="high_frquency_sugar_no">NO</label>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4">
                                                                            <div class="form-group mb-2">
                                                                                <label for="fizzy_acidic_drinks"
                                                                                    class="margin_bottom_5 label_input">Lots
                                                                                    of fizzy acidic drinks?</label>
                                                                                <ul class="health-fund text-center">
                                                                                    <li>
                                                                                        <input type="radio"
                                                                                            id="fizzy_acidic_drinks_yes"
                                                                                            (click)="fizzy_acidic_drinks('yes')"
                                                                                            [checked]="fizzyAcidicDrinks=='yes'"
                                                                                            name="fizzy_acidic_drinks"
                                                                                            value="Yes" />
                                                                                        <label
                                                                                            for="fizzy_acidic_drinks_yes">YES</label>
                                                                                    </li>
                                                                                    <li>
                                                                                        <input type="radio"
                                                                                            id="fizzy_acidic_drinks_no"
                                                                                            (click)="fizzy_acidic_drinks('no')"
                                                                                            [checked]="fizzyAcidicDrinks=='no'"
                                                                                            name="fizzy_acidic_drinks"
                                                                                            value="No" />
                                                                                        <label
                                                                                            for="fizzy_acidic_drinks_no">NO</label>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <!-- <div class="col-md-4" *ngIf="fizzyAcidicDrinks=='yes'">
                                                                            <label for="fizzy_acidic_drinks_details" class=" label_input">Acidic drinks details</label>
                                                                            <input id="fizzy_acidic_drinks_details" name="fizzy_acidic_drinks_details" type="text" class="form-control shadow-none mt-1" [value]="fizzy_acidic_drinks_details" placeholder="" formControlName="fizzy_acidic_drinks_details" />
                                                                        </div> -->
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-12">
                                                                    <div class="row">
                                                                        <div class="col-md-4">
                                                                        </div>

                                                                        <div class="col-md-4 mt-2"
                                                                            *ngIf="fizzyAcidicDrinks=='yes'">
                                                                            <label for="fizzy_acidic_drinks_details"
                                                                                class=" label_input">Acidic drinks
                                                                                details</label>
                                                                            <input id="fizzy_acidic_drinks_details"
                                                                                name="fizzy_acidic_drinks_details"
                                                                                type="text"
                                                                                class="form-control shadow-none mt-1"
                                                                                [value]="fizzy_acidic_drinks_details"
                                                                                placeholder=""
                                                                                formControlName="fizzy_acidic_drinks_details" />
                                                                        </div>

                                                                        <div class="col-md-4">
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-12 mt-4">
                                                                    <h4 class="Nunito-Bold font_16">Habits</h4>
                                                                </div>
                                                                <div class="col-md-12 mt-1">
                                                                    <div class="row">
                                                                        <div class="col-md-4">
                                                                            <div class="form-group">
                                                                                <div class="input-field">
                                                                                    <label for="smoke_per_day"
                                                                                        class="margin_bottom_5 label_input">Smoke
                                                                                        (Per Day)</label>
                                                                                    <input id="smoke_per_day"
                                                                                        (keyup)="onEnterData($event)"
                                                                                        onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))"
                                                                                        (keydown.space)="$event.preventDefault();"
                                                                                        (focusout)="validateInput($event)"
                                                                                        name="smoke_per_day" type="text"
                                                                                        class="form-control shadow-none"
                                                                                        value="" maxlength="3"
                                                                                        placeholder=""
                                                                                        value="{{smoke_per_day}}"
                                                                                        formControlName="smoke_per_day" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4">
                                                                            <div class="form-group">
                                                                                <div class="input-field">
                                                                                    <label for="tobacco_per_day"
                                                                                        class="margin_bottom_5 label_input">Chew
                                                                                        Tobacco (Per Day)</label>
                                                                                    <input id="tobacco_per_day"
                                                                                        (keyup)="onEnterData($event)"
                                                                                        onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))"
                                                                                        (keydown.space)="$event.preventDefault();"
                                                                                        (focusout)="validateInput($event)"
                                                                                        name="tobacco_per_day"
                                                                                        type="text"
                                                                                        class="form-control shadow-none"
                                                                                        value="" maxlength="3"
                                                                                        placeholder=""
                                                                                        value="{{tobacco_per_day}}"
                                                                                        formControlName="tobacco_per_day" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4">
                                                                            <div class="form-group">
                                                                                <div class="input-field">
                                                                                    <label for="alcohol_per_day"
                                                                                        class="margin_bottom_5 label_input">Alcohol
                                                                                        (Units Per Week)</label>
                                                                                    <input id="alcohol_per_day"
                                                                                        (keyup)="onEnterData($event)"
                                                                                        onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))"
                                                                                        (keydown.space)="$event.preventDefault();"
                                                                                        (focusout)="validateInput($event)"
                                                                                        name="alcohol_per_day"
                                                                                        type="text"
                                                                                        class="form-control shadow-none"
                                                                                        value="" maxlength="3"
                                                                                        placeholder=""
                                                                                        value="{{alcohol_per_day}}"
                                                                                        formControlName="alcohol_per_day" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="sec_inner_content1 py-2" *ngIf="showMedicalHistoryForms">
                                                <div class="body_content">
                                                    <div class="profile_content_sec ">
                                                        <div class="tab-content tab_content_sec clearfix">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="row">
                                                                        <div class="col-md-4 line_height_27">
                                                                            <h4
                                                                                class="Nunito-Bold font_16 sub-heading-padding">
                                                                                Warnings</h4>

                                                                            <label class="mt-1 p-1 d-flex"
                                                                                formArrayName="warnings"
                                                                                *ngFor="let warning of warningsFormArray.controls; let i = index">
                                                                                <div class="checkBoxOptionsWrapper">
                                                                                    <div
                                                                                        *ngIf="checkWarningIds(medical_history_warnings[i].id);then warningIdFound else warningIdNotFound">
                                                                                        here is ignored</div>
                                                                                    <ng-template #warningIdFound>
                                                                                        <input type="checkbox"
                                                                                            class="checkBoxOptions"
                                                                                            [checked]="true"
                                                                                            [ngModel]="true"
                                                                                            [formControlName]="i"
                                                                                            (change)="true" #checkBox>
                                                                                        {{medical_history_warnings[i].name}}
                                                                                        <div class="custom-subinput-padding"
                                                                                            *ngIf="medical_history_warnings[i].has_details == true && checkBox.checked">
                                                                                            <input type="text"
                                                                                                class="text firstletter_capital"
                                                                                                [value]="getWarningsValue(medical_history_warnings[i].id)"
                                                                                                id="medical_history_text_warning_{{medical_history_warnings[i].id}}"
                                                                                                placeholder="Details" />
                                                                                        </div>
                                                                                    </ng-template>
                                                                                    <ng-template #warningIdNotFound>
                                                                                        <input type="checkbox"
                                                                                            class="checkBoxOptions"
                                                                                            [formControlName]="i"
                                                                                            (change)="true" #checkBox>
                                                                                        {{medical_history_warnings[i].name}}
                                                                                        <div class="custom-subinput-padding"
                                                                                            *ngIf="medical_history_warnings[i].has_details == true && checkBox.checked">
                                                                                            <input type="text"
                                                                                                class="text firstletter_capital"
                                                                                                value=""
                                                                                                id="medical_history_text_warning_{{medical_history_warnings[i].id}}"
                                                                                                placeholder="Details" />
                                                                                        </div>
                                                                                    </ng-template>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-md-4 line_height_27">
                                                                            <h4
                                                                                class="Nunito-Bold font_16 sub-heading-padding">
                                                                                Heart</h4>

                                                                            <label class="mt-1 p-1 d-flex"
                                                                                formArrayName="hearts"
                                                                                *ngFor="let heart of heartFormArray.controls; let i = index">
                                                                                <div class="checkBoxOptionsWrapper">

                                                                                    <div
                                                                                        *ngIf="checkheartIds(medical_history_heart[i].id);then heartIdFound else heartIdNotFound">
                                                                                        here is ignored</div>
                                                                                    <ng-template #heartIdFound>
                                                                                        <input type="checkbox"
                                                                                            class="checkBoxOptions"
                                                                                            [checked]="true"
                                                                                            [ngModel]="true"
                                                                                            [formControlName]="i"
                                                                                            (change)="true" #checkBox>
                                                                                        {{medical_history_heart[i].name}}
                                                                                        <div class="custom-subinput-padding"
                                                                                            *ngIf="medical_history_heart[i].has_details == true && checkBox.checked">
                                                                                            <input type="text"
                                                                                                class="text firstletter_capital"
                                                                                                [value]="getheartValue(medical_history_heart[i].id)"
                                                                                                id="medical_history_text_heart_{{medical_history_heart[i].id}}"
                                                                                                placeholder="Details" />
                                                                                        </div>
                                                                                    </ng-template>
                                                                                    <ng-template #heartIdNotFound>
                                                                                        <input type="checkbox"
                                                                                            class="checkBoxOptions"
                                                                                            [formControlName]="i"
                                                                                            (change)="true" #checkBox>
                                                                                        {{medical_history_heart[i].name}}
                                                                                        <div class="custom-subinput-padding"
                                                                                            *ngIf="medical_history_heart[i].has_details == true && checkBox.checked">
                                                                                            <input type="text"
                                                                                                class="text firstletter_capital"
                                                                                                value=""
                                                                                                id="medical_history_text_heart_{{medical_history_heart[i].id}}"
                                                                                                placeholder="Details" />
                                                                                        </div>
                                                                                    </ng-template>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-md-4 line_height_27">
                                                                            <h4
                                                                                class="Nunito-Bold font_16 sub-heading-padding">
                                                                                Chest</h4>

                                                                            <label class="mt-1 p-1 d-flex"
                                                                                formArrayName="chests"
                                                                                *ngFor="let chest of chestFormArray.controls; let i = index">
                                                                                <div class="checkBoxOptionsWrapper">

                                                                                    <div
                                                                                        *ngIf="checkchestIds(medical_history_chest[i].id);then chestIdFound else chestIdNotFound">
                                                                                        here is ignored</div>
                                                                                    <ng-template #chestIdFound>
                                                                                        <input type="checkbox"
                                                                                            class="checkBoxOptions"
                                                                                            [checked]="true"
                                                                                            [ngModel]="true"
                                                                                            [formControlName]="i"
                                                                                            (change)="true" #checkBox>
                                                                                        {{medical_history_chest[i].name}}
                                                                                        <div class="custom-subinput-padding"
                                                                                            *ngIf="medical_history_chest[i].has_details == true && checkBox.checked">
                                                                                            <input type="text"
                                                                                                class="text firstletter_capital"
                                                                                                [value]="getchestValue(medical_history_chest[i].id)"
                                                                                                id="medical_history_text_chest_{{medical_history_chest[i].id}}"
                                                                                                placeholder="Details" />
                                                                                        </div>
                                                                                    </ng-template>
                                                                                    <ng-template #chestIdNotFound>
                                                                                        <input type="checkbox"
                                                                                            class="checkBoxOptions"
                                                                                            [formControlName]="i"
                                                                                            (change)="true" #checkBox>
                                                                                        {{medical_history_chest[i].name}}
                                                                                        <div class="custom-subinput-padding"
                                                                                            *ngIf="medical_history_chest[i].has_details == true && checkBox.checked">
                                                                                            <input type="text"
                                                                                                class="text firstletter_capital"
                                                                                                value=""
                                                                                                id="medical_history_text_chest_{{medical_history_chest[i].id}}"
                                                                                                placeholder="Details" />
                                                                                        </div>
                                                                                    </ng-template>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="sec_inner_content1 py-2 mt-1" *ngIf="showMedicalHistoryForms">
                                                <div class="body_content">
                                                    <div class="profile_content_sec ">
                                                        <div class="tab-content tab_content_sec clearfix">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="row">
                                                                        <div class="col-md-6 line_height_27">
                                                                            <h4
                                                                                class="Nunito-Bold font_16 sub-heading-padding">
                                                                                Blood</h4>

                                                                            <label class="mt-1 p-1 d-flex"
                                                                                formArrayName="bloods"
                                                                                *ngFor="let blood of bloodFormArray.controls; let i = index">
                                                                                <div class="checkBoxOptionsWrapper">

                                                                                    <div
                                                                                        *ngIf="checkbloodIds(medical_history_blood[i].id);then bloodIdFound else bloodIdNotFound">
                                                                                        here is ignored</div>
                                                                                    <ng-template #bloodIdFound>
                                                                                        <input type="checkbox"
                                                                                            class="checkBoxOptions"
                                                                                            [checked]="true"
                                                                                            [ngModel]="true"
                                                                                            [formControlName]="i"
                                                                                            (change)="true" #checkBox>
                                                                                        {{medical_history_blood[i].name}}
                                                                                        <div class="custom-subinput-padding"
                                                                                            *ngIf="medical_history_blood[i].has_details == true && checkBox.checked">
                                                                                            <input type="text"
                                                                                                class="text firstletter_capital"
                                                                                                [value]="getbloodValue(medical_history_blood[i].id)"
                                                                                                id="medical_history_text_blood_{{medical_history_blood[i].id}}"
                                                                                                placeholder="Details" />
                                                                                        </div>
                                                                                    </ng-template>
                                                                                    <ng-template #bloodIdNotFound>
                                                                                        <input type="checkbox"
                                                                                            class="checkBoxOptions"
                                                                                            [formControlName]="i"
                                                                                            (change)="true" #checkBox>
                                                                                        {{medical_history_blood[i].name}}
                                                                                        <div class="custom-subinput-padding"
                                                                                            *ngIf="medical_history_blood[i].has_details == true && checkBox.checked">
                                                                                            <input type="text"
                                                                                                class="text firstletter_capital"
                                                                                                value=""
                                                                                                id="medical_history_text_blood_{{medical_history_blood[i].id}}"
                                                                                                placeholder="Details" />
                                                                                        </div>
                                                                                    </ng-template>
                                                                                </div>
                                                                            </label>
                                                                            <div class="mt-2">
                                                                                <div class="form-group d-block">
                                                                                    <label for="autism"
                                                                                        class="margin_bottom_5 label_input">Have
                                                                                        you ever diagnosed with learning
                                                                                        disabilities/ADHD/Autism
                                                                                        issues?</label>

                                                                                    <div class="row mt-2 p-1 mx-auto">
                                                                                        <div class="col-md-3 ml-lg-3">
                                                                                            <label
                                                                                                class="form-check-label input_name post_label pointer_link medical_reasons_div">
                                                                                                <input type="checkbox"
                                                                                                    class="form-check-input"
                                                                                                    (click)="autism_status('Yes')"
                                                                                                    [checked]="autismStatus==='Yes'"
                                                                                                    name="autism"
                                                                                                    value="Yes">
                                                                                                <p
                                                                                                    class="med_head d-inline">
                                                                                                    Yes</p>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="col-md-3">
                                                                                            <label
                                                                                                class="form-check-label input_name post_label pointer_link medical_reasons_div">
                                                                                                <input type="checkbox"
                                                                                                    class="form-check-input"
                                                                                                    (click)="autism_status('No')"
                                                                                                    [checked]="autismStatus==='No'"
                                                                                                    name="autism"
                                                                                                    value="No">
                                                                                                <p
                                                                                                    class="med_head d-inline">
                                                                                                    No</p>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="mt-2"
                                                                                *ngIf="autismStatus==='Yes'">
                                                                                <div class="form-group mt-1">
                                                                                    <div class="input-field">
                                                                                        <input
                                                                                            id="learning_disabilities_details"
                                                                                            name="learning_disabilities_details"
                                                                                            type="text"
                                                                                            class="form-control firstletter_capital shadow-none"
                                                                                            [value]="learning_disabilities_details"
                                                                                            maxlength="50"
                                                                                            placeholder="Details"
                                                                                            formControlName="learning_disabilities_details" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">

                                                                            <div class="form-group d-block mt-2">
                                                                                <label for="medication"
                                                                                    class="margin_bottom_5 label_input font_14 ">Medication
                                                                                    - Please List All</label>
                                                                                <div class="text_box">
                                                                                    <textarea
                                                                                        class="form-control  firstletter_capital date_pos input_text_field form-control mt-2 mx-auto"
                                                                                        autocomplete="off"
                                                                                        maxlength="101" rows="2"
                                                                                        trim="blur"
                                                                                        placeholder="Please enter the medication details"
                                                                                        (keyup)="onUpdateMedicationDetails($event)"
                                                                                        [value]="medication_details"
                                                                                        formControlName="medication_details">{{medication_details}}</textarea>
                                                                                </div>
                                                                            </div>
                                                                            <div class="form-group d-block mt-2">
                                                                                <label for="phone"
                                                                                    class="margin_bottom_5 label_input">Surgeries</label>
                                                                                <div class="text_box">
                                                                                    <textarea
                                                                                        class="form-control firstletter_capital date_pos input_text_field form-control mt-2 mx-auto"
                                                                                        autocomplete="off"
                                                                                        maxlength="101" rows="2"
                                                                                        trim="blur"
                                                                                        placeholder="Please enter the Surgeries details"
                                                                                        (keyup)="onUpdatesurgeryDetails($event)"
                                                                                        [value]="surgery_details"
                                                                                        formControlName="surgery_details">{{surgery_details}}</textarea>
                                                                                </div>
                                                                            </div>
                                                                            <div class="form-group d-block mt-2">
                                                                                <label for="phone"
                                                                                    class="margin_bottom_5 label_input">Sleeping
                                                                                    disorder?</label>

                                                                                <div class="row mt-2 p-1 mx-auto">
                                                                                    <div class="col-md-3 ml-lg-3">
                                                                                        <label
                                                                                            class="form-check-label input_name post_label pointer_link medical_reasons_div">
                                                                                            <input type="checkbox"
                                                                                                (click)="sleeping_disorder('yes')"
                                                                                                [checked]="sleepingDisorder=='yes'"
                                                                                                class="form-check-input">
                                                                                            <p
                                                                                                class="med_head d-inline">
                                                                                                Yes</p>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div class="col-md-3">
                                                                                        <label
                                                                                            class="form-check-label input_name post_label pointer_link medical_reasons_div">
                                                                                            <input type="checkbox"
                                                                                                (click)="sleeping_disorder('no')"
                                                                                                [checked]="sleepingDisorder=='no'"
                                                                                                class="form-check-input">
                                                                                            <p
                                                                                                class="med_head d-inline">
                                                                                                No</p>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="sec_inner_content1 pb-2 mt-1" *ngIf="showMedicalHistoryForms">
                                                <div class="body_content">
                                                    <div class="profile_content_sec ">
                                                        <div class="tab-content tab_content_sec clearfix">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <h4 class="Nunito-Bold font_16 p-2">
                                                                                Allergies</h4>
                                                                            <div class="two-grids">

                                                                                <label class="mt-1 p-1 d-flex"
                                                                                    formArrayName="allergies"
                                                                                    *ngFor="let allergy of allergiesFormArray.controls; let i = index">
                                                                                    <div class="checkBoxOptionsWrapper">

                                                                                        <div
                                                                                            *ngIf="checkallergyIds(medical_history_allergies[i].id);then allergyIdFound else allergyIdNotFound">
                                                                                            here is ignored</div>
                                                                                        <ng-template #allergyIdFound>
                                                                                            <input type="checkbox"
                                                                                                class="checkBoxOptions"
                                                                                                [checked]="true"
                                                                                                [ngModel]="true"
                                                                                                [formControlName]="i"
                                                                                                (change)="true"
                                                                                                #checkBox>
                                                                                            {{medical_history_allergies[i].name}}
                                                                                            <div class="custom-subinput-padding"
                                                                                                *ngIf="medical_history_allergies[i].has_details == true && checkBox.checked">
                                                                                                <input type="text"
                                                                                                    class="text firstletter_capital"
                                                                                                    [value]="getallergyValue(medical_history_allergies[i].id)"
                                                                                                    id="medical_history_text_allergies_{{medical_history_allergies[i].id}}"
                                                                                                    placeholder="Details" />
                                                                                            </div>
                                                                                        </ng-template>
                                                                                        <ng-template #allergyIdNotFound>
                                                                                            <input type="checkbox"
                                                                                                class="checkBoxOptions"
                                                                                                [formControlName]="i"
                                                                                                (change)="true"
                                                                                                #checkBox>
                                                                                            {{medical_history_allergies[i].name}}
                                                                                            <div class="custom-subinput-padding"
                                                                                                *ngIf="medical_history_allergies[i].has_details == true && checkBox.checked">
                                                                                                <input type="text"
                                                                                                    class="text firstletter_capital"
                                                                                                    value=""
                                                                                                    id="medical_history_text_allergies_{{medical_history_allergies[i].id}}"
                                                                                                    placeholder="Details" />
                                                                                            </div>
                                                                                        </ng-template>
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="sec_inner_content1 p-0 mt-1" *ngIf="showMedicalHistoryForms">
                                                <div class="body_content">
                                                    <div class="profile_content_sec ">
                                                        <div
                                                            class="tab-content tab_content_sec clearfix list-left-padding">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <h4 class="Nunito-Bold font_16 p-2">Others
                                                                            </h4>
                                                                            <div class="two-grids">

                                                                                <label class="mt-1 p-1 d-flex"
                                                                                    formArrayName="others"
                                                                                    *ngFor="let other of otherFormArray.controls; let i = index">
                                                                                    <div class="checkBoxOptionsWrapper">

                                                                                        <div
                                                                                            *ngIf="checkotherIds(medical_history_other[i].id);then otherIdFound else otherIdNotFound">
                                                                                            here is ignored</div>
                                                                                        <ng-template #otherIdFound>
                                                                                            <input type="checkbox"
                                                                                                class="checkBoxOptions"
                                                                                                [checked]="true"
                                                                                                [ngModel]="true"
                                                                                                [formControlName]="i"
                                                                                                (change)="true"
                                                                                                #checkBox>
                                                                                            {{medical_history_other[i].name}}
                                                                                            <div class="custom-subinput-padding"
                                                                                                *ngIf="medical_history_other[i].has_details == true && checkBox.checked">
                                                                                                <input type="text"
                                                                                                    class="text firstletter_capital"
                                                                                                    [value]="getotherValue(medical_history_other[i].id)"
                                                                                                    id="medical_history_text_others_{{medical_history_other[i].id}}"
                                                                                                    placeholder="Details" />
                                                                                            </div>
                                                                                        </ng-template>
                                                                                        <ng-template #otherIdNotFound>
                                                                                            <input type="checkbox"
                                                                                                class="checkBoxOptions"
                                                                                                [formControlName]="i"
                                                                                                (change)="true"
                                                                                                #checkBox>
                                                                                            {{medical_history_other[i].name}}
                                                                                            <div class="custom-subinput-padding"
                                                                                                *ngIf="medical_history_other[i].has_details == true && checkBox.checked">
                                                                                                <input type="text"
                                                                                                    class="text firstletter_capital"
                                                                                                    value=""
                                                                                                    id="medical_history_text_others_{{medical_history_other[i].id}}"
                                                                                                    placeholder="Details" />
                                                                                            </div>
                                                                                        </ng-template>
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="sec_inner_content1 pt-2 mt-1" *ngIf="showMedicalHistoryForms">
                                                <div class="body_content">
                                                    <div class="profile_content_sec ">
                                                        <div class="tab-content tab_content_sec clearfix">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <h4 class="Nunito-Bold">Tell us a little more about
                                                                        your Oral Health</h4>
                                                                    <div class="row align-items-center d_block"
                                                                        *ngFor="let data of responsedata let i = index">
                                                                        <div class="col-md-12 col-lg-5 my-3 max_width">
                                                                            <p class="font_16  ml-1"
                                                                                id="{{data.question_id}}">
                                                                                {{data.question_name}}</p>
                                                                        </div>
                                                                        <div class="col-md-12 col-lg-7 my-3 max_width">
                                                                            <div class=" options_sec">
                                                                                <div class=" text-center"
                                                                                    *ngIf="data.type==1 || data.type==2">
                                                                                    <div
                                                                                        class="oral_hygiene_btn{{i}} text-right row ml-1">
                                                                                        <div *ngFor="let options of data.options let j = index"
                                                                                            class="">

                                                                                            <button type="button"
                                                                                                id="ohr_anwser_btn_{{data.question_id}}_{{options.option_name}}"
                                                                                                class="btn tell-us-about-oral-health custom_btn custom_button{{i}}{{j}} "
                                                                                                (click)="onAnswer($event,i,data.type,data.question_id,j)">{{options.option_name}}</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div *ngIf="data.type==3">
                                                                                <div
                                                                                    class="oral_hygiene_btn{{i}} text-right">
                                                                                    <div>
                                                                                        <input type="text"
                                                                                            autocomplete="off"
                                                                                            class="firstletter_capital input_text_field form-control"
                                                                                            trim="blur" id="id"
                                                                                            #answer_text
                                                                                            (focusout)="onAnswer($event,i,data.type,data.question_id)">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="sec_inner_content1 pt-2 mt-1" *ngIf="showMedicalHistoryForms">
                                                <div class="body_content">
                                                    <div class="profile_content_sec ">
                                                        <div class="tab-content tab_content_sec clearfix">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <div class="form-group d-block mt-2">
                                                                                <label for="happy_smile"
                                                                                    class="margin_bottom_5 label_input">Are
                                                                                    you happy with your smile?</label>
                                                                                <ul class="health-fund text-center">
                                                                                    <li>
                                                                                        <input type="radio"
                                                                                            id="happy_smile_yes"
                                                                                            (click)="happy_smile('Yes')"
                                                                                            name="happy_smile"
                                                                                            [checked]="happySmile==='Yes'"
                                                                                            value="Yes" />
                                                                                        <label
                                                                                            for="happy_smile_yes">YES</label>
                                                                                    </li>
                                                                                    <li>
                                                                                        <input type="radio"
                                                                                            id="happy_smile_no"
                                                                                            (click)="happy_smile('No')"
                                                                                            name="happy_smile"
                                                                                            [checked]="happySmile==='No'"
                                                                                            value="No" />
                                                                                        <label
                                                                                            for="happy_smile_no">NO</label>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mt-4">

                                                                            <div class="form-group common_selectfield">
                                                                                <label for="purpose_of_visit"
                                                                                    class="margin_bottom_5 label_input">What
                                                                                    is the main purpose for you to visit
                                                                                    today?</label>
                                                                                <div
                                                                                    class="input-field position-relative">
                                                                                    <select
                                                                                        (change)="onUpdatePurposeOfVisit($event)"
                                                                                        name="purpose_of_visit"
                                                                                        id="purpose_of_visit"
                                                                                        class="form-control dropdown_select_fonts"
                                                                                        formControlName="purpose_of_visit">
                                                                                        <option value=""> Select
                                                                                        </option>
                                                                                        <option value="General checkup"
                                                                                            [selected]='purpose_of_visit=="General checkup"'>
                                                                                            General Checkup</option>
                                                                                        <option value="Toothache"
                                                                                            [selected]='purpose_of_visit=="Toothache"'>
                                                                                            Toothache</option>
                                                                                        <option
                                                                                            value="Cosmetic treatment"
                                                                                            [selected]='purpose_of_visit=="Cosmetic treatment"'>
                                                                                            Cosmetic Treatment</option>
                                                                                        <option value="others"
                                                                                            [selected]='purpose_of_visit=="others"'>
                                                                                            Others </option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>

                                                                            <div *ngIf='purpose_of_visit=="others"'>
                                                                                <div class="input-field">
                                                                                    <input id="purpose_of_visit_reason"
                                                                                        (keyup)="onUpdateVisitReason($event)"
                                                                                        (focusout)="validateInput($event)"
                                                                                        name="purpose_of_visit_reason"
                                                                                        type="text"
                                                                                        class="form-control firstletter_capital shadow-none"
                                                                                        value="{{purpose_of_visit_reason}}"
                                                                                        maxlength="20"
                                                                                        placeholder="Details"
                                                                                        formControlName="purpose_of_visit_reason" />
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                        <div
                                                                            class="col-md-6 mt-4 align-items-center justify-content-center ">
                                                                            <label for="last_dental_visit"
                                                                                class="margin_bottom_5 label_input">When
                                                                                was your last dental visit?</label>
                                                                            <div class="row pl-4 mt-2">
                                                                                <div class="col-md-4">
                                                                                    <label
                                                                                        class="form-check-label input_name post_label pointer_link medical_reasons_div"
                                                                                        for="radio3">
                                                                                        <input type="radio"
                                                                                            (change)="last_dental_visit_period('6 Months')"
                                                                                            [checked]="last_dental_visit=='6 Months'"
                                                                                            class="form-check-input"
                                                                                            id="6_month"
                                                                                            name="last_dental_visit">
                                                                                        <p class="med_head d-inline">6
                                                                                            Months</p>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="col-md-4">
                                                                                    <label
                                                                                        class="form-check-label input_name post_label pointer_link medical_reasons_div"
                                                                                        for="radio3">
                                                                                        <input type="radio"
                                                                                            (change)="last_dental_visit_period('12 Months')"
                                                                                            [checked]="last_dental_visit=='12 Months'"
                                                                                            class="form-check-input"
                                                                                            id="12_month"
                                                                                            name="last_dental_visit">
                                                                                        <p class="med_head d-inline">12
                                                                                            Months</p>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="col-md-4">
                                                                                    <label
                                                                                        class="form-check-label input_name post_label pointer_link medical_reasons_div"
                                                                                        for="radio3">
                                                                                        <input type="radio"
                                                                                            (change)="last_dental_visit_period('Never')"
                                                                                            [checked]="last_dental_visit=='Never'"
                                                                                            class="form-check-input"
                                                                                            id="never"
                                                                                            name="last_dental_visit">
                                                                                        <p class="med_head d-inline">
                                                                                            Never</p>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mt-2">

                                                                            <div class="form-group common_selectfield">
                                                                                <label for="hearAboutUs"
                                                                                    class="margin_bottom_5 label_input">How
                                                                                    did you hear about us?</label>
                                                                                <div
                                                                                    class="input-field position-relative">
                                                                                    <select
                                                                                        (change)="onUpdateHearAboutus($event)"
                                                                                        name="hearAboutUs"
                                                                                        id="hearAboutUs"
                                                                                        class="form-control dropdown_select_fonts"
                                                                                        formControlName="hearAboutUs">
                                                                                        <option value=""> Select
                                                                                        </option>
                                                                                        <option value="Google Search"
                                                                                            [selected]='hearAboutUs=="Google Search"'>
                                                                                            Google Search</option>
                                                                                        <option value="Facebook"
                                                                                            [selected]='hearAboutUs=="Facebook"'>
                                                                                            Facebook</option>
                                                                                        <option value="Others"
                                                                                            [selected]='hearAboutUs=="Others"'>
                                                                                            Others</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>

                                                                            <div *ngIf='hearAboutUs=="Others"'>
                                                                                <div class="input-field">
                                                                                    <input id="hear_about_us_reason"
                                                                                        (keyup)="onUpdateHearAboutUsReason($event)"
                                                                                        (focusout)="validateInput($event)"
                                                                                        name="hear_about_us_reason"
                                                                                        type="text"
                                                                                        class="form-control firstletter_capital shadow-none"
                                                                                        value="{{hear_about_us_reason}}"
                                                                                        maxlength="20"
                                                                                        placeholder="Details"
                                                                                        formControlName="hear_about_us_reason" />
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div class="col-md-12 mt-2">
                                                                            <p class="font_12">I, the undersigned,
                                                                                consent to the performing of dental and
                                                                                oral surgery procedures agreed to be
                                                                                necessary or advisable, including the
                                                                                use of local anaesthetic and other
                                                                                medication
                                                                                as indicated and I will assume
                                                                                responsibility for the fees associated
                                                                                with those procedures. I understand that
                                                                                the practice requires at least 24 hour's
                                                                                notice if I need to
                                                                                cancel my scheduled appointment and that
                                                                                a cancellation fee may be incurred if I
                                                                                fail to do so. I hearby authorize the
                                                                                dentist or designated team member to
                                                                                take radiographs,
                                                                                study models, photographs and other
                                                                                diagnostic aids deemed appropriate to
                                                                                make a thorough diagnosis. I understand
                                                                                that payment is required on the day of
                                                                                the treatment. </p>
                                                                        </div>
                                                                        <div
                                                                            class="col-md-12 mt-4 align-items-center justify-content-center text-start">
                                                                            <div class="row pl_24">
                                                                                <div class="col-md-3">
                                                                                    <label
                                                                                        class="form-check-label input_name post_label pointer_link medical_reasons_div"
                                                                                        for="patient">
                                                                                        <input type="radio"
                                                                                            (change)="agree_person('pt')"
                                                                                            [checked]="agreePerson==='pt'"
                                                                                            class="form-check-input"
                                                                                            id="patient"
                                                                                            name="optradio">
                                                                                        <p class="med_head d-inline">
                                                                                            Patient</p>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="col-md-3">
                                                                                    <label
                                                                                        class="form-check-label input_name post_label pointer_link medical_reasons_div"
                                                                                        for="parent">
                                                                                        <input type="radio"
                                                                                            (change)="agree_person('pr')"
                                                                                            [checked]="agreePerson==='pr'"
                                                                                            class="form-check-input"
                                                                                            id="parent" name="optradio">
                                                                                        <p class="med_head d-inline">
                                                                                            Parent</p>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="col-md-3">
                                                                                    <label
                                                                                        class="form-check-label input_name post_label pointer_link medical_reasons_div"
                                                                                        for="guardian">
                                                                                        <input type="radio"
                                                                                            (change)="agree_person('gu')"
                                                                                            [checked]="agreePerson==='gu'"
                                                                                            class="form-check-input"
                                                                                            id="guardian"
                                                                                            name="optradio">
                                                                                        <p class="med_head d-inline">
                                                                                            Responsible Guardian</p>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mt-5">
                                                                            <div class="form-group">
                                                                                <label for="printName"
                                                                                    class="margin_bottom_5 label_input">Print
                                                                                    Name</label>
                                                                                <div class="input-field">
                                                                                    <input id="printName"
                                                                                        (keyup)="onUpdatePrintName($event)"
                                                                                        (focusout)="validateInput($event)"
                                                                                        name="printName" type="text"
                                                                                        class="form-control firstletter_capital shadow-none"
                                                                                        value="{{printName}}"
                                                                                        maxlength="15"
                                                                                        placeholder="Print Name"
                                                                                        formControlName="printName" />
                                                                                </div>
                                                                            </div>
                                                                            <div *ngIf="is_signature_new==1">
                                                                                <img src="{{originalSign}}">
                                                                            </div>
                                                                            <div *ngIf="exisitingSignature!=''">
                                                                                <img src="{{exisitingSignature}}">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6  mt-5">
                                                                            <div class="form-group">
                                                                                <label for="last_name"
                                                                                    class="margin_bottom_5 label_input">Signature</label>
                                                                                <div
                                                                                    class="input-field signature-wrapper">

                                                                                    <signature-pad
                                                                                        [options]="signaturePadOptions"
                                                                                        (onBeginEvent)="drawStart()"
                                                                                        (onEndEvent)="drawComplete()"
                                                                                        id="signatureCanvas"></signature-pad>
                                                                                </div>
                                                                            </div>
                                                                            <input type="button"
                                                                                (click)="clearSignature()"
                                                                                class="btn btn-outline-secondary float-left"
                                                                                value="Clear Signature" />
                                                                            <input type="button"
                                                                                (click)="saveSignature()"
                                                                                class="btn btn-dark saveSignature float-right"
                                                                                value="Save" />
                                                                        </div>
                                                                        <div class="col-md-6  mt-5">
                                                                            <div class="form-group">
                                                                                <label for="last_name"
                                                                                    class="margin_bottom_5 label_input">Submitted
                                                                                    Date</label>
                                                                                <div class="input-group">
                                                                                    <input id="submittedDate" readonly
                                                                                        class="form-control"
                                                                                        placeholder="yyyy-mm-dd"
                                                                                        name="submittedDate"
                                                                                        [maxDate]="maxDate"
                                                                                        ngbDatepicker
                                                                                        #date="ngbDatepicker"
                                                                                        [(ngModel)]="submittedDate"
                                                                                        formControlName="submittedDate">
                                                                                    <!-- <input id="submittedDate" class="form-control" placeholder="yyyy-mm-dd" name="submittedDate" [maxDate]="maxDate" ngbDatepicker #date="ngbDatepicker" formControlName="submittedDate" value="{{submittedDate}}"> -->
                                                                                    <div class="input-group-append">
                                                                                        <button
                                                                                            class="btn btn-fill-color icon-right-arrow"
                                                                                            (click)="date.toggle()"
                                                                                            type="button">
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="default_btn_end default_btn_bg p-0">
                                                <a href="javascript:;" class="d-block"><input
                                                        id="save_medical_history_btn"
                                                        [disabled]="isUpdateProfileButtonDisabled" type="submit"
                                                        class="btn button_links btn_width_2 continue_btn"
                                                        value="Submit" /></a>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div class=" tab-pane" id="show_medical_history">
                                    <!-- <div class="card-body tab-content" *ngIf="!showMedicalHistoryForms">
                                        <form autocomplete="off" [formGroup]="patientInfoForm" (ngSubmit)="onSubmit(patientInfoForm,'patientInfoForm')" class="contact_form" action="" enctype="multipart/form-data" method="post" id="patientInfoForm" name="patientInfoForm">
                                            <div class="tab-pane active" id="tabc" role="tabpanel">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group common_selectfield">
                                                            <label for="title" class="margin_bottom_5 label_input">Title</label>
                                                            <div class="input-field position-relative">
                                                                <select (change)="onUpdateTitle($event)" name="title" id="title" class="form-control" formControlName="title">
                                              <option value="Mr" [selected]='title=="Mr"' >Mr</option>
                                              <option value="Miss" [selected]='title=="Miss"' >Miss</option>
                                              <option value="Mrs" [selected]='title=="Mrs"' >Mrs</option>
                                              <option value="Prof" [selected]='title=="Prof"' >Prof</option>
                                              <option value="Dr" [selected]='title=="Dr"' >Dr</option>
                                              <option value="Ms" [selected]='title=="Ms"' >Ms</option>
                                           </select>
                                                 
                                                            </div>
                                                      
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="first_name" class="margin_bottom_5 label_input">First Name</label>
                                                            <div class="input-field">
                                                                <input id="first_name" formControlName="first_name" (keyup)="onUpdateProfileEnterData($event)" (focusout)="validateInput($event)" name="first_name" type="text" class="form-control firstletter_capital shadow-none" value="{{firstName}}" maxlength="15" placeholder="Enter First Name"
                                                                />
                                                            </div>
                                                            <div class="err-msg" *ngIf="ispatientInfoFormSubmitted===true&& patientInfoForm.get('first_name').hasError('required')">
                                                                Please enter the First Name
                                                            </div>
                                                            <div class="err-msg" *ngIf="ispatientInfoFormSubmitted===true&& patientInfoForm.get('first_name').hasError('minlength')">
                                                                First Name should be Min 3 characters
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="last_name" class="margin_bottom_5 label_input">Last Name</label>
                                                            <div class="input-field">
                                                                <input id="last_name" formControlName="last_name" (keyup)="onUpdateProfileEnterData($event)" (focusout)="validateInput($event)" name="last_name" type="text" class="form-control firstletter_capital shadow-none" value="{{lastName}}" maxlength="15" placeholder="Enter Last Name"
                                                                />
                                                            </div>
                                                            <div class="err-msg" *ngIf="ispatientInfoFormSubmitted===true&& patientInfoForm.get('last_name').hasError('required')">
                                                                Last Name should be Min 1 character(s)
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-md-6 form-group mb-6">
                                                        <label for="dob">Date of Birth</label>
                                                        <div class="input-group">
                                                            <input id="dob" class="form-control" placeholder="yyyy-mm-dd" name="dob" [maxDate]="maxDate" ngbDatepicker #date="ngbDatepicker" [(ngModel)]="dob" formControlName="dob">
                                                            <div class="input-group-append">
                                                                <button class="btn btn-outline-secondary icon-Calendar" (click)="date.toggle()" type="button">
                                           </button>
                                                            </div>
                                                        </div>
                                                        <div class="err-msg" *ngIf="ispatientInfoFormSubmitted===true&& patientInfoForm.get('dob').hasError('required')">
                                                            DOB should not be Empty
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="home_phone" class="margin_bottom_5 label_input">Home phone</label>
                                                            <div class="input-field">
                                                                <input (keyup)="onEnterData($event)" maxlength="10" onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))" (keydown.space)="$event.preventDefault();" (focusout)="validateInput($event)" class="form-control"
                                                                    type="text" ng2TelInput (hasError)="signInHasError($event)" (ng2TelOutput)="signInGetNumber($event)" (intlTelInputObject)="hometelInputObject($event)" (countryChange)="homePhoneOnCountryChange($event)"
                                                                    [value]="patientInfoForm.get('home_phone').value" (enableAutoCountrySelect)="true" placeholder="Home phone Number" formControlName="home_phone">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="email_address" class="margin_bottom_5 label_input">Email Address</label>
                                                            <div class="input-field">
                                                                <input id="email_address" formControlName="email" (keyup)="onUpdateProfileEnterData($event)" (focusout)="validateInput($event)" name="email_address" type="text" class="form-control shadow-none" readonly />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="mobile_number" class="margin_bottom_5 label_input">Mobile number</label>
                                                            <div class="input-field">
                                                                <input (keyup)="onEnterData($event)" maxlength="10" onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))" (keydown.space)="$event.preventDefault();" (focusout)="validateInput($event)" class="form-control"
                                                                    type="text" ng2TelInput (hasError)="signInHasError($event)" (ng2TelOutput)="signInGetNumber($event)" (intlTelInputObject)="MobileNumberInputObject($event)" (countryChange)="MobileNumberOnCountryChange($event)"
                                                                    [value]="" placeholder="Mobile Number" formControlName="mobile_number">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group common_selectfield">
                                                            <label for="gender" class="margin_bottom_5 label_input">Gender</label>
                                                            <div class="input-field position-relative">
                                                                <select (change)="onUpdateGender($event)" name="gender" id="gender" class="form-control" formControlName="gender">
                                              <option value="m" [selected]='gender=="m"'>Male</option>
                                              <option value="f" [selected]='gender=="f"' >Female</option>
                                              <option value="o" [selected]='gender=="o"' >Others</option>
                                           </select>
                                                             
                                                            </div>
                                                      
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label for="address" class="margin_bottom_5 label_input">Home Address</label>
                                                        <div class="text_box">
                                                            <textarea class="form-control home_address firstletter_capital date_pos input_text_field form-control mt-2 mx-auto" autocomplete="off" maxlength="100" rows="4" trim="blur" placeholder="Home Address" (keyup)="onUpdateHomeAddress($event)" [value]="home_address"
                                                                formControlName="home_address">{{home_address}}</textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="occupation" class="margin_bottom_5 label_input">Occupation</label>
                                                            <div class="input-field">
                                                                <input id="occupation" (keyup)="onUpdateOccupation($event)" (focusout)="validateInput($event)" name="occupation" type="text" class="form-control firstletter_capital shadow-none" value="{{occupation}}" maxlength="30" placeholder="Student" formControlName="occupation"
                                                                />
                                                            </div>
                                                       
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="emergency_contact" class="margin_bottom_5 label_input">Emergency contact person</label>
                                                            <div class="input-field">
                                                                <input (keyup)="onEnterData($event)" onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))" (keydown.space)="$event.preventDefault();" (focusout)="validateInput($event)" class="form-control firstletter_capital"
                                                                    type="text" ng2TelInput (hasError)="signInHasError($event)" (ng2TelOutput)="signInGetNumber($event)" (intlTelInputObject)="EmergencyContactInputObject($event)" (countryChange)="EmergencyContactOnCountryChange($event)"
                                                                    [value]="" placeholder="Emergency contact number" formControlName="emergency_contact">
                                                            </div>
                                                            <div class="input-field mt-2">
                                                                <input id="doctor" (keyup)="onUpdateDoctor($event)" (focusout)="validateInput($event)" name="doctor" type="text" class="form-control shadow-none" value="{{doctor}}" maxlength="15" placeholder="Contact Person" formControlName="doctor" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 health-fund-wrapper">
                                                       
                                                        <div class="form-group common_selectfield">
                                                            <label for="health_fund" class="margin_bottom_5 label_input">Health Fund</label>
                                                            <div class="input-field position-relative">
                                                                <select name="health_fund" id="health_fund" (change)="onUpdateHealthFund($event)" class="form-control" formControlName="health_fund">
                                                                  <option value="no" [selected]="healthFundStatus=='no'"  >No I dont have any insurance </option>
                                              <option value="yes" [selected]="healthFundStatus=='yes'" >Australian Unity Health Limited</option>
                                           </select>
                                                              
                                                            </div>
                                                       
                                                        </div>
                                                        <div class="row d-block" *ngIf="healthFundStatus=='yes'">
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label for="health_unique_identified_number" class="margin_bottom_5 label_input">Enter Health Unique Identified Number</label>
                                                                    <div class="input-field">
                                                                        <input id="health_unique_identified_number" (keyup)="onUpdateProfileEnterData($event)" (focusout)="validateInput($event)" name="health_unique_identified_number" type="text" class="form-control shadow-none" value="{{health_unique_identified_number}}" maxlength="20"
                                                                            placeholder="Health Unique Identified Number" formControlName="health_unique_identified_number" />
                                                                    </div>
                                                                </div>
                                                        
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row float-right">
                                                    <div class="button_sec">
                                                        <div class="button-sec-ul d-flex align-items-center justify-content-between">
                                                            <div class="d-flex align-items-center responsive_btns">
                                                        
                                                                <div class="default_btn default_btn_bg p-0">
                                                                    <a href="javascript:;" class="d-block"><input id="save_patient_btn" type="submit" class="btn button_links btn_width_2 continue_btn" value="Save" /></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane" id="tabb" role="tabpanel" *ngIf="showMedicalHistoryForms">
                                                <div class="row">
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                        
                                    <div class="sec_inner_content1 p-0" *ngIf="showMedicalHistoryForms">
                                        <form autocomplete="off" [formGroup]="updateMedicalHistoryForm" (ngSubmit)="onSubmit(updateMedicalHistoryForm,'updateMedicalHistoryForm')" class="contact_form" action="" enctype="multipart/form-data" method="post" id="update_medical_history_form" name="update_medical_history_form">
                                            <div class="body_content">
                                                <div class="profile_content_sec no-border-radius">
                                                    <div class="tab-content tab_content_sec clearfix">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="col-md-12">
                                                                    <h4 class="Nunito-Bold">Medical History</h4>
                                                                    <p class="mt-1 font_12 text_grey">(Please answer this seaction as accurately as possible. All records are confidential)</p>
                                                                </div>
                                                                <div class="col-md-12 mt-4">
                                                                    <h4 class="Nunito-Bold">Habits</h4>
                                                                </div>
                                                                <div class="col-md-12 mt-1">
                                                                    <div class="row">
                                                                        <div class="col-md-4">
                                                                            <div class="form-group">
                                                                                <div class="input-field">
                                                                                    <input id="smoke_per_day" (keyup)="onEnterData($event)" onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))" (keydown.space)="$event.preventDefault();" (focusout)="validateInput($event)" name="smoke_per_day"
                                                                                        type="text" class="form-control shadow-none" value="" maxlength="3" placeholder="Smoke (Per Day)" value="{{smoke_per_day}}" formControlName="smoke_per_day" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4">
                                                                            <div class="form-group">
                                                                                <div class="input-field">
                                                                                    <input id="tobacco_per_day" (keyup)="onEnterData($event)" onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))" (keydown.space)="$event.preventDefault();" (focusout)="validateInput($event)" name="tobacco_per_day"
                                                                                        type="text" class="form-control shadow-none" value="" maxlength="3" placeholder="Chew Tobacco (Per Day)" value="{{tobacco_per_day}}" formControlName="tobacco_per_day" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4">
                                                                            <div class="form-group">
                                                                                <div class="input-field">
                                                                                    <input id="alcohol_per_day" (keyup)="onEnterData($event)" onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))" (keydown.space)="$event.preventDefault();" (focusout)="validateInput($event)" name="alcohol_per_day"
                                                                                        type="text" class="form-control shadow-none" value="" maxlength="3" placeholder="Alcohol (Units Per Week)" value="{{alcohol_per_day}}" formControlName="alcohol_per_day" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12 mt-1">
                                                                    <div class="row">
                                                                        <div class="col-md-4">
                                                                            <div class="form-group">
                                                                                <label for="high_frquency_sugar" class="margin_bottom_5 label_input">High Sugar Frequency?</label>
                                                                                <ul class="health-fund text-center">
                                                                                    <li>
                                                                                        <input type="radio" id="high_frquency_sugar_yes" (change)="high_frquency_sugar('yes')" name="high_frquency_sugar" [checked]="highFrequencySugar=='yes'" value="Yes" />
                                                                                        <label for="high_frquency_sugar_yes">YES</label>
                                                                                    </li>
                                                                                    <li>
                                                                                        <input type="radio" id="high_frquency_sugar_no" (change)="high_frquency_sugar('no')" name="high_frquency_sugar" [checked]="highFrequencySugar=='no'" value="No" />
                                                                                        <label for="high_frquency_sugar_no">NO</label>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4">
                                                                            <div class="form-group mb-2">
                                                                                <label for="fizzy_acidic_drinks" class="margin_bottom_5 label_input">Lots of fizzy acidic drinks?</label>
                                                                                <ul class="health-fund text-center">
                                                                                    <li>
                                                                                        <input type="radio" id="fizzy_acidic_drinks_yes" (click)="fizzy_acidic_drinks('yes')" [checked]="fizzyAcidicDrinks=='yes'" name="fizzy_acidic_drinks" value="Yes" />
                                                                                        <label for="fizzy_acidic_drinks_yes">YES</label>
                                                                                    </li>
                                                                                    <li>
                                                                                        <input type="radio" id="fizzy_acidic_drinks_no" (click)="fizzy_acidic_drinks('no')" [checked]="fizzyAcidicDrinks=='no'" name="fizzy_acidic_drinks" value="No" />
                                                                                        <label for="fizzy_acidic_drinks_no">NO</label>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4" *ngIf="fizzyAcidicDrinks=='yes'">
                                                                            <label for="fizzy_acidic_drinks_details" class=" label_input">Acidic drinks Details</label>
                                                                            <input id="fizzy_acidic_drinks_details" name="fizzy_acidic_drinks_details" type="text" class="form-control shadow-none mt-1" [value]="fizzy_acidic_drinks_details" placeholder="Acidic drinks Details" formControlName="fizzy_acidic_drinks_details" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="sec_inner_content1 py-2 mt-1" *ngIf="showMedicalHistoryForms">
                                                <div class="body_content">
                                                    <div class="profile_content_sec ">
                                                        <div class="tab-content tab_content_sec clearfix">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="row">
                                                                        <div class="col-md-4">
                                                                            <h4 class="Nunito-Bold font_14 sub-heading-padding">Warnings</h4>
                                                                
                                                                            <label class="mt-1 p-1 d-flex" formArrayName="warnings" *ngFor="let warning of warningsFormArray.controls; let i = index">
                                                            <span class="position-relative">
                                                               <div *ngIf="checkWarningIds(medical_history_warnings[i].id);then warningIdFound else warningIdNotFound">here is ignored</div>
                                                               <ng-template #warningIdFound>
                                                                  <input type="checkbox" [checked]="true" [ngModel]="true" [formControlName]="i" (change)="true" #checkBox>
                                                                  {{medical_history_warnings[i].name}}
                                                                  <div class="custom-subinput-padding" *ngIf="medical_history_warnings[i].has_details == true && checkBox.checked">
                                                                     <input type="text" class="text firstletter_capital" [value]="getWarningsValue(medical_history_warnings[i].id)"  id="medical_history_text_warning_{{medical_history_warnings[i].id}}" placeholder="Details"/>
                                                                  </div>
                                                               </ng-template>
                                                               <ng-template #warningIdNotFound>
                                                                  <input type="checkbox" [formControlName]="i" (change)="true" #checkBox>
                                                                  {{medical_history_warnings[i].name}}
                                                                  <div class="custom-subinput-padding" *ngIf="medical_history_warnings[i].has_details == true && checkBox.checked">
                                                                     <input type="text" class="text firstletter_capital" value=""  id="medical_history_text_warning_{{medical_history_warnings[i].id}}" placeholder="Details"/>
                                                                  </div>
                                                               </ng-template>
                                                            </span>
                                                         </label>
                                                                        </div>
                                                                        <div class="col-md-4">
                                                                            <h4 class="Nunito-Bold font_14 sub-heading-padding">Heart</h4>
                                                                         
                                                                            <label class="mt-1 p-1 d-flex" formArrayName="hearts" *ngFor="let heart of heartFormArray.controls; let i = index">
                                                            <span class="position-relative">
                                                                
                                                               <div *ngIf="checkheartIds(medical_history_heart[i].id);then heartIdFound else heartIdNotFound">here is ignored</div>
                                                               <ng-template #heartIdFound>
                                                                  <input type="checkbox" [checked]="true" [ngModel]="true" [formControlName]="i" (change)="true" #checkBox>
                                                                  {{medical_history_heart[i].name}}
                                                                  <div class="custom-subinput-padding" *ngIf="medical_history_heart[i].has_details == true && checkBox.checked">
                                                                     <input type="text" class="text firstletter_capital" [value]="getheartValue(medical_history_heart[i].id)"  id="medical_history_text_heart_{{medical_history_heart[i].id}}" placeholder="Details"/>
                                                                  </div>
                                                               </ng-template>
                                                               <ng-template #heartIdNotFound>
                                                                  <input type="checkbox" [formControlName]="i" (change)="true" #checkBox>
                                                                  {{medical_history_heart[i].name}}
                                                                  <div class="custom-subinput-padding" *ngIf="medical_history_heart[i].has_details == true && checkBox.checked">
                                                                     <input type="text" class="text firstletter_capital" value=""  id="medical_history_text_heart_{{medical_history_heart[i].id}}" placeholder="Details"/>
                                                                  </div>
                                                               </ng-template>
                                                            </span>
                                                         </label>
                                                                        </div>
                                                                        <div class="col-md-4">
                                                                            <h4 class="Nunito-Bold font_14 sub-heading-padding">Chest</h4>
                                                                 
                                                                            <label class="mt-1 p-1 d-flex" formArrayName="chests" *ngFor="let chest of chestFormArray.controls; let i = index">
                                                            <span class="position-relative">
                                                                
                                                               <div *ngIf="checkchestIds(medical_history_chest[i].id);then chestIdFound else chestIdNotFound">here is ignored</div>
                                                               <ng-template #chestIdFound>
                                                                  <input type="checkbox" [checked]="true" [ngModel]="true" [formControlName]="i" (change)="true" #checkBox>
                                                                  {{medical_history_chest[i].name}}
                                                                  <div class="custom-subinput-padding" *ngIf="medical_history_chest[i].has_details == true && checkBox.checked">
                                                                     <input type="text" class="text firstletter_capital" [value]="getchestValue(medical_history_chest[i].id)"  id="medical_history_text_chest_{{medical_history_chest[i].id}}" placeholder="Details"/>
                                                                  </div>
                                                               </ng-template>
                                                               <ng-template #chestIdNotFound>
                                                                  <input type="checkbox" [formControlName]="i" (change)="true" #checkBox>
                                                                  {{medical_history_chest[i].name}}
                                                                  <div class="custom-subinput-padding" *ngIf="medical_history_chest[i].has_details == true && checkBox.checked">
                                                                     <input type="text" class="text firstletter_capital" value=""  id="medical_history_text_chest_{{medical_history_chest[i].id}}" placeholder="Details"/>
                                                                  </div>
                                                               </ng-template>
                                                            </span>
                                                         </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="sec_inner_content1 py-2 mt-1" *ngIf="showMedicalHistoryForms">
                                                <div class="body_content">
                                                    <div class="profile_content_sec ">
                                                        <div class="tab-content tab_content_sec clearfix">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="row">
                                                                        <div class="col-md-6">
                                                                            <h4 class="Nunito-Bold font_14 sub-heading-padding">Blood</h4>
                                                                 
                                                                            <label class="mt-1 p-1 d-flex" formArrayName="bloods" *ngFor="let blood of bloodFormArray.controls; let i = index">
                                                            <span class="position-relative">
                                                                
                                                               <div *ngIf="checkbloodIds(medical_history_blood[i].id);then bloodIdFound else bloodIdNotFound">here is ignored</div>
                                                               <ng-template #bloodIdFound>
                                                                  <input type="checkbox" [checked]="true" [ngModel]="true" [formControlName]="i" (change)="true" #checkBox>
                                                                  {{medical_history_blood[i].name}}
                                                                  <div class="custom-subinput-padding" *ngIf="medical_history_blood[i].has_details == true && checkBox.checked">
                                                                     <input type="text" class="text firstletter_capital" [value]="getbloodValue(medical_history_blood[i].id)"  id="medical_history_text_blood_{{medical_history_blood[i].id}}" placeholder="Details"/>
                                                                  </div>
                                                               </ng-template>
                                                               <ng-template #bloodIdNotFound>
                                                                  <input type="checkbox" [formControlName]="i" (change)="true" #checkBox>
                                                                  {{medical_history_blood[i].name}}
                                                                  <div class="custom-subinput-padding" *ngIf="medical_history_blood[i].has_details == true && checkBox.checked">
                                                                     <input type="text" class="text firstletter_capital" value=""  id="medical_history_text_blood_{{medical_history_blood[i].id}}" placeholder="Details"/>
                                                                  </div>
                                                               </ng-template>
                                                            </span>
                                                         </label>
                                                                            <div class="mt-2">
                                                                
                                                                                <div class="form-group d-block">
                                                                                    <label for="autism" class="margin_bottom_5 label_input">Have you ever diagnosed with learning disabilities/ADHD/Autism issues?</label>
                                                                                     
                                                                                    <div class="row mt-2 p-1">
                                                                                        <div class="col-md-3 ml-3">
                                                                                            <label class="form-check-label input_name post_label pointer_link medical_reasons_div">
                                                                                          <input type="checkbox" class="form-check-input"   (click)="autism_status('Yes')" [checked]="autismStatus==='Yes'||learning_disabilities==='Yes'" name="autism" value="Yes" >
                                                                                          <p class="med_head Nunito-Bold d-inline">Yes</p>
                                                                                        </label>
                                                                                        </div>
                                                                                        <div class="col-md-3">
                                                                                            <label class="form-check-label input_name post_label pointer_link medical_reasons_div">
                                                                                        <input type="checkbox" class="form-check-input"  (click)="autism_status('No')" [checked]="autismStatus==='No'||learning_disabilities==='No'" name="autism" value="No" >
                                                                                        <p class="med_head Nunito-Bold d-inline">No</p>
                                                                                      </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="mt-2" *ngIf="autismStatus==='Yes'||learning_disabilities==='Yes'">
                                                                                <div class="form-group mt-1">
                                                                                    <div class="input-field">
                                                                                        <input id="learning_disabilities_details" name="learning_disabilities_details" type="text" class="form-control firstletter_capital shadow-none" [value]="learning_disabilities_details" maxlength="50" placeholder="details" formControlName="learning_disabilities_details"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                             
                                                                            <div class="form-group d-block mt-2">
                                                                                <label for="medication" class="margin_bottom_5 label_input Nunito-Bold font_14 ">Medication - Please List All</label>
                                                                                <div class="text_box">
                                                                                    <textarea class="form-control  firstletter_capital date_pos input_text_field form-control mt-2 mx-auto" autocomplete="off" maxlength="101" rows="2" trim="blur" placeholder="Please enter the medication details" (keyup)="onUpdateMedicationDetails($event)"
                                                                                        [value]="medication_details" formControlName="medication_details">{{medication_details}}</textarea>
                                                                                </div>
                                                                            </div>
                                                                            <div class="form-group d-block mt-2">
                                                                                <label for="phone" class="margin_bottom_5 label_input">Surgeries</label>
                                                                                <div class="text_box">
                                                                                    <textarea class="form-control firstletter_capital date_pos input_text_field form-control mt-2 mx-auto" autocomplete="off" maxlength="101" rows="2" trim="blur" placeholder="Please enter the Surgeries details" (keyup)="onUpdatesurgeryDetails($event)" [value]="surgery_details"
                                                                                        formControlName="surgery_details">{{surgery_details}}</textarea>
                                                                                </div>
                                                                            </div>
                                                                            <div class="form-group d-block mt-2">
                                                                                <label for="phone" class="margin_bottom_5 label_input">Sleeping disorder?</label>
                                                                                 
                                                                                <div class="row mt-2 p-1">
                                                                                    <div class="col-md-3 ml-3">
                                                                                        <label class="form-check-label input_name post_label pointer_link medical_reasons_div">
                                                                                      <input type="checkbox" class="form-check-input">
                                                                                      <p class="med_head Nunito-Bold d-inline">Yes</p>
                                                                                    </label>
                                                                                    </div>
                                                                                    <div class="col-md-3">
                                                                                        <label class="form-check-label input_name post_label pointer_link medical_reasons_div">
                                                                                    <input type="checkbox" class="form-check-input" >
                                                                                    <p class="med_head Nunito-Bold d-inline">No</p>
                                                                                  </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="sec_inner_content1 pb-2 mt-1" *ngIf="showMedicalHistoryForms">
                                                <div class="body_content">
                                                    <div class="profile_content_sec ">
                                                        <div class="tab-content tab_content_sec clearfix">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <h4 class="Nunito-Bold font_14 p-2">Allergies</h4>
                                                                            <div class="two-grids">
                                                                   
                                                                                <label class="mt-1 p-1 d-flex" formArrayName="allergies" *ngFor="let allergy of allergiesFormArray.controls; let i = index">
                                                               <span class="position-relative d-inline">
                                                                   
                                                                  <div *ngIf="checkallergyIds(medical_history_allergies[i].id);then allergyIdFound else allergyIdNotFound">here is ignored</div>
                                                                  <ng-template #allergyIdFound>
                                                                     <input type="checkbox" [checked]="true" [ngModel]="true" [formControlName]="i" (change)="true" #checkBox>
                                                                     {{medical_history_allergies[i].name}}
                                                                     <div class="custom-subinput-padding" *ngIf="medical_history_allergies[i].has_details == true && checkBox.checked">
                                                                        <input type="text" class="text firstletter_capital" [value]="getallergyValue(medical_history_allergies[i].id)"  id="medical_history_text_allergies_{{medical_history_allergies[i].id}}" placeholder="Details"/>
                                                                     </div>
                                                                  </ng-template>
                                                                  <ng-template #allergyIdNotFound>
                                                                     <input type="checkbox" [formControlName]="i" (change)="true" #checkBox>
                                                                     {{medical_history_allergies[i].name}}
                                                                     <div class="custom-subinput-padding" *ngIf="medical_history_allergies[i].has_details == true && checkBox.checked">
                                                                        <input type="text" class="text firstletter_capital" value=""  id="medical_history_text_allergies_{{medical_history_allergies[i].id}}" placeholder="Details"/>
                                                                     </div>
                                                                  </ng-template>
                                                               </span>
                                                            </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="sec_inner_content1 p-0 mt-1" *ngIf="showMedicalHistoryForms">
                                                <div class="body_content">
                                                    <div class="profile_content_sec ">
                                                        <div class="tab-content tab_content_sec clearfix list-left-padding">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <h4 class="Nunito-Bold font_14 p-2">Others</h4>
                                                                            <div class="two-grids">
                                                                   
                                                                                <label class="mt-1 p-1 d-flex" formArrayName="others" *ngFor="let other of otherFormArray.controls; let i = index">
                                                               <span class="position-relative">
                                                                   
                                                                  <div *ngIf="checkotherIds(medical_history_other[i].id);then otherIdFound else otherIdNotFound">here is ignored</div>
                                                                  <ng-template #otherIdFound>
                                                                     <input type="checkbox" [checked]="true" [ngModel]="true" [formControlName]="i" (change)="true" #checkBox>
                                                                     {{medical_history_other[i].name}}
                                                                     <div class="custom-subinput-padding" *ngIf="medical_history_other[i].has_details == true && checkBox.checked">
                                                                        <input type="text" class="text firstletter_capital" [value]="getotherValue(medical_history_other[i].id)"  id="medical_history_text_others_{{medical_history_other[i].id}}" placeholder="Details"/>
                                                                     </div>
                                                                  </ng-template>
                                                                  <ng-template #otherIdNotFound>
                                                                     <input type="checkbox" [formControlName]="i" (change)="true" #checkBox>
                                                                     {{medical_history_other[i].name}}
                                                                     <div class="custom-subinput-padding" *ngIf="medical_history_other[i].has_details == true && checkBox.checked">
                                                                        <input type="text" class="text firstletter_capital" value=""  id="medical_history_text_others_{{medical_history_other[i].id}}" placeholder="Details"/>
                                                                     </div>
                                                                  </ng-template>
                                                               </span>
                                                            </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="sec_inner_content1 pt-2 mt-1" *ngIf="showMedicalHistoryForms">
                                                <div class="body_content">
                                                    <div class="profile_content_sec ">
                                                        <div class="tab-content tab_content_sec clearfix">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <h4 class="Nunito-Bold">Tell us a little more about your Oral Health</h4>
                                                                    <div class="row align-items-center d_block" *ngFor="let data of responsedata let i = index">
                                                                        <div class="col-md-12 col-lg-5 my-3 max_width">
                                                                            <p class="grey_color font_16 MuseoSans-500 ml-1" id="{{data.question_id}}">{{data.question_name}}</p>
                                                                        </div>
                                                                        <div class="col-md-12 col-lg-7 my-3 max_width">
                                                                            <div class=" options_sec">
                                                                                <div class=" text-center" *ngIf="data.type==1 || data.type==2">
                                                                                    <div class="oral_hygiene_btn{{i}} text-right row ml-1">
                                                                                        <div *ngFor="let options of data.options let j = index" class="">
                                                                                           
                                                                                            <button type="button" id="ohr_anwser_btn_{{data.question_id}}_{{options.option_name}}" class="btn tell-us-about-oral-health custom_btn custom_button{{i}}{{j}} " (click)="onAnswer($event,i,data.type,data.question_id,j)">{{options.option_name}}</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div *ngIf="data.type==3">
                                                                                <div class="oral_hygiene_btn{{i}} text-right">
                                                                                    <div>
                                                                                        <input type="text" autocomplete="off" class="firstletter_capital input_text_field form-control" trim="blur" id="id" #answer_text (focusout)="onAnswer($event,i,data.type,data.question_id)">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="sec_inner_content1 pt-2 mt-1" *ngIf="showMedicalHistoryForms">
                                                <div class="body_content">
                                                    <div class="profile_content_sec ">
                                                        <div class="tab-content tab_content_sec clearfix">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <div class="form-group d-block mt-2">
                                                                                <label for="happy_smile" class="margin_bottom_5 label_input">Are you happy with your smile?</label>
                                                                                <ul class="health-fund text-center">
                                                                                    <li>
                                                                                        <input type="radio" id="happy_smile_yes" (click)="happy_smile('Yes')" name="happy_smile" [checked]="happySmile==='Yes'" value="Yes" />
                                                                                        <label for="happy_smile_yes">YES</label>
                                                                                    </li>
                                                                                    <li>
                                                                                        <input type="radio" id="happy_smile_no" (click)="happy_smile('No')" name="happy_smile" [checked]="happySmile==='No'" value="No" />
                                                                                        <label for="happy_smile_no">NO</label>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mt-2">
                                                                       
                                                                            <div class="form-group common_selectfield">
                                                                                <label for="purpose_of_visit" class="margin_bottom_5 label_input">what is the main purpose for you to visit today?</label>
                                                                                <div class="input-field position-relative">
                                                                                    <select (change)="onUpdatePurposeOfVisit($event)" name="purpose_of_visit" id="purpose_of_visit" class="form-control" formControlName="purpose_of_visit">
                                                                  <option value="General check" [selected]='purpose_of_visit=="General check"'>General Check</option>
                                                                  <option value="Toothache" [selected]='purpose_of_visit=="Toothache"'>Toothache</option>
                                                                  <option value="Cosmetic options" [selected]='purpose_of_visit=="Cosmetic options"'>Cosmetic options</option>
                                                               </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <div class="col-md-6 mt-2 align-items-center justify-content-center ">
                                                                            <label for="last_dental_visit" class="margin_bottom_5 label_input">when was your last dental visit?</label>
                                                                            <div class="row pl-4 mt-2">
                                                                                <div class="col-md-3">
                                                                                    <label class="form-check-label input_name post_label pointer_link medical_reasons_div" for="radio3">
                                                                  <input type="radio" (change)="last_dental_visit_period('6 Months')" [checked]="last_dental_visit=='6 Months'" class="form-check-input" id="6_month" name="last_dental_visit">
                                                                  <p class="med_head Nunito-Bold d-inline">6 Months</p>
                                                               </label>
                                                                                </div>
                                                                                <div class="col-md-3">
                                                                                    <label class="form-check-label input_name post_label pointer_link medical_reasons_div" for="radio3">
                                                                  <input type="radio"  (change)="last_dental_visit_period('12 Months')" [checked]="last_dental_visit=='12 Months'" class="form-check-input" id="12_month" name="last_dental_visit">
                                                                  <p class="med_head Nunito-Bold d-inline">12 Months</p>
                                                               </label>
                                                                                </div>
                                                                                <div class="col-md-3">
                                                                                    <label class="form-check-label input_name post_label pointer_link medical_reasons_div" for="radio3">
                                                                  <input type="radio"  (change)="last_dental_visit_period('Never')" [checked]="last_dental_visit=='Never'" class="form-check-input" id="never" name="last_dental_visit">
                                                                  <p class="med_head Nunito-Bold d-inline">Never</p>
                                                               </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mt-2">
                                                                       
                                                                            <div class="form-group common_selectfield">
                                                                                <label for="hearAboutUs" class="margin_bottom_5 label_input">How did you hear about us?</label>
                                                                                <div class="input-field position-relative">
                                                                                    <select (change)="onUpdateHearAboutus($event)" name="hearAboutUs" id="hearAboutUs" class="form-control" formControlName="hearAboutUs">
                                                                  <option value="Google Search" [selected]='hearAboutUs=="Google Search"'>Google Search</option>
                                                                  <option value="Facebook" [selected]='hearAboutUs=="Facebook"'>Facebook</option>
                                                                  <option value="Others" [selected]='hearAboutUs=="Others"'>Others</option>
                                                               </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-12 mt-2">
                                                                            <p class="font_12">I, the undersigned, consent to the performing of dental and oral surgery procedures agreed to be necessary or advisable, including the use of local anaesthetic and other medication as indicated and
                                                                                I will assume responsibility for the fees associated with those procedures. I understand that the practice requires at least 24 hour's notice if I need to cancel my scheduled appointment and
                                                                                that a cancellation fee may be incurred if I fail to do so. I hearby authorize the dentist or designated team member to take radiographs, study models, photographs and other diagnostic aids deemed
                                                                                appropriate to make a thorough diagnosis. I understand that payment is required on the day of the treatment. </p>
                                                                        </div>
                                                                        <div class="col-md-12 mt-4 align-items-center justify-content-center text-start">
                                                                            <div class="row pl_24"> 
                                                                                <div class="col-md-3">
                                                                                    <label class="form-check-label input_name post_label pointer_link medical_reasons_div" for="radio3">
                                                                  <input type="radio" (change)="agree_person('pt')" [checked]="agreePerson==='pt'" class="form-check-input" id="radio3" name="optradio">
                                                                  <p class="med_head Nunito-Bold d-inline">Patient</p>
                                                               </label>
                                                                                </div>
                                                                                <div class="col-md-3">
                                                                                    <label class="form-check-label input_name post_label pointer_link medical_reasons_div" for="radio3">
                                                                  <input type="radio"  (change)="agree_person('pr')" [checked]="agreePerson==='pr'" class="form-check-input" id="radio3" name="optradio">
                                                                  <p class="med_head Nunito-Bold d-inline">Parent</p>
                                                               </label>
                                                                                </div>
                                                                                <div class="col-md-3">
                                                                                    <label class="form-check-label input_name post_label pointer_link medical_reasons_div" for="radio3">
                                                                  <input type="radio"  (change)="agree_person('gu')" [checked]="agreePerson==='gu'" class="form-check-input" id="radio3" name="optradio">
                                                                  <p class="med_head Nunito-Bold d-inline">Responsible Guardian</p>
                                                               </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mt-5">
                                                                            <div class="form-group">
                                                                                <label for="printName" class="margin_bottom_5 label_input">Print Name</label>
                                                                                <div class="input-field">
                                                                                    <input id="printName" (keyup)="onUpdatePrintName($event)" (focusout)="validateInput($event)" name="printName" type="text" class="form-control firstletter_capital shadow-none" value="{{printName}}" maxlength="15" placeholder="Print Name" formControlName="printName"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div *ngIf="is_signature_new==1">
                                                                                <img src="{{originalSign}}">
                                                                            </div>
                                                                            <div *ngIf="exisitingSignature!=''">
                                                                                <img src="{{exisitingSignature}}">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6  mt-5">
                                                                            <div class="form-group">
                                                                                <label for="last_name" class="margin_bottom_5 label_input">Signature</label>
                                                                                <div class="input-field signature-wrapper">
                                                                                   
                                                                                    <signature-pad [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()" id="signatureCanvas"></signature-pad>
                                                                                </div>
                                                                            </div>
                                                                             
                                                                            <input type="button" (click)="clearSignature()" class="btn btn-outline-danger float-left" value="Clear Signature" />
                                                                            <input type="button" (click)="saveSignature()" class="btn btn-outline-success  float-right" value="Save" />
                                                                        </div>
                                                                        <div class="col-md-6  mt-5">
                                                                            <div class="form-group">
                                                                                <label for="last_name" class="margin_bottom_5 label_input">Submitted Date</label>
                                                                                <div class="input-group">
                                                                                    <input id="submittedDate" class="form-control" placeholder="yyyy-mm-dd" name="submittedDate" [maxDate]="maxDate" ngbDatepicker #date="ngbDatepicker" formControlName="submittedDate" value="{{submittedDate}}">
                                                                                    <div class="input-group-append">
                                                                                        <button class="btn btn-outline-secondary icon-Calendar" (click)="date.toggle()" type="button">
                                                                  </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="default_btn_end default_btn_bg p-0">
                                                <a href="javascript:;" class="d-block"><input id="save_medical_history_btn" [disabled]="isUpdateProfileButtonDisabled" type="submit" class="btn button_links btn_width_2 continue_btn" value="Save" /></a>
                                            </div>
                    
                                        </form> -->
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { Services } from "../../Services";

@Component({
  selector: "app-product-discount-card",
  templateUrl: "./product-discount-card.component.html",
  styleUrls: ["./product-discount-card.component.css"],
})
export class ProductDiscountCardComponent implements OnInit {
  isExpand: boolean = false;
  @Output() productDiscountClick: EventEmitter<void> = new EventEmitter();
  isBupaFlow = false;
  appServices: Services;
  
  constructor(private router: Router) {
    this.appServices = new Services();
    this.isBupaFlow = this.appServices.getSession("isBupaFlow", false) === "true";
  }

  ngOnInit(): void {}

  onGetNow() {
    if (!this.isBupaFlow) {
      this.productDiscountClick.emit();
      this.router.navigate(["product-discount"]);
    } else {
      window.location.href = "https://www.bupadental.com.au/health-cover";
    }
  }
}

export const environment = {
  production: true,
  DEBUG_ENABLED: 1,
  IPDATA_KEY: 'af338b03c73cd319c3126f0289370ae80544784a79d59032cbbc616f',
  IPDATA_API: 'https://api.ipdata.co',


  // TECH ACTIVE - DEV - aus configuration
  ENV: 'Dev',
  DOMAIN: 'https://ausmilowidget.tech-active.com/',
  REDIRECT_URL: '/',
  BASE_PATH: '/',   // for productions
  S3_BASE_URL: 'https://smilo-ai-aus-dev.s3.ap-south-1.amazonaws.com',
  AES_ENC_SECRET_KEY: 'dDkWyTcpancwALsZF7PB3b5KCKvmirxn',
  AES_ENC_IV: 'f09c01zyc2x5HdhG',
  // API_BASE_URL: 'https://ausmilowidgetapi.tech-active.com/api/app/v2/',
  API_BASE_URL: 'https://api-d1.smilo.ai/api/app/v2/',
  AUTHORIZATION_KEY: 'Rwvf4MESc6fYp_wN7rLBXehH9yuHmsMWfuUdftCif96L6WG3MLkGS7PjD7_kvERCcXhxpe',
  AWS_ACCESS_KEY: 'AKIAWCDD25BTA3RL5VMB',
  AWS_SECRET_KEY: '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',
  S3_BUCKET_NAME: 'smilo-ai-aus-dev',
  CONSENT_FORM: 'https://www.smilo.ai/consent-form/',
  PRIVACY: 'https://www.smilo.ai/privacy-policy/',
  TERMS: 'https://www.smilo.ai/terms-and-conditions/',
  LICENCE_AGREEMENT: 'https://www.smilo.ai/licence-agreement/',
  // SMILO_TOKEN: 'Shz8fZ0Itbdmrvx2mY6Ye9H09g2OkHXBd9nVIl2AX8lljTodcl',
  SMILO_TOKEN: 'wIPhTYkd1Hpj2rqtgZ91Ue0sg4AFI5DoOFXlOYQ2vTWil0iyjN',      //Bupa widget Token
  FBPIXEL_ID: '1281636479006304_comment',
  GTM_ID: 'G-Y9KMZJD340_comment',
  GA_TRACKING_ID: 'G-NCQHVNT6YS_comment', // for productions
  TITLE_PREFIX: 'Smilo.ai - ',



  // India-dabur configuration
  LANDING_PAGE: "https://www.dev.smilo.ai/new-design-landing-page/",
  LANDING_PAGE_SINGLE_IMAGE:
    "https://www.dev.smilo.ai/landing-page-single-image-widget/",
  LANDING_PAGE_DABUR: "https://www.smilo.ai/dabur-dental-health-check/",
  HOME_PAGE: "https://www.smilo.ai",
  DABUR_CONSENT_FORM: "https://www.smilo.ai/dabur-consent-form/",
  DABUR_PRIVACY: "https://www.smilo.ai/dabur-privacy-policy/",
  DABUR_TERMS: "https://www.smilo.ai/dabur-terms-and-conditions/",
  IN_PRIVACY: "https://www.dev.smilo.ai/in/privacy-policy-new/",
  IN_TERMS: "https://www.dev.smilo.ai/in/terms-and-conditions-new/",
  IN_CONSENT_FORM: "https://www.dev.smilo.ai/in/consent-form/",
  AWS_REGION: "ap-south-1",
  CLIENT_WHATSAPP_CONTACT: "https://wa.me/919741296904",
  RAZOR_PAY_KEY: "rzp_test_Hx6Vk9dkTogcCR",
  GET_IN_TOUCH: "https://www.dev.smilo.ai/in/contact/",
  DISCLAIMER_PAGE: "https://www.smilo.ai/in/disclaimer/",
  DABUR_DISCLAIMER_PAGE: "https://www.smilo.ai/dabur-disclaimer/",
  GTM_ID_INDIA: "GTM-PS7N4D6_comment",
  DOMAIN_URL: "india.qa.smilo.health",
  CCAVENUE_ACCESS_CODE: "AVIT05KJ27BH62TIHB",
  CCAVENUE_REDIRECTION_URL:
    "https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction",
};

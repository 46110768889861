// import { Component, ChangeDetectorRef, ChangeDetectionStrategy, ElementRef, ViewChild, OnInit,AfterViewInit, TemplateRef, HostListener } from '@angular/core';
import { Component, ChangeDetectorRef, ElementRef, ViewChild, OnInit, AfterViewInit } from '@angular/core';
// import { Router, ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Services } from '../helpers/Services';
import { HttpClient } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { LyDialog } from '@alyle/ui/dialog';
import { ImgCropperEvent } from '@alyle/ui/image-cropper';
import { CropperDialogComponent } from '../global_components/cropper-dialog/cropper-dialog.component';
import * as $ from 'jquery';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';
import { MessageService } from '../profile-pic-service.service';
import Swal from 'sweetalert2';
import { Md5 } from 'ts-md5/dist/md5';
import { TitleCasePipe } from '@angular/common';
// import { Console } from 'console';

import { Location } from '@angular/common';

import { SignaturePad } from 'angular2-signaturepad';
import { ElementSchemaRegistry } from '@angular/compiler';


const md5 = new Md5();



@Component({
  selector: 'app-medical-history-form',
  templateUrl: './medical-history-form.component.html',
  styleUrls: ['./medical-history-form.component.css'],
  providers: [TitleCasePipe]
})
export class MedicalHistoryFormComponent implements OnInit, AfterViewInit {

  @ViewChild('myProfile', { static: false }) myProfile: ElementRef;

  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  public signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 5,
    'canvasWidth': 500,
    'canvasHeight': 150
  };

  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");

  isSubmitted = false;
  changePasswordForm: FormGroup;
  profile_status: any = 'active';
  change_password_status: any = '';
  user_details: any = '';
  firstName: any = '';
  lastName: any = '';
  email: any = '';
  countryList: any = '';
  appservices: Services;
  selectedCountry: any = '';
  stateList: any = '';
  selectedState: any = '';
  cityList: any = '';
  selectedCity: any = '';
  country_name: any = '';
  countryCodeNumber: any = '';
  phoneNumber: any = '';
  user_id: any = '';
  apirequest: any = '';
  input: any = '';
  deleted_msg_class: string = 'display_none';
  isButtonDisabled: boolean = false;
  updateProfileForm: FormGroup;
  isUpdateProfileButtonDisabled: boolean = false;
  currentPasswordError: boolean = true;
  passwordUpdatedSuccessfully: string = 'display_none';
  passwordUpdatedError: string = 'display_none';
  passwordUpdatedErrorMsg: string = '';



  profileUpdatedSuccessfully: string = 'display_none';
  profileUpdatedError: string = 'display_none';
  profileUpdatedErrorMsg: string = '';


  user_selected_country: any = '';
  user_selected_state: any = '';

  cropped_url_blob: Blob;
  folder: string = '';
  file_name: string = '';
  todaysDataTime: any = '';
  currentDate: any = '';
  profile_img: any = 'assets/images/default-profile.png';
  profile_img_complete_path: string = '';
  tempProfilePic: any = '';
  sendSubjectMessage: any = {};

  userPassword: any = '';
  isPasswordFormSubmitted: any = false;


  signInValidNumber: any = true;
  phoneSignIn: any = '';
  signInPhoneNumber: any = '';
  signInSelectedCountryCode: any = '';
  signInSelectedCountry: any = '';
  isUserLoggedIn: any = '';

  healthFundStatus: any = 'yes';

  showMedicalHistoryForms: boolean = false;
  medicalHistoryFormLoading: boolean = false;
  medical_history_warnings: any;
  medical_history_heart: any;
  medical_history_chest: any;
  medical_history_blood: any;
  medical_history_allergies: any;
  medical_history_other: any;

  patientInfoForm: FormGroup;
  title: any = 'Mr';
  home_phone: any = '';
  mobile_number: any = '';
  gender: any = 'm';
  home_address: any = '';
  occupation: any = '';
  emergency_contact: any = '';
  dob: any = null;
  health_fund: any = 'no';
  health_unique_identified_number: any = '';
  doctor: any = '';

  ispatientInfoFormSubmitted: any = false;
  patientInfoUpdatedSuccessfully: string = 'display_none';
  patientInfoUpdatedError: string = 'display_none';
  patientInfoUpdatedErrorMsg: string = '';

  wholeDOB: any = ''; bypass_select_service: string | JSON;
  widget_service_type: string | JSON;
  ;

  wholeSubmittedDate: any = '';

  homePhoneSelectedCountryCode: any = '';
  homePhoneSelectedCountry: any = '';

  homePhone_country_name: any = '';
  homePhonecountryCodeNumber: any = '';


  mobilePhoneSelectedCountryCode: any = '';
  mobilePhoneSelectedCountry: any = '';

  mobilePhone_country_name: any = '';
  mobilePhonecountryCodeNumber: any = '';


  emergencyContactSelectedCountryCode: any = '';
  emergencyContactSelectedCountry: any = '';

  emergencyContact_country_name: any = '';
  emergencyContactcountryCodeNumber: any = '';


  updateMedicalHistoryForm: FormGroup;

  smoke_per_day: any = '';
  tobacco_per_day: any = '';
  alcohol_per_day: any = '';
  highFrequencySugar: any = '';
  fizzyAcidicDrinks: any = '';

  autismStatus: any = '';
  learning_disabilities: any = '';
  learning_disabilities_details: any = '';
  medication_details: any = '';
  surgery_details: any = '';

  signature: any = '';

  sleepingDisorder: any = '';

  happySmile: any = '';
  purpose_of_visit: any = '';

  last_dental_visit: any = '';
  printName: any = '';
  hearAboutUs: any = '';

  submittedDate: any = null;

  agreePerson: any = '';

  updateMedicalHistoryFormSubmitted: any = false;
  MedicalHistoryFormSUpdatedSuccessfully: string = 'display_none';
  MedicalHistoryFormSUpdatedError: string = 'display_none';
  MedicalHistoryFormSUpdatedErrorMsg: string = '';


  maxDate: any;

  fizzy_acidic_drinks_details: any;
  is_signature_new: any = '';;

  availableWarnings: any;

  heart_warning: any;

  chest_warning: any;

  blood_warning: any;

  allergies_warning: any;

  originalSign: any;

  other_warnings: any;

  responsedata: any;

  answers: any[] = [];

  latest_ans: any;
  obj: any;

  exisitingSignature: any = '';

  answeredOptions: any = '';

  profile_image: any = 'assets/images/default-profile.png';

  emergency_contact_name: any = '';

  insurance_number: any = '';

  purpose_of_visit_reason: any = '';

  hear_about_us_reason: any = '';

  showSuccessPage: boolean = false;


  constructor(private _dialog: LyDialog, private _cd: ChangeDetectorRef, private router: Router, private httpClient: HttpClient, private fb: FormBuilder, private messageService: MessageService, private titleCasePipe: TitleCasePipe, private _location: Location) {
    this.appservices = new Services;

    // this.updateMedicalHistoryForm = this.fb.group({
    //   orders: new FormArray([])
    // });

  }



  ngAfterViewInit(): void {
    // throw new Error('Method not implemented.');

    // $("ul.nav-tabs a").click(function (e) {
    //   console.log(" Clicked ");
    //   e.preventDefault();  
    //     $(this).tab('show');
    // });

    if (this.showMedicalHistoryForms) {
      this.signaturePad.set('minWidth', 5);
      this.signaturePad.set('maxWidth', 250);
      this.signaturePad.clear();
    }

    if (this.availableWarnings) {
      this.availableWarnings.forEach(
        (currentValue, index) => {
          // console.log( currentValue );
        });
    }



  }



  drawComplete() {
    // console.log(this.signaturePad.toDataURL());
    this.signature = this.signaturePad.toDataURL();
  }

  drawStart() {
    //console.log('begin drawing');
  }

  saveSignature() {
    this.originalSign = this.signature;
    // console.log(" this.originalSign : " + this.originalSign + " this.is_signature_new :  " +  this.is_signature_new );
    if (!this.originalSign) {
      return false;
    }
    // console.log(" this.originalSign :  " + this.originalSign) ;
    var base64Img = this.signature;
    base64Img = base64Img.replace("data:image/png;base64,", "");
    // console.log(base64Img);
    this.signature = base64Img;
    this.exisitingSignature = '';
    this.is_signature_new = 1;
    $('.saveSignature').attr('disabled', true);
    // this.clearSignature();
  }

  health_fund_status(status) {
    this.healthFundStatus = status;
  }

  show_medical_history_forms(booleanStatus) {

    this.showMedicalHistoryForms = booleanStatus;
    if (this.showMedicalHistoryForms) {

      this.apirequest = [];
      let input = this.appservices.generteJSONFromArray(this.apirequest);
      // console.log(input);
      this.appservices.callJSONAPI(this.appservices.GET_MEDICAL_HISTORY_DETAILS, input, 'get', this, '').subscribe(
        (data: any) => {
          // console.log( JSON.stringify(data.result) );
          if (data.status) {

            const warningsArray = this.updateMedicalHistoryForm.get('warnings') as FormArray;
            warningsArray.clear();


            const heartsArray = this.updateMedicalHistoryForm.get('hearts') as FormArray;
            heartsArray.clear();


            const chestArray = this.updateMedicalHistoryForm.get('chests') as FormArray;
            chestArray.clear();

            const bloodsArray = this.updateMedicalHistoryForm.get('bloods') as FormArray;
            bloodsArray.clear();

            const allergiesArray = this.updateMedicalHistoryForm.get('allergies') as FormArray;
            allergiesArray.clear();

            const othersArray = this.updateMedicalHistoryForm.get('others') as FormArray;
            othersArray.clear();

            if (!this.appservices.isEmpty(data.result.app_details.medical_history_warnings)) {
              this.medical_history_warnings = data.result.app_details.medical_history_warnings;
              this.medical_history_warnings.forEach(
                (currentValue, index) => {
                  this.warningsFormArray.push(new FormControl(false));
                });
            }

            if (!this.appservices.isEmpty(data.result.app_details.medical_history_heart)) {
              this.medical_history_heart = data.result.app_details.medical_history_heart;
              // heart
              this.medical_history_heart.forEach(() => this.heartFormArray.push(new FormControl(false)));
            }

            if (!this.appservices.isEmpty(data.result.app_details.medical_history_chest)) {
              this.medical_history_chest = data.result.app_details.medical_history_chest;
              this.medical_history_chest.forEach(() => this.chestFormArray.push(new FormControl(false)));
            }

            if (!this.appservices.isEmpty(data.result.app_details.medical_history_blood)) {
              this.medical_history_blood = data.result.app_details.medical_history_blood;
              this.medical_history_blood.forEach(() => this.bloodFormArray.push(new FormControl(false)));
            }

            if (!this.appservices.isEmpty(data.result.app_details.medical_history_allergies)) {
              this.medical_history_allergies = data.result.app_details.medical_history_allergies;
              this.medical_history_allergies.forEach(() => this.allergiesFormArray.push(new FormControl(false)));
            }

            if (!this.appservices.isEmpty(data.result.app_details.medical_history_other)) {
              this.medical_history_other = data.result.app_details.medical_history_other;
              this.medical_history_other.forEach(() => this.otherFormArray.push(new FormControl(false)));
            }

            this.apirequest = [];
            let input = this.appservices.generteJSONFromArray(this.apirequest);
            // console.log(input);
            this.appservices.callJSONAPI(this.appservices.GET_PATIENT_INFO, input, 'get', this, '').subscribe(
              (data: any) => {
                // console.log( JSON.stringify(data.result));
                if (data.status) {
                  this.appservices.callJSONAPI(this.appservices.QUESTIONNAIRE + "?offset=0&limit=100", "", "get", this, "127.0.0.1").subscribe(
                    (data: any) => {
                      // console.log(data)        
                      if (data.status) {
                        $('.sec_inner_content').show();
                        this.responsedata = data.result.questions;
                        this.apirequest = [];
                        this.apirequest["content_type"] = '1';
                        this.apirequest["widget_request"] = 'yes';
                        let inputs = this.appservices.generteJSONFromArray(this.apirequest);
                        // console.log(input);
                        this.appservices.callJSONAPI(this.appservices.LAST_SAVED_ANSWER, inputs, 'post', this, '').subscribe(
                          (data: any) => {

                            if (data.status) {

                              if (data.result) {
                                this.answeredOptions = data.result;

                                setTimeout(() => {
                                  if (this.answeredOptions.length > 0) {
                                    this.answeredOptions.forEach(
                                      (currentValue, index) => {
                                        // ohr_anser_btn_t2YW5kbRRsYVObgz1Q1Yvg_Once
                                        //  console.log('ohr_anwser_btn_'+currentValue.question_id+"_"+currentValue.text_answer);
                                        if (currentValue.question_id) {
                                          setTimeout(() => {
                                            document.getElementById('ohr_anwser_btn_' + currentValue.question_id + "_" + currentValue.text_answer).click();
                                          }, 100);
                                        }
                                      });
                                  }
                                }, 1000);

                                // console.log(" this.answeredOptions : " + this.answeredOptions.length);

                                // (document.getElementById('refresh').click())

                              }

                            }
                            // console.log( JSON.stringify( data));

                          });
                      } else {
                      }
                    },
                    (error) => { }
                  );

                  if (!data.result.medical_history_info) {
                    return false;
                  }

                  this.smoke_per_day = data.result.medical_history_info.smoke;
                  this.updateMedicalHistoryForm.controls['smoke_per_day'].setValue(this.smoke_per_day);
                  this.tobacco_per_day = data.result.medical_history_info.tobacco;

                  this.updateMedicalHistoryForm.controls['tobacco_per_day'].setValue(this.tobacco_per_day);
                  this.alcohol_per_day = data.result.medical_history_info.alcohol;
                  this.updateMedicalHistoryForm.controls['alcohol_per_day'].setValue(this.alcohol_per_day);

                  this.highFrequencySugar = data.result.medical_history_info.sugar;
                  this.fizzyAcidicDrinks = data.result.medical_history_info.acidic_drink;
                  this.fizzy_acidic_drinks_details = data.result.medical_history_info.acidic_drink_details;
                  this.updateMedicalHistoryForm.controls['fizzy_acidic_drinks_details'].setValue(this.fizzy_acidic_drinks_details);
                  // console.log(" fizzy_acidic_drinks_details : " + this.fizzy_acidic_drinks_details);

                  this.medication_details = data.result.medical_history_info.medication;
                  this.updateMedicalHistoryForm.controls['medication_details'].setValue(this.medication_details);
                  this.surgery_details = data.result.medical_history_info.surgeries;

                  this.updateMedicalHistoryForm.controls['surgery_details'].setValue(this.surgery_details);
                  this.sleepingDisorder = data.result.medical_history_info.sleeping_disorder;
                  this.learning_disabilities = data.result.medical_history_info.learning_disabilities;

                  this.autismStatus = this.learning_disabilities;
                  this.learning_disabilities_details = data.result.medical_history_info.learning_disabilities_option;
                  this.updateMedicalHistoryForm.controls['learning_disabilities_details'].setValue(this.learning_disabilities_details);


                  this.purpose_of_visit_reason = data.result.medical_history_info.purpose_of_visit_reason;
                  this.updateMedicalHistoryForm.controls['purpose_of_visit_reason'].setValue(this.purpose_of_visit_reason);
                  this.hear_about_us_reason = data.result.medical_history_info.hear_about_us_reason;

                  this.updateMedicalHistoryForm.controls['hear_about_us_reason'].setValue(this.hear_about_us_reason);

                  this.happySmile = data.result.medical_history_info.smile;
                  // this.smi = data.result.medical_history_info.smile_details; 

                  this.purpose_of_visit = data.result.medical_history_info.purpose;
                  this.updateMedicalHistoryForm.controls['purpose_of_visit'].setValue(this.purpose_of_visit);

                  this.last_dental_visit = data.result.medical_history_info.last_dental_visit;
                  this.updateMedicalHistoryForm.controls['last_dental_visit'].setValue(this.last_dental_visit);
                  // console.log(" this.last_dental_visit : " + this.last_dental_visit  );
                  this.hearAboutUs = data.result.medical_history_info.hear_about_us;
                  this.updateMedicalHistoryForm.controls['hearAboutUs'].setValue(this.hearAboutUs);

                  this.agreePerson = data.result.medical_history_info.agree_person;
                  this.printName = data.result.medical_history_info.print_name;
                  this.updateMedicalHistoryForm.controls['printName'].setValue(this.printName);

                  this.submittedDate = data.result.medical_history_info.submitted_date;

                  if (this.submittedDate) {
                    // console.log("Inside");
                    var dobSplit = (this.submittedDate).split("-");
                    if (dobSplit.length > 0) {
                      this.submittedDate = { year: parseInt(dobSplit[0]), month: parseInt(dobSplit[1]), day: parseInt(dobSplit[2]) };
                    }

                    //  this.dob = new Date(this.dob);
                  }
                  else {
                    this.submittedDate = null;
                  }
                  this.updateMedicalHistoryForm.controls['submittedDate'].setValue(this.submittedDate);

                  this.availableWarnings = data.result.medical_history_info.warnings;
                  // console.log(this.availableWarnings); 
                  this.heart_warning = data.result.medical_history_info.heart;
                  // console.log(this.heart_warning); 
                  this.chest_warning = data.result.medical_history_info.chest;
                  // console.log(this.chest_warning); 
                  this.blood_warning = data.result.medical_history_info.blood;
                  // console.log(this.blood_warning); 
                  this.allergies_warning = data.result.medical_history_info.allergies;
                  // console.log(this.allergies_warning); 
                  this.other_warnings = data.result.medical_history_info.other;
                  // console.log(this.other_warnings); 
                  this.exisitingSignature = data.result.medical_history_info.signature;
                  // console.log(data.result.medical_history_info.signature); 

                  // if(this.availableWarnings.length>0)
                  // {
                  //   this.availableWarnings.forEach(
                  //     (currentValue, index) => 
                  //     { 

                  //     })
                  // }
                }
              });
          }
        });
    }

    else {
      this.apirequest = [];
      let input = this.appservices.generteJSONFromArray(this.apirequest);
      // console.log(input);
      this.appservices.callJSONAPI(this.appservices.GET_PATIENT_INFO, input, 'get', this, '').subscribe(
        (data: any) => {
          // console.log( JSON.stringify(data.result));
          if (data.status) {
            // console.log(" data.result.personal_info.doctor  " + data.result.personal_info.doctor);
            this.title = data.result.personal_info.title;
            this.doctor = data.result.personal_info.doctor;
            this.firstName = data.result.personal_info.first_name;
            this.lastName = data.result.personal_info.last_name;

            this.appservices.setSession("first_name", this.firstName, false);
            this.appservices.setSession("last_name", this.lastName, false);

            this.home_phone = data.result.personal_info.home_phone;
            // console.log(" this.home_phone : " + this.home_phone);
            this.patientInfoForm.controls['home_phone'].setValue(this.home_phone);
            this.gender = data.result.personal_info.gender;
            this.home_address = data.result.personal_info.address;
            this.patientInfoForm.controls['home_address'].setValue(this.home_address);

            this.occupation = data.result.personal_info.occupation;
            this.patientInfoForm.controls['occupation'].setValue(this.occupation);
            // console.log( " this.occupation : " + this.occupation );
            this.patientInfoForm.controls['doctor'].setValue(this.doctor);

            this.healthFundStatus = data.result.personal_info.health_fund;
            this.health_unique_identified_number = data.result.personal_info.health_fund_details;
            this.patientInfoForm.controls['health_unique_identified_number'].setValue(this.health_unique_identified_number);

            this.insurance_number = data.result.personal_info.insurance_number;
            this.patientInfoForm.controls['insurance_number'].setValue(this.insurance_number);
            this.mobile_number = data.result.personal_info.mobile_number;
            this.patientInfoForm.controls['mobile_number'].setValue(this.mobile_number);

            this.emergency_contact = data.result.personal_info.emergency_contact;
            this.patientInfoForm.controls['emergency_contact'].setValue(this.emergency_contact);
            this.emergency_contact_name = data.result.personal_info.emergency_contact_person;
            this.patientInfoForm.controls['emergency_contact_name'].setValue(this.emergency_contact_name);

            this.dob = data.result.personal_info.dob;
            if (this.dob) {
              // console.log("Inside");
              var dobSplit = (this.dob).split("-");
              if (dobSplit.length > 0) {
                this.dob = { year: parseInt(dobSplit[0]), month: parseInt(dobSplit[1]), day: parseInt(dobSplit[2]) };
              }
              //  this.dob = new Date(this.dob);
            }
            else {
              this.dob = null;
            }
            this.homePhone_country_name = data.result.personal_info.home_Phone_country;

            if (!this.appservices.isEmpty(this.homePhone_country_name)) {
              this.appservices.setSession("homePhone_country_name", this.homePhone_country_name, false);
            }
            this.mobilePhone_country_name = data.result.personal_info.mobile_phone_country;

            if (!this.appservices.isEmpty(this.mobilePhone_country_name)) {
              this.appservices.setSession("mobilePhone_country_name", this.mobilePhone_country_name, false);
            }
            this.emergencyContact_country_name = data.result.personal_info.emergency_contact_country;

            if (!this.appservices.isEmpty(this.emergencyContact_country_name)) {
              this.appservices.setSession("emergencyContact_country_name", this.emergencyContact_country_name, false);
            }
            this.gender = data.result.personal_info.gender;
          }
        });
    }
  }

  ngOnInit(): void {
    if ((localStorage.getItem('Input_form_data')) == null || (localStorage.getItem('Input_form_data')) == '') {
      this.router.navigate(['get-started/' + this.appservices.getSession("token", false)]);
    }

    this.show_medical_history_forms(false);
    this.appservices.setSession("bannerTitleText", "Medical History Form", false);

    this.maxDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getUTCDate() };
    this.firstName = this.appservices.getSession("first_name", false);
    this.lastName = this.appservices.getSession("last_name", false);
    this.email = this.appservices.getSession("email", false);
    this.phoneNumber = this.appservices.getSession("phoneNumber", false);
    this.user_selected_country = this.appservices.getSession("country_id", false);
    this.user_selected_state = this.appservices.getSession("state_id", false);
    this.selectedCity = this.appservices.getSession("city", false);
    if (this.appservices.getSession("profile_picture", false) != '') {
      this.profile_img = this.appservices.getSession("profile_picture", false);
    }

    this.updateProfileForm = this.fb.group({
      first_name: [this.firstName, [Validators.required, Validators.minLength(3), Validators.pattern(this.NoWhitespaceRegExp)]],
      last_name: [this.lastName, [Validators.required, Validators.minLength(1), Validators.pattern(this.NoWhitespaceRegExp)]],
      email: [this.email, [Validators.required]],
      // phoneNumber: [this.phoneNumber, [Validators.required,Validators.minLength(8),Validators.maxLength(15)]],
      phoneNumber: [this.phoneNumber, []],
      userSelectedCountry: [this.user_selected_country, [Validators.required]],
      userSelectedState: [this.user_selected_state, [Validators.required]],
      city: [this.selectedCity, [Validators.required, Validators.minLength(3), Validators.maxLength(20), Validators.pattern(this.NoWhitespaceRegExp)]],
      profile_image: [this.profile_img]
    });

    this.currentDate = new Date();
    this.todaysDataTime = formatDate(this.currentDate, 'yyyyMMddhhmmss', 'en-US');

    this.changePasswordForm = this.fb.group({
      current_password: ['', [Validators.required, Validators.minLength(6)]],
      new_password: ['', [Validators.required, Validators.minLength(6), checkPasswordValidation]],
      confirm_password: ['', [Validators.required, Validators.minLength(6), MatchPassword]]
    });

    this.patientInfoForm = this.fb.group({
      title: [this.title, []],
      first_name: [this.firstName, [Validators.required, Validators.minLength(3), Validators.pattern(this.NoWhitespaceRegExp)]],
      last_name: [this.lastName, [Validators.required, Validators.minLength(1), Validators.pattern(this.NoWhitespaceRegExp)]],
      dob: [this.dob, [Validators.required]],
      email: [this.email, [Validators.required]],
      home_phone: [this.home_phone, []],
      mobile_number: [this.mobile_number, []],
      gender: [this.gender, []],
      home_address: [this.home_address, []],
      occupation: [this.occupation, []],
      emergency_contact: [this.emergency_contact, []],
      health_fund: [this.health_fund, []],
      health_unique_identified_number: [this.health_unique_identified_number, []],
      doctor: [this.doctor, []],
      emergency_contact_name: [this.emergency_contact_name, []],
      insurance_number: [this.insurance_number, []]

    });

    this.updateMedicalHistoryForm = this.fb.group({
      smoke_per_day: [this.smoke_per_day, []],
      tobacco_per_day: [this.tobacco_per_day, []],
      alcohol_per_day: [this.alcohol_per_day, []],
      highFrequencySugar: [this.highFrequencySugar, []],
      fizzyAcidicDrinks: [this.fizzyAcidicDrinks, []],
      warnings: new FormArray([]),
      hearts: new FormArray([]),
      chests: new FormArray([]),
      bloods: new FormArray([]),
      allergies: new FormArray([]),
      others: new FormArray([]),
      autismStatus: [this.autismStatus, []],
      learning_disabilities: [this.learning_disabilities, []],
      learning_disabilities_details: [this.learning_disabilities_details, []],
      medication_details: [this.medication_details, []],
      surgery_details: [this.surgery_details, []],
      sleepingDisorder: [this.sleepingDisorder, []],
      happySmile: [this.happySmile, []],
      agreePerson: [this.agreePerson, []],
      purpose_of_visit: [this.purpose_of_visit, []],
      last_dental_visit: [this.last_dental_visit, []],
      printName: [this.printName, []],
      hearAboutUs: [this.hearAboutUs, []],
      submittedDate: [this.submittedDate, []],
      fizzy_acidic_drinks_details: [this.fizzy_acidic_drinks_details, []],
      purpose_of_visit_reason: [this.purpose_of_visit_reason, []],
      hear_about_us_reason: [this.hear_about_us_reason, []],

    });

    this.user_selected_country = this.appservices.getSession("country_id", false);
    this.user_selected_state = this.appservices.getSession("state_id", false);
    this.selectedCity = this.appservices.getSession("city", false);
    // console.log("Called");
    this.appservices.callJSONAPI(this.appservices.GET_COUNTRY_LIST + "?", '', 'get', this, '127.0.0.1').subscribe(
      (data: any) => {
        if (data.status && data.status_code === 1) {
          this.countryList = data.result.country_state_records;

          if (this.user_selected_country != '') {
            this.appservices.callJSONAPI(this.appservices.GET_COUNTRY_LIST + "?country_id=" + this.user_selected_country, '', 'get', this, '127.0.0.1').subscribe(
              (data: any) => {
                if (data.status && data.status_code === 1) {
                  this.stateList = data.result.country_state_records;
                }
              });
          }

          // console.log(" this.user_selected_country : " + this.user_selected_country);        
        }
      });

    /* if ((localStorage.getItem('Input_form_data')) == null || (localStorage.getItem('Input_form_data')) == '' )   {
    //  this.router.navigate(['get-started/'+this.appservices.getSession("token", false)]); 
    }
    else 
    {
      this.user_details = JSON.parse(localStorage.getItem('Input_form_data'));
      console.log(" this.user_details : " + this.user_details);
      if (this.user_details) {
        
        console.log(" this.user_details.firstName : " + this.user_details.firstName );

        console.log(" this.user_details.lastName : " + this.user_details.lastName );

        this.firstName        = this.user_details.firstName;
        this.lastName         = this.user_details.lastName;
        this.email            = this.user_details.email;
        this.phoneNumber      = this.user_details.phoneNumber;

        this.user_selected_country = this.appservices.getSession("country_id", false);
        this.user_selected_state   = this.appservices.getSession("state_id", false);
        this.selectedCity          = this.appservices.getSession("city", false);
        if(this.appservices.getSession("profile_picture", false)!='')
        {
          this.profile_img = this.appservices.getSession("profile_picture", false);
        }
        
        this.updateProfileForm = this.fb.group({
          first_name: [this.firstName, [Validators.required]],
          last_name: [this.lastName, [Validators.required]],
          email: [this.email, [Validators.required]],
          phoneNumber: [this.phoneNumber, [Validators.required]],
          userSelectedCountry: [this.user_selected_country, [Validators.required]],
          userSelectedState: [this.user_selected_state, [Validators.required]],
          city: [this.selectedCity, [Validators.required]],
          profile_image: [this.profile_img]
        });

         
        if (this.updateProfileForm.valid) {
          this.isUpdateProfileButtonDisabled = false;
        }
        else
          this.isUpdateProfileButtonDisabled = true;
      }
    } */
    // console.log( "country_name" + this.appservices.getSession("country_name", false) );

  }

  get warningsFormArray() {
    return this.updateMedicalHistoryForm.controls.warnings as FormArray;
  }

  get heartFormArray() {
    return this.updateMedicalHistoryForm.controls.hearts as FormArray;
  }

  get chestFormArray() {
    return this.updateMedicalHistoryForm.controls.chests as FormArray;
  }

  get bloodFormArray() {
    return this.updateMedicalHistoryForm.controls.bloods as FormArray;
  }

  get allergiesFormArray() {
    return this.updateMedicalHistoryForm.controls.allergies as FormArray;
  }

  get otherFormArray() {
    return this.updateMedicalHistoryForm.controls.others as FormArray;
  }

  selectedTab(type) {
    // console.log(" type  : " + type);
  }

  onSelectCountry(selectedCountry) {
    // console.log(" selectedCountry.target.value : " + selectedCountry.target.value);
    this.selectedCountry = selectedCountry.target.value;
    this.user_selected_state = '';
    this.updateProfileForm.controls['userSelectedState'].setValue('');

    if (this.selectedCountry != '') {
      this.appservices.callJSONAPI(this.appservices.GET_COUNTRY_LIST + "?country_id=" + this.selectedCountry, '', 'get', this, '127.0.0.1').subscribe(
        (data: any) => {
          if (data.status && data.status_code === 1) {
            this.stateList = data.result.country_state_records;
          }
        });
    }
    else {
      this.stateList = [];
    }
  }

  onSelectState(selectedState) {
    // console.log(" selectedCountry.target.value : " + selectedCountry.target.value);
    this.selectedState = selectedState.target.value;
    if (this.selectedState != '') {
      this.user_selected_state = this.selectedState;
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  get ci() { return this.updateProfileForm.controls; }

  numericOnly(event) {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  countryChange(country: any) {
    this.country_name = country.iso2;
    this.countryCodeNumber = country.dialCode;
    this.appservices.setSession("country_name", this.country_name, false);
    this.appservices.setSession("dial_code", this.countryCodeNumber, false);
    this.setValidators();
  }

  telInputObject(obj) {
    if (this.appservices.hasSession("country_name")) {
      this.country_name = this.appservices.getSession("country_name", false);
      obj.setCountry(this.country_name.toUpperCase());
    }
    else {
      obj.setCountry('au');
    }
    // obj.setCountry('au');
    // this.setValidators();
  }

  hometelInputObject(obj) {
    if (this.appservices.hasSession("homePhone_country_name")) {
      this.homePhone_country_name = this.appservices.getSession("homePhone_country_name", false);
      obj.setCountry(this.homePhone_country_name.toUpperCase());
    }
    else {
      obj.setCountry('au');
    }
    // obj.setCountry('au');
    // this.setValidators();
  }

  MobileNumberInputObject(obj) {
    if (this.appservices.hasSession("mobilePhone_country_name")) {
      this.mobilePhone_country_name = this.appservices.getSession("mobilePhone_country_name", false);
      obj.setCountry(this.mobilePhone_country_name.toUpperCase());
    }
    else {
      obj.setCountry('au');
    }
    // obj.setCountry('au');
    // this.setValidators();
  }

  EmergencyContactInputObject(obj) {
    if (this.appservices.hasSession("emergencyContact_country_name")) {
      this.emergencyContact_country_name = this.appservices.getSession("emergencyContact_country_name", false);
      obj.setCountry(this.emergencyContact_country_name.toUpperCase());
    }
    else {
      obj.setCountry('au');
    }
    // obj.setCountry('au');
    // this.setValidators();
  }

  setValidators() {
    if (this.country_name == 'au') {
      this.updateProfileForm.get('phoneNumber').setValidators([Validators.required, Validators.pattern(/^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/)]);
      this.updateProfileForm.get('phoneNumber').updateValueAndValidity();
    }
    else {
      this.updateProfileForm.get('phoneNumber').setValidators([Validators.required, Validators.pattern('^[0-9]*$')]);
      this.updateProfileForm.get('phoneNumber').updateValueAndValidity();
    }
  }

  onEnterData(event) {
    let nameType = event.target.getAttribute('formControlName');
    let nameVal = event.target.value;
    if (nameType === 'phoneNumber') {
      const charCode = (event.which) ? event.which : event.keyCode;
      // console.log(" charCode : " + charCode);
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
      }
      this.isSubmitted = false;
    }
    // console.log("nameType " + nameType );
    // console.log("nameVal  " + nameVal );
    /* if(nameType==='firstName')
     { 
       this.firstName = nameVal;      
     }
     else if(nameType==='lastName')
     {
       this.lastName = nameVal;
     } */

    //  if (this.changePasswordForm.valid) {
    //    this.isButtonDisabled = false;
    //  }
    //  else
    //    this.isButtonDisabled = true;
  }

  onUpdateProfileEnterData(event) {
    let nameType = event.target.getAttribute('formControlName');
    let nameVal = event.target.value;
    // nameVal = this.titleCasePipe.transform(nameVal); 
    if (nameType === 'first_name') {
      this.firstName = nameVal;
    }
    else if (nameType === 'last_name') {
      this.lastName = nameVal;
    }
    else if (nameType === 'city') {
      this.selectedCity = nameVal;
    }
    else if (nameType === 'phoneNumber') {
      this.updateProfileForm.controls['phoneNumber'].setValue(nameVal);
    }
    if (nameType === 'userSelectedCountry') {
      this.user_selected_state = '';
    }
    if (this.updateProfileForm.valid) {
      // console.log(" 2 false ");
      //  this.isUpdateProfileButtonDisabled = false;
    }
    else {
      // console.log(" 2 true ");
      //  this.isUpdateProfileButtonDisabled = true;
    }

  }

  validateInput(event) {
    let nameType = event.target.getAttribute('formControlName');
    let nameVal = event.target.value;
    nameVal = (nameVal).trim();
    if (nameVal.length > 0) {
      nameVal = (this.titleCasePipe.transform(nameVal)).trim();
    }
    // console.log(" nameType : " + nameType);
    if (nameType === 'home_phone' || nameType === 'mobile_number' || nameType === 'health_unique_identified_number' || nameType === 'emergency_contact' || nameType === 'occupation' || nameType === 'emergency_contact' || nameType === 'doctor' || nameType === 'emergency_contact_name' || nameType === 'insurance_number') {
      this.patientInfoForm.controls[nameType].setValue(nameVal);
    }
    else if (nameType === 'smoke_per_day' || nameType === 'tobacco_per_day' || nameType === 'alcohol_per_day' || nameType === 'printName' || nameType === 'purpose_of_visit_reason' || nameType === 'hear_about_us_reason') {
      this.updateMedicalHistoryForm.controls[nameType].setValue(nameVal);
    }
    else {
      this.updateProfileForm.controls[nameType].setValue(nameVal);
    }
  }

  deleteMyAccount() {
    Swal.fire({
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    this.user_id = this.appservices.getSession("user_id", false);
    this.user_details = (localStorage.getItem('Input_form_data'));
    if (this.user_details != '') {
      this.user_details = JSON.parse(localStorage.getItem('Input_form_data'));
      this.email = this.user_details.email;
    }
    if (!this.appservices.isEmpty(this.user_id) && !this.appservices.isEmpty(this.email)) {

      this.apirequest = [];
      this.apirequest["user_id"] = this.user_id;
      this.apirequest["email"] = this.email;
      this.apirequest["password"] = Md5.hashStr(this.userPassword); // md5.appendStr(this.userPassword);
      this.input = this.appservices.generteJSONFromArray(this.apirequest);

      this.appservices.callJSONAPI(this.appservices.DELETE_USER_ACCOUNT, this.input, 'post', this, '127.0.0.1').subscribe(
        (data: any) => {
          if (data.status && data.status_code === 1) {
            this.appservices.setSession("city", '', false);
            this.appservices.setSession("state_id", '', false);
            this.appservices.setSession("country_id", '', false);
            $('.continue_btn').attr('disabled', true);
            this.deleted_msg_class = 'display_block';
            // Swal.fire(
            //   'Deleted!',
            //   'Your Account has been deleted.',
            //   'success'
            // )
            Swal.hideLoading();
            Swal.close();
            setTimeout(() => {                         // <<<---using ()=> syntax
              this.router.navigate(['get-started/' + this.appservices.getSession("token", false)]);
            }, 1500);
            // this.stateList    = data.result.country_state_records;
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              showCloseButton: true,
              text: data.message
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.deleteUserAlert();
              }
            });
          }
        });
    }
  }

  onSubmit(form: FormGroup, formType: any) {
    // console.log('Valid?', form.valid); // true or false
    // console.log('current_password', form.value.current_password);
    // console.log('new_password', form.value.new_password);
    // console.log('confirm_password', form.value.confirm_password);
    // console.log('current_password', form.value.profile_image);
    // console.log(" formType : " + formType);

    this.passwordUpdatedSuccessfully = 'display_none';
    this.passwordUpdatedError = 'display_none';
    this.profileUpdatedSuccessfully = 'display_none';
    this.profileUpdatedError = 'display_none';
    this.patientInfoUpdatedError = 'display_none';

    this.patientInfoUpdatedSuccessfully = 'display_none';
    this.MedicalHistoryFormSUpdatedSuccessfully = 'display_none';
    this.MedicalHistoryFormSUpdatedError = 'display_none';

    if (formType === 'changePasswordForm') {
      this.isPasswordFormSubmitted = true;
    }
    else if (formType === 'patientInfoForm') {
      this.ispatientInfoFormSubmitted = true;
      // {"year":2022,"month":12,"day":8}
      // console.log(" this.DOB : " + this.dob);
    }
    else if (formType === 'updateMedicalHistoryForm') {
      this.updateMedicalHistoryFormSubmitted = true;
      // this.wholeSubmittedDate = form.value.submittedDate;

      // if(this.wholeSubmittedDate)
      // {
      //   this.submittedDate = this.wholeSubmittedDate.year+"-"+this.wholeSubmittedDate.month+"-"+this.wholeSubmittedDate.day;
      //   this.updateMedicalHistoryForm.controls['submittedDate'].setValue(this.submittedDate);
      // }
    }
    else {
      this.isSubmitted = true;
    }

    const selectedMedicalHistoryWarnings = this.updateMedicalHistoryForm.value.warnings
      .map((checked, i) => checked ? this.medical_history_warnings[i].id : null)
      .filter(v => v !== null);

    var medicalHistoryWarningsArr = [];
    Object.keys(selectedMedicalHistoryWarnings).forEach(function (key) {
      // console.log(key, selectedMedicalHistoryWarnings[key]);
      var enteredDetails = $('#medical_history_text_warning_' + selectedMedicalHistoryWarnings[key]).val();
      if (!enteredDetails) {
        enteredDetails = '';
      }
      var selectedID = selectedMedicalHistoryWarnings[key];
      medicalHistoryWarningsArr.push({
        details: enteredDetails,
        id: selectedID
      });

    });
    //  console.log(" medicalHistoryWarningsArr : " + JSON.stringify(medicalHistoryWarningsArr));

    const selectedMedicalHistoryHeart = this.updateMedicalHistoryForm.value.hearts
      .map((checked, i) => checked ? this.medical_history_heart[i].id : null)
      .filter(v => v !== null);
    //  console.log(" selectedMedicalHistoryHeart : " + selectedMedicalHistoryHeart);

    var medicalHistoryHeartArr = [];
    Object.keys(selectedMedicalHistoryHeart).forEach(function (key) {
      // console.log(key, selectedMedicalHistoryWarnings[key]);
      var enteredDetails = $('#medical_history_text_heart_' + selectedMedicalHistoryHeart[key]).val();
      if (!enteredDetails) {
        enteredDetails = '';
      }
      var selectedID = selectedMedicalHistoryHeart[key];
      medicalHistoryHeartArr.push({
        details: enteredDetails,
        id: selectedID
      });

    });

    //  console.log(" medicalHistoryHeartArr : " +JSON.stringify( medicalHistoryHeartArr));
    const selectedMedicalHistoryChest = this.updateMedicalHistoryForm.value.chests
      .map((checked, i) => checked ? this.medical_history_chest[i].id : null)
      .filter(v => v !== null);

    //  console.log(" selectedMedicalHistoryHeart : " + selectedMedicalHistoryHeart);

    var medicalHistoryChestArr = [];
    Object.keys(selectedMedicalHistoryChest).forEach(function (key) {
      // console.log(key, selectedMedicalHistoryWarnings[key]);
      var enteredDetails = $('#medical_history_text_chest_' + selectedMedicalHistoryChest[key]).val();
      if (!enteredDetails) {
        enteredDetails = '';
      }
      var selectedID = selectedMedicalHistoryChest[key];
      medicalHistoryChestArr.push({
        details: enteredDetails,
        id: selectedID
      });
    });

    // console.log(" medicalHistoryChestArr : " + JSON.stringify(medicalHistoryChestArr));
    const selectedMedicalHistoryBlood = this.updateMedicalHistoryForm.value.bloods
      .map((checked, i) => checked ? this.medical_history_blood[i].id : null)
      .filter(v => v !== null);
    // console.log(" selectedMedicalHistoryHeart : " + selectedMedicalHistoryHeart);

    var medicalHistoryBloodArr = [];
    Object.keys(selectedMedicalHistoryBlood).forEach(function (key) {
      // console.log(key, selectedMedicalHistoryWarnings[key]);
      var enteredDetails = $('#medical_history_text_blood_' + selectedMedicalHistoryBlood[key]).val();
      if (!enteredDetails) {
        enteredDetails = '';
      }
      var selectedID = selectedMedicalHistoryBlood[key];
      medicalHistoryBloodArr.push({
        details: enteredDetails,
        id: selectedID
      });
    });

    //  console.log(" medicalHistoryBloodArr : " + JSON.stringify(medicalHistoryBloodArr));

    const selectedMedicalHistoryAllergy = this.updateMedicalHistoryForm.value.allergies
      .map((checked, i) => checked ? this.medical_history_allergies[i].id : null)
      .filter(v => v !== null);
    // console.log(" selectedMedicalHistoryHeart : " + selectedMedicalHistoryHeart);
    var medicalHistoryAllergyArr = [];
    Object.keys(selectedMedicalHistoryAllergy).forEach(function (key) {
      // console.log(key, selectedMedicalHistoryWarnings[key]);
      var enteredDetails = $('#medical_history_text_allergies_' + selectedMedicalHistoryAllergy[key]).val();
      if (!enteredDetails) {
        enteredDetails = '';
      }
      var selectedID = selectedMedicalHistoryAllergy[key];
      medicalHistoryAllergyArr.push({
        details: enteredDetails,
        id: selectedID
      });

    });

    // console.log(" medicalHistoryAllergyArr : " + JSON.stringify(medicalHistoryAllergyArr));
    const selectedMedicalHistoryOther = this.updateMedicalHistoryForm.value.others
      .map((checked, i) => checked ? this.medical_history_other[i].id : null)
      .filter(v => v !== null);

    // console.log(" selectedMedicalHistoryHeart : " + selectedMedicalHistoryHeart);

    var medicalHistoryOtherArr = [];
    Object.keys(selectedMedicalHistoryOther).forEach(function (key) {
      // console.log(key, selectedMedicalHistoryWarnings[key]);
      var enteredDetails = $('#medical_history_text_others_' + selectedMedicalHistoryOther[key]).val();
      if (!enteredDetails) {
        enteredDetails = '';
      }
      var selectedID = selectedMedicalHistoryOther[key];
      medicalHistoryOtherArr.push({
        details: enteredDetails,
        id: selectedID
      });

    });

    // console.log(" medicalHistoryOtherArr : " + JSON.stringify(medicalHistoryOtherArr));
    // console.log("JSON.stringify(this.updateMedicalHistoryForm.value) " + JSON.stringify(this.updateMedicalHistoryForm.value) );

    const controls = this.patientInfoForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        //console.log(" controls[name] : " + JSON.stringify(controls[name]));   
        //  console.log("  controls[name] : " + name);         
      }
    }

    // console.log(" this.patientInfoForm.valid : " + this.patientInfoForm.valid);
    if (this.changePasswordForm.valid && formType === 'changePasswordForm') {
      this.apirequest = [];
      this.apirequest["old_password"] = Md5.hashStr(form.value.current_password);
      this.apirequest["password"] = Md5.hashStr(form.value.new_password);
      this.apirequest["confirm_password"] = Md5.hashStr(form.value.confirm_password);
      this.input = this.appservices.generteJSONFromArray(this.apirequest);


      $('#save_change_pwd_btn').val('Please wait...').attr('disabled', true);

      this.appservices.callJSONAPI(this.appservices.CHANGE_PASSWORD, this.input, 'post', this, '127.0.0.1').subscribe(
        (data: any) => {
          this.changePasswordForm.reset();
          this.isPasswordFormSubmitted = false;
          $('#save_change_pwd_btn').val('Change password').attr('disabled', false);
          if (data.status && data.status_code === 1) {
            this.passwordUpdatedSuccessfully = 'display_block';
            setTimeout(() => {                           // <<<---using ()=> syntax
              this.passwordUpdatedSuccessfully = 'display_none';
              // this.myProfile.nativeElement.click();           
              this.appservices.setSession("isUserLoggedIn", "false", false);
              this.router.navigate(['oral-health-score-preference']);
            }, 2000);
            // Swal.fire(
            //   'Updated!',
            //   'Your Password has been updated.',
            //   'success'
            // );
          }
          else {
            this.passwordUpdatedError = 'display_block';
            this.passwordUpdatedErrorMsg = data.message;
            setTimeout(() => {
              this.passwordUpdatedError = 'display_none';
            }, 5000);
            // Swal.fire({
            //   icon: 'error',
            //   title: 'Oops...',
            //   text: data.message
            // });
          }
        });
    }

    // console.log(" this.signInValidNumber : " + this.signInValidNumber);
    // if(!this.signInValidNumber) { return false; }

    // console.log(" this.updateProfileForm.valid : " + this.updateProfileForm.valid);
    if (this.updateProfileForm.valid && formType === 'updateProfileForm') {
      $('.continue_btn').val('Please wait...').attr('disabled', true);
      //  console.log(" form.value.first_name : " +  form.value.first_name);
      //  console.log(" form.value.last_name : " +  form.value.last_name);
      // console.log(" form.value.email : " +  form.value.email);
      // console.log(" form.value.userSelectedCountry : " +  form.value.userSelectedCountry);
      // console.log(" form.value.userSelectedState : " +  form.value.userSelectedState);
      // console.log(" form.value.userSelectedState : " +  form.value.userSelectedState);
      // console.log(" form.value.phoneNumber : " +  form.value.phoneNumber);

      // localStorage.setItem('Input_form_data', JSON.stringify(form.value));   

      localStorage.setItem('Input_form_data', JSON.stringify(this.updateProfileForm.value));
      form.value.first_name = this.titleCasePipe.transform(form.value.first_name);
      form.value.last_name = this.titleCasePipe.transform(form.value.last_name);

      this.apirequest = [];
      this.apirequest["first_name"] = form.value.first_name;
      this.apirequest["last_name"] = form.value.last_name;
      this.apirequest["email"] = form.value.email;
      this.apirequest["country_id"] = form.value.userSelectedCountry;
      this.apirequest["state_id"] = form.value.userSelectedState;
      this.apirequest["city"] = form.value.city;
      this.apirequest["profile_image"] = this.profile_img_complete_path;
      this.apirequest["dial_code"] = this.appservices.getSession("dial_code", false);
      this.apirequest["contact_number"] = form.value.phoneNumber;
      this.apirequest["dial_country_code"] = this.country_name;

      // console.log(" this.apirequest : " + this.apirequest);
      this.input = this.appservices.generteJSONFromArray(this.apirequest);

      // console.log(" this.apirequest : " + this.apirequest);
      this.appservices.callJSONAPI(this.appservices.UPDATE_WIDGET_PROFILE, this.input, 'post', this, '127.0.0.1').subscribe(
        (data: any) => {
          $('.continue_btn').val('Save').attr('disabled', false);
          if (data.status && data.status_code === 1) {
            this.appservices.setSession("first_name", form.value.first_name, false);
            this.appservices.setSession("last_name", form.value.last_name, false);
            this.appservices.setSession("email", form.value.email, false);
            this.appservices.setSession("country_id", form.value.userSelectedCountry, false);
            this.appservices.setSession("state_id", form.value.userSelectedState, false);
            this.appservices.setSession("city", form.value.city, false);
            // console.log(" this.profile_img_complete_path : " + this.profile_img_complete_path);
            this.sendSubjectMessage = { first_name: form.value.first_name, last_name: form.value.last_name, text: this.profile_img };
            if (this.profile_img_complete_path != '') {
              this.sendSubjectMessage['text'] = environment.S3_BASE_URL + this.profile_img_complete_path;
              // this.messageService.sendMessage({text:environment.S3_BASE_URL+this.profile_img_complete_path,first_name:form.value.first_name,last_name:form.value.last_name});          
              this.appservices.setSession("profile_picture", environment.S3_BASE_URL + this.profile_img_complete_path, false);
            }
            this.messageService.sendMessage(this.sendSubjectMessage);
            this.appservices.setSession("phoneNumber", form.value.phoneNumber, false);

            // Swal.fire(
            //   'Updated!',
            //   'Your Account has been updated.',
            //   'success'
            // );
            this.profileUpdatedSuccessfully = 'display_block';
            setTimeout(() => {                           // <<<---using ()=> syntax
              this.profileUpdatedSuccessfully = 'display_none';
            }, 5000);
          }
          else {
            this.profileUpdatedError = 'display_block';
            this.profileUpdatedErrorMsg = data.message;
            // Swal.fire({
            //   icon: 'error',
            //   title: 'Oops...',
            //   text: data.message
            // });
          }
        });
    }

    if (this.patientInfoForm.valid && formType === 'patientInfoForm') {
      // console.log(" form.value :  " + JSON.stringify(form.value) );
      this.apirequest = [];
      this.apirequest["title"] = (form.value.title);

      this.apirequest["first_name"] = form.value.first_name;
      this.apirequest["last_name"] = form.value.last_name;
      this.apirequest["email"] = form.value.email;
      this.wholeDOB = form.value.dob;
      var tempDob = null;
      if (this.wholeDOB) {
        tempDob = this.wholeDOB.year + "-" + this.wholeDOB.month + "-" + this.wholeDOB.day;
      }
      this.apirequest["dob"] = tempDob;
      this.apirequest["gender"] = 'm';
      if (this.gender != '') {
        this.apirequest["gender"] = this.gender;
      }
      else {
        this.gender = 'm';
      }

      this.apirequest["home_dial_code"] = this.homePhoneSelectedCountryCode;
      this.apirequest["home_Phone_country"] = this.homePhone_country_name;
      this.apirequest["home_phone"] = (form.value.home_phone);

      this.apirequest["work_dial_code"] = '';
      this.apirequest["work_number"] = '';

      this.apirequest["mobile_dial_code"] = this.mobilePhoneSelectedCountryCode;
      this.apirequest["mobile_phone_country"] = this.mobilePhone_country_name;

      this.apirequest["mobile_number"] = (form.value.mobile_number);
      this.apirequest["emergency_contact_person"] = (form.value.emergency_contact_name);
      this.apirequest["emergency_dial_code"] = this.emergencyContactSelectedCountryCode;

      this.apirequest["emergency_contact"] = (form.value.emergency_contact);
      this.apirequest["emergency_contact_country"] = this.emergencyContact_country_name;

      this.apirequest["address"] = (form.value.home_address);
      this.apirequest["health_fund"] = (form.value.health_fund);

      this.apirequest["health_fund_details"] = (form.value.health_unique_identified_number);
      this.apirequest["insurance_number"] = (form.value.insurance_number);

      if (form.value.health_fund == 'no') {
        this.apirequest["insurance_number"] = '';
        // this.apirequest["health_fund_details"] = '';
      }

      this.apirequest["occupation"] = (form.value.occupation);
      this.apirequest["doctor"] = (form.value.doctor);

      this.occupation = form.value.occupation;
      this.input = this.appservices.generteJSONFromArray(this.apirequest);

      // console.log(" this.input " + JSON.stringify(this.input)  );

      $('#save_patient_btn').val('Please wait...').attr('disabled', true);

      this.appservices.callJSONAPI(this.appservices.UPDATE_PATIENT_INFO, this.input, 'post', this, '127.0.0.1').subscribe(
        (data: any) => {
          this.ispatientInfoFormSubmitted = false;
          $('#save_patient_btn').val('Save & Next').attr('disabled', false);
          if (data.status && data.status_code === 1) {
            // (this.updateMedicalHistoryForm.controls.warnings).clear();

            this.title = data.result.personal_info.title;
            this.doctor = data.result.personal_info.doctor;
            this.patientInfoForm.controls['doctor'].setValue(this.doctor);

            this.firstName = data.result.personal_info.first_name;
            this.lastName = data.result.personal_info.last_name;

            this.appservices.setSession("first_name", this.firstName, false);
            this.appservices.setSession("last_name", this.lastName, false);


            if (this.appservices.getSession("profile_picture", false) != '') {
              this.profile_image = this.appservices.getSession("profile_picture", false);
            }
            this.sendSubjectMessage = { first_name: form.value.first_name, last_name: form.value.last_name, text: this.profile_img };
            this.messageService.sendMessage(this.sendSubjectMessage);

            this.home_phone = data.result.personal_info.home_phone;
            // console.log(" this.home_phone : " + this.home_phone);
            this.patientInfoForm.controls['home_phone'].setValue(this.home_phone);
            this.gender = data.result.personal_info.gender;
            this.home_address = data.result.personal_info.address;

            this.occupation = data.result.personal_info.occupation;
            this.patientInfoForm.controls['occupation'].setValue(this.occupation);

            this.healthFundStatus = data.result.personal_info.health_fund;
            // console.log("  healthFundStatus : " + this.healthFundStatus);
            this.health_unique_identified_number = data.result.personal_info.health_fund_details;

            this.patientInfoForm.controls['health_unique_identified_number'].setValue(this.health_unique_identified_number);
            this.insurance_number = data.result.personal_info.insurance_number;
            this.patientInfoForm.controls['insurance_number'].setValue(this.insurance_number);

            this.mobile_number = data.result.personal_info.mobile_number;
            this.patientInfoForm.controls['mobile_number'].setValue(this.mobile_number);

            this.emergency_contact = data.result.personal_info.emergency_contact;
            this.patientInfoForm.controls['emergency_contact'].setValue(this.emergency_contact);

            this.emergency_contact_name = data.result.personal_info.emergency_contact_person;
            this.patientInfoForm.controls['emergency_contact_name'].setValue(this.emergency_contact_name);

            this.dob = data.result.personal_info.dob;
            if (this.dob) {
              // console.log("Inside");
              var dobSplit = (this.dob).split("-");
              if (dobSplit.length > 0) {
                this.dob = { year: parseInt(dobSplit[0]), month: parseInt(dobSplit[1]), day: parseInt(dobSplit[2]) };
              }

              //  this.dob = new Date(this.dob);
            }
            else {
              this.dob = null;
            }

            $('html, body').animate({ scrollTop: '0px' }, 300);
            this.patientInfoUpdatedSuccessfully = 'display_block';
            setTimeout(() => {                           // <<<---using ()=> syntax
              this.patientInfoUpdatedSuccessfully = 'display_none';

              document.getElementById('medical_history_form_href').click();

              this.show_medical_history_forms(true);
            }, 2000);
          }
          else {
            this.patientInfoUpdatedError = 'display_block';
            this.patientInfoUpdatedErrorMsg = data.message;
            setTimeout(() => {
              this.patientInfoUpdatedError = 'display_none';
            }, 5000);
            // Swal.fire({
            //   icon: 'error',
            //   title: 'Oops...',
            //   text: data.message
            // });
          }
        });
    }

    // console.log( JSON.stringify( form.value) );

    if (formType === 'updateMedicalHistoryForm') {
      this.apirequest = [];
      // console.log( JSON.stringify(this.input) );
      // return;

      $('#save_medical_history_btn').val('Please wait...').attr('disabled', true);

      this.apirequest["oral_unique_id"] = this.appservices.getSession("ohr_unique_key", false);
      this.apirequest["content_type"] = 1;
      this.apirequest["questions"] = this.answers;
      this.apirequest["widget_request"] = 'yes';
      let input = this.appservices.generteJSONFromArray(this.apirequest);
      // console.log( JSON.stringify( input) );
      this.appservices.callJSONAPI(this.appservices.QUESTIONNAIRE_SAVE, input, 'post', this, '').subscribe(
        (data: any) => {
          // console.log(data.status);
          this.apirequest = [];
          this.apirequest["acidic_drink"] = this.fizzyAcidicDrinks;
          // console.log(" this.fizzyAcidicDrinks : " + this.fizzyAcidicDrinks);
          if (this.fizzyAcidicDrinks == 'yes') {
            // console.log(" Inside ");
            this.apirequest["acidic_drink_details"] = form.value.fizzy_acidic_drinks_details;
          }
          else {
            // console.log(" Outside ");
            form.value.fizzy_acidic_drinks_details = '';
            this.fizzy_acidic_drinks_details = '';
            this.apirequest["acidic_drink_details"] = '';
          }
          this.apirequest["sugar"] = this.highFrequencySugar;
          this.apirequest["smoke"] = form.value.smoke_per_day;
          this.apirequest["tobacco"] = form.value.tobacco_per_day;
          this.apirequest["alcohol"] = form.value.alcohol_per_day;

          this.apirequest["medication"] = form.value.medication_details;
          this.apirequest["surgeries"] = (form.value.surgery_details);
          this.apirequest["learning_disabilities_option"] = form.value.learning_disabilities_details;

          this.apirequest["learning_disabilities"] = this.autismStatus;
          this.apirequest["sleeping_disorder"] = this.sleepingDisorder;
          this.apirequest["smile"] = this.happySmile;
          this.apirequest["purpose"] = (form.value.purpose_of_visit);
          this.apirequest["last_dental_visit"] = (this.last_dental_visit);
          this.apirequest["purpose_of_visit_reason"] = (this.purpose_of_visit_reason);

          if (this.last_dental_visit != 'others') {
            this.apirequest["purpose_of_visit_reason"] = '';
          }

          this.apirequest["hear_about_us_reason"] = (this.hear_about_us_reason);
          if (this.hear_about_us_reason != 'Others') {
            this.apirequest["hear_about_us_reason"] = '';
          }

          this.apirequest["hear_about_us"] = form.value.hearAboutUs;
          this.apirequest["agree_person"] = this.agreePerson;
          this.apirequest["print_name"] = (form.value.printName);
          this.apirequest["is_signature_new"] = this.is_signature_new;
          this.wholeSubmittedDate = form.value.submittedDate;
          // console.log(" this.wholeSubmittedDate : " + JSON.stringify( this.wholeSubmittedDate));

          var tempwholeSubmittedDate = null;
          if (this.wholeSubmittedDate) {
            tempwholeSubmittedDate = this.wholeSubmittedDate.year + "-" + this.wholeSubmittedDate.month + "-" + this.wholeSubmittedDate.day;
          }

          this.apirequest["submitted_date"] = tempwholeSubmittedDate;
          this.apirequest["warnings"] = medicalHistoryWarningsArr;
          this.apirequest["heart"] = medicalHistoryHeartArr;
          this.apirequest["chest"] = medicalHistoryChestArr;
          this.apirequest["blood"] = medicalHistoryBloodArr;
          this.apirequest["allergies"] = medicalHistoryAllergyArr;
          this.apirequest["other"] = medicalHistoryOtherArr;
          this.apirequest["purpose_of_visit_reason"] = (this.purpose_of_visit_reason);
          if (this.purpose_of_visit != 'others') {
            this.apirequest["purpose_of_visit_reason"] = '';
          }
          this.apirequest["hear_about_us_reason"] = (this.hear_about_us_reason);
          if (this.hearAboutUs != 'Others') {
            this.apirequest["hear_about_us_reason"] = '';
          }
          this.apirequest["signature"] = '';
          if (this.is_signature_new == 1) {
            this.apirequest["signature"] = this.signature;
          }

          this.input = this.appservices.generteJSONFromArray(this.apirequest);
          // console.log(" this.input : " + JSON.stringify(this.input));
          this.appservices.callJSONAPI(this.appservices.UPDATE_MEDICAL_HISTORY, this.input, 'post', this, '127.0.0.1').subscribe(
            (data: any) => {
              this.ispatientInfoFormSubmitted = false;
              $('#save_medical_history_btn').val('Save').attr('disabled', false);
              if (data.status && data.status_code === 1) {
                this.smoke_per_day = data.result.medical_history_info.smoke;
                this.tobacco_per_day = data.result.medical_history_info.tobacco;
                this.alcohol_per_day = data.result.medical_history_info.alcohol;

                this.highFrequencySugar = data.result.medical_history_info.sugar;
                this.fizzyAcidicDrinks = data.result.medical_history_info.acidic_drink;
                this.fizzy_acidic_drinks_details = data.result.medical_history_info.acidic_drink_details;

                this.medication_details = data.result.medical_history_info.medication;
                this.surgery_details = data.result.medical_history_info.surgeries;
                this.sleepingDisorder = data.result.medical_history_info.sleeping_disorder;

                this.learning_disabilities = data.result.medical_history_info.learning_disabilities;
                this.autismStatus = this.learning_disabilities;
                this.learning_disabilities_details = data.result.medical_history_info.learning_disabilities_option;

                this.updateMedicalHistoryForm.controls['learning_disabilities_details'].setValue(this.learning_disabilities_details);
                this.purpose_of_visit_reason = data.result.medical_history_info.purpose_of_visit_reason;
                this.updateMedicalHistoryForm.controls['purpose_of_visit_reason'].setValue(this.purpose_of_visit_reason);

                this.hear_about_us_reason = data.result.medical_history_info.hear_about_us_reason;
                this.updateMedicalHistoryForm.controls['hear_about_us_reason'].setValue(this.hear_about_us_reason);
                this.happySmile = data.result.medical_history_info.smile;

                // this.smi = data.result.medical_history_info.smile_details; 
                this.purpose_of_visit = data.result.medical_history_info.purpose;
                this.updateMedicalHistoryForm.controls['purpose_of_visit'].setValue(this.purpose_of_visit);

                this.last_dental_visit = data.result.medical_history_info.last_dental_visit;
                this.updateMedicalHistoryForm.controls['last_dental_visit'].setValue(this.last_dental_visit);
                this.hearAboutUs = data.result.medical_history_info.hear_about_us;
                this.updateMedicalHistoryForm.controls['hearAboutUs'].setValue(this.hearAboutUs);
                this.agreePerson = data.result.medical_history_info.agree_person;
                this.printName = data.result.medical_history_info.print_name;
                this.updateMedicalHistoryForm.controls['printName'].setValue(this.printName);

                this.submittedDate = data.result.medical_history_info.submitted_date;
                if (this.submittedDate) {
                  // console.log("Inside");
                  var dobSplit = (this.submittedDate).split("-");
                  if (dobSplit.length > 0) {
                    this.submittedDate = { year: parseInt(dobSplit[0]), month: parseInt(dobSplit[1]), day: parseInt(dobSplit[2]) };
                  }

                  //  this.dob = new Date(this.dob);
                }
                else {
                  this.submittedDate = null;
                }
                this.updateMedicalHistoryForm.controls['submittedDate'].setValue(this.submittedDate);
                // if(this.submittedDate)
                // {
                //   console.log("Inside");
                //   var dobSplit = (this.submittedDate).split("-"); 
                //   if(dobSplit.length>0)
                //   {                
                //     this.submittedDate = {year: parseInt(dobSplit[0]), month:parseInt(dobSplit[1]), day: parseInt(dobSplit[2])};
                //   }
                // }
                // else 
                // {
                //   this.submittedDate = null;
                // }

                // $('html, body').animate({scrollTop: '0px'}, 300);
                // this.clearSignature();
                // this.MedicalHistoryFormSUpdatedSuccessfully = 'display_block';          
                // setTimeout(()=>{                         
                //   this.MedicalHistoryFormSUpdatedSuccessfully = 'display_none';   
                // }, 5000); 
                this.showSuccessPage = true;
              }
              else {
                this.MedicalHistoryFormSUpdatedError = 'display_block';
                this.MedicalHistoryFormSUpdatedErrorMsg = data.message;
                setTimeout(() => {
                  this.MedicalHistoryFormSUpdatedError = 'display_none';
                }, 5000);
                // Swal.fire({
                //   icon: 'error',
                //   title: 'Oops...',
                //   text: data.message
                // });
              }
            });

        },
        (error) => {
          this.appservices.showDebugMessage(error);
        }
      );
    }

    // if()
    // {
    //   $params['acidic_drink']         = isset($post_data['acidic_drink']) ? $post_data['acidic_drink'] : null;
    //   $params['acidic_drink_details'] = isset($post_data['acidic_drink_details']) ? $post_data['acidic_drink_details'] : null;
    //   $params['sugar']                = isset($post_data['sugar']) ? $post_data['sugar'] : null;
    //   $params['smoke']                = isset($post_data['smoke']) ? $post_data['smoke'] : null;
    //   $params['tobacco']              = isset($post_data['tobacco']) ? $post_data['tobacco'] : null;
    //   $params['alcohol']              = isset($post_data['alcohol']) ? $post_data['alcohol'] : null;
    //   $params['warnings']             = isset($post_data['warnings']) ? $post_data['warnings'] : null;
    //   $params['heart']                = isset($post_data['heart']) ? $post_data['heart'] : null;
    //   $params['chest']                = isset($post_data['chest']) ? $post_data['chest'] : null;
    //   $params['blood']                = isset($post_data['blood']) ? $post_data['blood'] : null;
    //   $params['medication']           = isset($post_data['medication']) ? $post_data['medication'] : null;
    //   $params['surgeries']            = isset($post_data['surgeries']) ? $post_data['surgeries'] : null;
    //   $params['learning_disabilities']    = isset($post_data['learning_disabilities']) ? $post_data['learning_disabilities'] : null;
    //   $params['sleeping_disorder']    = isset($post_data['sleeping_disorder']) ? $post_data['sleeping_disorder'] : null;
    //   $params['allergies']            = isset($post_data['allergies']) ? $post_data['allergies'] : null;
    //   $params['other']                = isset($post_data['other']) ? $post_data['other'] : null;
    //   $params['smile']                = isset($post_data['smile']) ? $post_data['smile'] : null;
    //   $params['smile_details']        = isset($post_data['smile_details']) ? $post_data['smile_details'] : null;
    //   $params['purpose']              = isset($post_data['purpose']) ? $post_data['purpose'] : null;
    //   $params['last_dental_visit']    = isset($post_data['last_dental_visit']) ? $post_data['last_dental_visit'] : null;
    //   $params['hear_about_us']        = isset($post_data['hear_about_us']) ? $post_data['hear_about_us'] : null;
    //   $params['agree_person']         = isset($post_data['agree_person']) ? $post_data['agree_person'] : null;
    //   $params['print_name']           = isset($post_data['print_name']) ? $post_data['print_name'] : null;
    //   $params['submitted_date']       = isset($post_data['submitted_date']) ? $post_data['submitted_date'] : null;
    // }
  }

  get current_password() { return this.changePasswordForm.get('current_password'); }

  get new_password() { return this.changePasswordForm.get('new_password'); }

  get confirm_password() { return this.changePasswordForm.get('confirm_password'); }


  openCropperDialog(event: Event) {
    const target = event.target as HTMLInputElement;
    var isValid = true;
    if (target.files && target.files.length > 0) {
      if (target.files[0].name != '') {
        var filename = target.files[0].name;
        var fileExt = filename.split('.').pop();
        var imgArray: Array<string> = ['jpg', 'jpeg', 'png'];
        var isValidImage: any = imgArray.filter(name => name.includes(fileExt));
        if (isValidImage === '') {
          isValid = false;
        }
      }
      // console.log(" fileExt : " + fileExt );
      // var video:Array<string> = ['mp4','mov','wmv','avi','avchd','flv','f4v','swf','mkv','webm'];
    }

    if (!isValid) return false;
    $("body").addClass("modal-open");
    this.appservices.serviceMessages('vs-messages', '', '');
    let user_id = this.appservices.getSession("user_id", false);
    this._dialog.open<CropperDialogComponent, Event>(CropperDialogComponent, {
      data: event,
      width: 320,
      disableClose: true
    }).afterClosed.subscribe((result?: ImgCropperEvent) => {

      $("body").removeClass("modal-open");
      if (result) {

        $('.continue_btn').html('Please wait...').attr('disabled', true);

        $('#overlay1').addClass('overlay');
        $('.upload_preview_span').addClass("spinner-border");
        $('.please_wait_msg').html('Please wait...');

        /* $('.avatar_preview' + rowid).removeClass('edit_icon_hover');
        $('.upload_preview_span' + rowid).addClass("spinner-border");
        $('.upload_inner_section' + rowid).addClass("image_brightness");
        $('.image_upload' + rowid).removeClass('cursor_pointer').hide(); */

        // console.log("result.dataURL :  " + result.dataURL);
        this.profile_img = result.dataURL;
        this.cropped_url_blob = this.appservices.convertDataUrlToBlob(result.dataURL);
        let fileExt = result.dataURL.split(';')[0].split('/')[1];
        this.folder = 'user_profile_images/';
        //  console.log(" this.user_id :  " + user_id);
        this.file_name = user_id + '/image_' + this.todaysDataTime + "." + fileExt;
        this.profile_img_complete_path = this.folder + this.file_name;
        let thisobject = this;
        let greetingPromise = this.appservices.uploadToS3(this.cropped_url_blob, this.file_name, this.folder, fileExt);
        greetingPromise.then(function (resolveOutput) {
          // console.log(" resolveOutput : " + JSON.stringify(resolveOutput));
          $('#overlay1').removeClass('overlay');
          $('.upload_preview_span').removeClass("spinner-border");
          //  $('.upload_inner_section').removeClass("image_brightness");
          $('.please_wait_msg').html('');
          $('.continue_btn').html('Continue').attr('disabled', false);
          //  this.appservices.setSession("profile_picture",resolveOutput["Location"], false);
          //  thisobject.pushImageDataInToArray(resolveOutput["Location"]);
          /* thisobject.count_value = thisobject.count_value - 1;
          $('.avatar_preview' + rowid).addClass('edit_icon_hover');
          $('#overlay1' + rowid).removeClass('overlay');
          $('.upload_preview_span' + rowid).removeClass("spinner-border");
          $('.upload_inner_section' + rowid).removeClass("image_brightness");
          $('.please_wait_msg' + rowid).html('');
          // console.log(" this.ohrdata.length : " + this.ohrdata.length);
          // console.log(" this.ohrdata.length : " + thisobject.getOHRDataLength());
          if(thisobject.getOHRDataLength() >= 3 && $('#uploadImage0').length >0 )
          {
            $('#num2').removeClass('disabled');
            $('.upload-teeth-continue-btn').removeAttr('disabled');
          }       
          if (thisobject.count_value == 0)
            // $('#circles > div').removeClass('disabled');
            $('.continue_btn').html('Continue').attr('disabled', false); */
        }, function (rejectOutput) {
        });
        this.updateProfileForm.patchValue({
          profile_image: result.dataURL
        });
        this._cd.markForCheck();
      }
    });
  }

  pushImageDataInToArray(location) {
    // console.log(" this.updateProfileForm.valid : " + this.updateProfileForm.valid);
    if (this.updateProfileForm.valid) {
      // console.log(" 3 false ");
      this.isUpdateProfileButtonDisabled = false;
    }
    else {
      // console.log(" 3 true ");
      this.isUpdateProfileButtonDisabled = true;
    }
    // this.tempProfilePic = location;
    // this.appservices.setSession("profile_picture",environment.S3_BASE_URL+location, false);
  }

  deleteUserAlert() {
    /* Swal.fire({
      title: 'Are you sure want to delete?',
      text: 'You can not undo this Operation!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((response: any) => {
      if (response.value) {
  
        this.deleteMyAccount();
  
      } else if (response.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire(
        //   'Cancelled',
        //   'Your imaginary file is safe',
        //   'error'
        // )
      }
    }) */
    Swal.fire({
      title: "Delete My Account",
      text: "Please enter your Password to delete your account",
      input: 'password',
      allowOutsideClick: true,
      showCloseButton: true,
      inputAutoTrim: true,
      inputValidator: (value) => {
        if ((value).length > 20) {
          return 'You cannot enter more than 20 characters';
        }
        else if (!value) {
          return 'Please enter the Password';
        }
      }
    }).then((result) => {
      if (result.value) {
        // console.log("Result: " + result.value);               
        this.userPassword = result.value;
        this.deleteMyAccount();
      }
      else if (result.isConfirmed) {
        Swal.fire('Password required', 'please enter the password', 'error');
      }
    });

  }

  tabChange(currentTab) {
    if (currentTab == 'password') {
      this.showMedicalHistoryForms = false;
      this.profileUpdatedSuccessfully = 'display_none';
      this.profileUpdatedError = 'display_none';
      this.isPasswordFormSubmitted = false;
    }
    else if (currentTab == 'profile') {
      this.showMedicalHistoryForms = false;
      this.passwordUpdatedSuccessfully = 'display_none';
      this.passwordUpdatedError = 'display_none';
      this.isSubmitted = false;
    }
    else if (currentTab == 'medical_info') {
      this.passwordUpdatedSuccessfully = 'display_none';
      this.passwordUpdatedError = 'display_none';
      this.isSubmitted = false;
      // $(".nav-underline a:first").trigger('click');    
      setTimeout(() => {
        document.getElementById('patientinfo-link').click();
      }, 100);
    }
  }

  /* checkPasswordValidation()
  {
    let password = this.changePasswordForm.get('new_password').value;
    var check = true;
    var regExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/;
    if (regExp.test(password)) {
      check = false;
    }
  } */

  getErrorList(errorObject) {
    return Object.keys(errorObject);
  }


  signInHasError(status: any) {
    // console.log(" Sign in has error called ");
    if (status == null) {
      this.signInValidNumber = false;
      this.phoneSignIn.setValue(null);
    } else {
      this.signInValidNumber = status;
    }
  };


  signInGetNumber(n: string) {
    this.signInPhoneNumber = n;
  };

  signInOnCountryChange(c: any) {
    // console.log(" Onchange clicked  " + JSON.stringify(c));
    this.signInSelectedCountryCode = '+' + c.dialCode;
    let up: string = c.iso2;
    this.signInSelectedCountry = up.toUpperCase();

    this.country_name = c.iso2;
    this.countryCodeNumber = c.dialCode;
    // console.log(this.country_name );
    // console.log(this.countryCodeNumber );
    this.appservices.setSession("country_name", this.country_name, false);
    this.appservices.setSession("dial_code", this.countryCodeNumber, false);
    this.isSubmitted = false;
    // this.setValidators();

  };

  homePhoneOnCountryChange(c: any) {

    this.homePhoneSelectedCountryCode = '+' + c.dialCode;
    let up: string = c.iso2;
    this.homePhoneSelectedCountry = up.toUpperCase();

    this.homePhone_country_name = c.iso2;
    this.homePhonecountryCodeNumber = c.dialCode;
    //  console.log(this.country_name );
    //  console.log(this.homePhonecountryCodeNumber );

    this.appservices.setSession("homePhone_country_name", this.homePhone_country_name, false);
    this.appservices.setSession("homePhonecountryCodeNumber", this.homePhonecountryCodeNumber, false);

  };

  MobileNumberOnCountryChange(c: any) {

    this.mobilePhoneSelectedCountryCode = '+' + c.dialCode;
    let up: string = c.iso2;
    this.mobilePhoneSelectedCountry = up.toUpperCase();

    this.mobilePhone_country_name = c.iso2;
    this.mobilePhonecountryCodeNumber = c.dialCode;
    // console.log(this.country_name );

    this.appservices.setSession("mobilePhone_country_name", this.mobilePhone_country_name, false);
    this.appservices.setSession("mobilePhonecountryCodeNumber", this.mobilePhonecountryCodeNumber, false);

  };

  EmergencyContactOnCountryChange(c: any) {

    this.emergencyContactSelectedCountryCode = '+' + c.dialCode;
    let up: string = c.iso2;
    this.emergencyContactSelectedCountry = up.toUpperCase();

    this.emergencyContact_country_name = c.iso2;
    this.emergencyContactcountryCodeNumber = c.dialCode;
    // console.log(this.country_name );

    this.appservices.setSession("emergencyContact_country_name", this.emergencyContact_country_name, false);
    this.appservices.setSession("emergencyContactcountryCodeNumber", this.emergencyContactcountryCodeNumber, false);

  };


  onBackToHome() {
    // this.router.navigate(['oral-health-score-preference']);
    this._location.back();
  }

  onUpdateHealthFund(event) {
    this.healthFundStatus = event.target.value;
    this.health_fund = this.healthFundStatus;
  }

  onUpdateEmergencyContact(event) {
    this.emergency_contact_name = event.target.value;
  }


  onUpdateTitle(event) {
    this.title = event.target.value;
  }

  onUpdateGender(event) {
    this.gender = event.target.value;
    // console.log(" this.gender : " + this.gender);
  }

  onUpdateOccupation(event) {
    this.occupation = event.target.value;
  }

  onUpdateHomeAddress(event) {
    this.home_address = event.target.value;
  }

  onUpdateMedicationDetails(event) {
    this.medication_details = event.target.value;
  }

  onUpdatesurgeryDetails(event) {
    this.surgery_details = event.target.value;
  }

  onUpdatePurposeOfVisit(event) {
    this.purpose_of_visit = event.target.value;
    // console.log(" this.purpose_of_visit "  + this.purpose_of_visit);
  }

  onUpdateHearAboutus(event) {
    this.hearAboutUs = event.target.value;

  }

  onUpdateLastDentalVisit(event) {
    this.last_dental_visit = event.target.value;
  }

  onUpdatePrintName(event) {
    this.printName = event.target.value;
  }


  onUpdateHearAboutUs(event) {
    this.hearAboutUs = event.target.value;
  }


  onUpdateDoctor(event) {
    this.doctor = event.target.value;
  }

  onUpdateInsuranceNumber(event) {
    this.insurance_number = event.target.value;
  }

  onUpdateVisitReason(event) {
    this.purpose_of_visit_reason = event.target.value;
  }

  onUpdateHearAboutUsReason(event) {
    this.hear_about_us_reason = event.target.value;
  }

  high_frquency_sugar(type) {
    this.highFrequencySugar = type;
  }


  fizzy_acidic_drinks(type) {
    this.fizzyAcidicDrinks = type;
    // console.log(" type : " + type);  
    if (type === 'no') {
      this.updateMedicalHistoryForm.controls['fizzy_acidic_drinks_details'].setValue('');
    }
  }

  autism_status(type) {
    // console.log(" this.type : " + type );
    this.autismStatus = type;
    if (type === 'No') {
      this.updateMedicalHistoryForm.controls['learning_disabilities_details'].setValue('');
    }
    // console.log(" this.autismStatus : " + this.autismStatus ); 
  }

  clearSignature() {
    this.signaturePad.clear();
    this.originalSign = '';
    this.signature = '';
    this.exisitingSignature = '';
    this.is_signature_new = '';
    $('.saveSignature').attr('disabled', false);
  }

  sleeping_disorder(type) {
    this.sleepingDisorder = type;
  }


  happy_smile(type) {
    this.happySmile = type;
  }

  agree_person(type) {
    this.agreePerson = type;
  }

  onAnswer(event, rowid, type, ques_id, btn_id?: any) {
    // this.isSubmitButtonDisabled  = true;
    this.appservices.serviceMessages('vs-messages', '', 'danger');
    let index = this.answers.findIndex(item => item.question_id === ques_id);
    if (index >= 0) {
      this.answers.splice(index, 1);
    }
    // console.log($(".custom_button" + rowid + btn_id).html())
    if (type == "1" || type == "2") {
      let ans = $(".custom_button" + rowid + btn_id).html();
      this.latest_ans = '[' + ans + ']';
    }
    else {
      if (event.target.value != '')
        this.latest_ans = event.target.value;
    }
    if (this.latest_ans != undefined) {
      this.obj = '{"question_id":"' + ques_id + '","type":"' + type + '","answers":"' + this.latest_ans + '"}';
      this.answers.push(JSON.parse(this.obj));
    }

    let rootElements = document.getElementsByClassName("oral_hygiene_btn" + rowid)[0];
    let buttonElements = rootElements.getElementsByClassName("custom_btn");
    if (type == "1" || type == "2") {
      for (var i = 0; i < buttonElements.length; i++) {
        buttonElements[i].classList.remove("active");
      }
    }
    let element = <HTMLInputElement>event.target;
    if (element.classList.contains("active"))
      element.classList.remove("active");
    else element.classList.add("active");


    var domRepresentation = document.getElementsByClassName('options_sec');
    // console.log(" domRepresentation.length " + domRepresentation.length); 
    if (this.answers.length === domRepresentation.length) {
      // this.isSubmitButtonDisabled  = false;
      $('#num3').removeClass('disabled');
    }
  }

  last_dental_visit_period(type) {
    this.last_dental_visit = type;
  }


  checkWarningIds(warningsId) {
    if (this.availableWarnings) {
      let obj = this.availableWarnings.find(o => o.id === warningsId);
      if (obj) {
        return true;
      }
      return false;
    }
    else {
      return false;
    }
  }

  getWarningsValue(warningsId) {
    if (this.availableWarnings) {
      let obj = this.availableWarnings.find(o => o.id === warningsId);
      // console.log(" obj " + JSON.stringify(obj));
      if (obj) {
        return obj.details;
      }
      return "";
    }
    else {
      return "";
    }
  }


  checkheartIds(warningsId) {
    if (this.heart_warning) {
      let obj = this.heart_warning.find(o => o.id === warningsId);
      if (obj) {
        return true;
      }
      return false;
    }
    else {
      return false;
    }
  }

  getheartValue(warningsId) {
    if (this.heart_warning) {
      let obj = this.heart_warning.find(o => o.id === warningsId);
      // console.log(" obj " + JSON.stringify(obj));
      if (obj) {
        return obj.details;
      }
      return "";
    }
    else {
      return "";
    }
  }


  checkchestIds(warningsId) {
    if (this.chest_warning) {
      let obj = this.chest_warning.find(o => o.id === warningsId);
      if (obj) {
        return true;
      }
      return false;
    }
    else {
      return false;
    }
  }

  getchestValue(warningsId) {
    if (this.chest_warning) {
      let obj = this.chest_warning.find(o => o.id === warningsId);
      // console.log(" obj " + JSON.stringify(obj));
      if (obj) {
        return obj.details;
      }
      return "";
    }
    else {
      return "";
    }
  }

  checkbloodIds(warningsId) {
    if (this.blood_warning) {
      let obj = this.blood_warning.find(o => o.id === warningsId);
      if (obj) {
        return true;
      }
      return false;
    }
    else {
      return false;
    }
  }

  getbloodValue(warningsId) {
    if (this.blood_warning) {
      let obj = this.blood_warning.find(o => o.id === warningsId);
      // console.log(" obj " + JSON.stringify(obj));
      if (obj) {
        return obj.details;
      }
      return "";
    }
    else {
      return "";
    }
  }

  checkallergyIds(warningsId) {
    if (this.allergies_warning) {
      let obj = this.allergies_warning.find(o => o.id === warningsId);
      if (obj) {
        return true;
      }
      return false;
    }
    else {
      return false;
    }
  }

  getallergyValue(warningsId) {
    if (this.allergies_warning) {
      let obj = this.allergies_warning.find(o => o.id === warningsId);
      // console.log(" obj " + JSON.stringify(obj));
      if (obj) {
        return obj.details;
      }
      return "";
    }
    else {
      return "";
    }
  }

  checkotherIds(warningsId) {
    if (this.other_warnings) {
      let obj = this.other_warnings.find(o => o.id === warningsId);
      if (obj) {
        return true;
      }
      return false;
    }
    else {
      return false;
    }
  }

  getotherValue(warningsId) {
    if (this.other_warnings) {
      let obj = this.other_warnings.find(o => o.id === warningsId);
      // console.log(" obj " + JSON.stringify(obj));
      if (obj) {
        return obj.details;
      }
      return "";
    }
    else {
      return "";
    }
  }

  BackToHome() {
    // console.log(" move_home " + move_home );
    this.bypass_select_service = this.appservices.getSession("bypass_select_service", false);
    this.widget_service_type = this.appservices.getSession("widget_button_type", false);
    // console.log( "  this.bypass_select_service : " + this.bypass_select_service + "  this.widget_service_type : " + this.widget_service_type);
    if ((this.widget_service_type != "2") && (this.bypass_select_service != '0')) {
      if (this.widget_service_type == "0") {
        this.appservices.setSession("uploadImageBtnSelected", false, false);
        this.router.navigate(['oral-health-score-preference']);
      }
      else if (this.widget_service_type == "1") {
        this.appservices.setSession("uploadImageBtnSelected", true, false);
        this.router.navigate(['contact-information']);
      }
    }
    else {
      (this.widget_service_type == "2");
      this.router.navigate(['get-started/' + this.appservices.getSession("token", false)]);
    }
  }

  navigateToOHR() {
    this.router.navigate(['upload-teeth-photos']);
  }

  navigateToDMS() {
    this.router.navigate(['upload-smile-photo']);
  }
}

function MatchPassword(control: AbstractControl) {
  let parent = control.parent;
  if (parent) {
    let password = parent.get('new_password').value;
    let confirmPassword = control.value;
    // console.log(" password : " + password);
    // console.log(" confirmPassword : " + confirmPassword);
    if (password != confirmPassword) {
      return { ConfirmPassword: true };
    } else {
      return null;
    }
  } else {
    return null;
  }
}

function checkPasswordValidation(control: AbstractControl) {
  let parent = control.parent;
  // console.log("parent : "+parent); 

  if (parent) {
    // console.log(" parent.get('current_password').value : " + parent.get('current_password').value );
    // let password = parent.get('new_password').value;
    let password = control.value;

    let currentPassword = parent.get('current_password').value;
    // console.log(" currentPassword : " + currentPassword);
    let confirmPassword = parent.get('confirm_password').value;
    // console.log(" confirmPassword : " + confirmPassword);

    if (currentPassword === password) {
      return { currentPasswordValidation: true };
    }

    var regExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/;
    if (regExp.test(password)) {
      // console.log(" Inside password validation ");
      // if (((password.trim())!==(confirmPassword.trim()))) {
      //   console.log("password != confirmPassword ");
      //   return {ConfirmPassword: true };
      // }
      return null;
    }
    else {
      // console.log(" password validation failed ");
      if (password !== confirmPassword && confirmPassword !== '') {
        return { passwordValidation: true, ConfirmPassword: true };
      } else {
        return { passwordValidation: true };
      }
    }
  } else {
    return null;
  }
}

$('body').on('cut copy paste', '.swal2-input', function (e) {
  // do something   
  e.preventDefault();
});


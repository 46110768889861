<!---India code starts--->

<div class="section_wrapper pt-0 position-relative Australia-section" *ngIf="!isDomainAus">

  <div class="section-banner_wrapper position-relative">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="banner_wrap d-flex align-items-center justify-content-between justify-content-lg-center">
            <div class="banner-content">
              <h1>
                What’s Next

              </h1>
            </div>
            <div class="banner-image">
              <img src="assets/images/rag-report.png" class="img-fluid d-none d-sm-block" alt="" />
              <img src="assets/images/rag-report-mobile.png" class="img-fluid d-block d-sm-none" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Banner -->
  <div class="rag-report-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <div class="tile">
            <div class="section-heading text-center">
              <h3 class="mb-3">{{ imageSelectionCount == 1 ? "Your Smile Check Rating" : "Your Oral Health Rating" }}
              </h3>
            </div>
            <!-- report graph -->
            <div class="reportscore-card">
              <div class="reportscore-lt">
                <div class="img-block">
                  <img [src]="
                      overall_score <= 4
                        ? 'assets/images/severe_g.png'
                        : overall_score >= 5 && overall_score <= 7
                        ? 'assets/images/poor_g.png'
                        : overall_score >= 8 && this.overall_score <= 10
                        ? 'assets/images/good_g.png'
                        : ''
                    " class="img-fluid" alt="" />
                </div>
              </div>
            </div>
            <div class="text-center reportsocre-info">
              <p>{{ rating_explanation }}</p>
              <!-- <p class="bold">
                {{ rating_explanation }}
              </p> -->
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-6">
          <div class="tile">
            <div class="section-heading text-center">
              <h3 class="mb-1">What’s Next?</h3>
              <p>Discover our services to improve your Oral Health</p>
              <!-- <p>Discover our other services to enhance your experience</p> -->
            </div>
            <div class="next_wrapper">
              <!-- Request A Dental Appointment Card -->
              <app-appointment-card *ngIf="!isDaburFlow && isRequestForAppointmentCard" class="next-card"
                (bookNowClick)="onBookNow()"></app-appointment-card>
              <!-- Get Product Discounts -->
              <app-product-discount-card *ngIf="!isDaburFlow && isProductDiscountCard" class="next-card"
                (productDiscountClick)="onProductDiscountClick()"></app-product-discount-card>
              <!-- Buy An Oral Health Report -->
              <app-buy-report-card *ngIf="!isDaburFlow && !isBupaFlow && isBuyReportCard && !isPaymentSuccessFul" class="next-card"
                (buyReportClick)="onBuyReportClick()"></app-buy-report-card>
              <app-download-report-card *ngIf="isDaburFlow || isBupaFlow" class="next-card"></app-download-report-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!---India code ends--->

<!---Australia code Starts--->
<div class="dynamic_wrapper Australia-section" *ngIf="isDomainAus">
  <div class="vs-messages"></div>
  <div class="dms_wrapper">
    <app-back-to-home></app-back-to-home>
    <div class="dms_content">
      <div class="sec_inner_content1 p-0">
        <div class="title_sec bg-grey max_width_800 br_10">
          <div class="d-flex align-items-center ohr_payment_header_div">
            <div class="w-100">
              <h4 class="font_20 font-weight-700 mb-3 text-center">
                <b>{{payment_title}}</b>
              </h4>

              <div class="d-flex mx-auto canvas-text-holder">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" [ngClass]="imageContainer">
                    <div class="canvas-container d-flex align-items-center justify-content-center">
                      <canvas id="canvas" #graphCanvas></canvas>
                      <img [ngClass]="meterClassName" id="needleImage" src="assets/images/Needle.png"
                        alt="{{OHRText}}" />
                      <div class="smiley_image">
                        <div class="dot">
                          <img class="smiley_img" src="{{OHRImage}}" alt="{{OHRText}}" />
                        </div>
                      </div>
                      <p class="rating_txt font_16 mt-2 text-center" [ngClass]="appservices.getColor(overall_score)">
                        <b>{{OHRText}}</b>
                      </p>
                    </div>
                  </div>
                  <div class="canvas-title col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <p class="font_12">
                      {{rating_explanation}}
                    </p>
                    <p class="mt-2 font_10">{{payment_sub_title}}</p>
                  </div>
                </div>

                <div></div>
                <div></div>
              </div>
            </div>
          </div>
          <hr class="m-0" />

          <div class="p_30" *ngIf="payment_type!=''&&ohr_flow!='2'">
            <h4 class="font_20 font-weight-700 mb-2 text-center">
              <b>{{benefits_title}}</b>
            </h4>
            <p class="text-center font_14 mb-2">{{benefits_sub_title}}</p>
            <div class="payment-access-carousel mb-5">
              <ngx-slick-carousel class="payment_access_teeth_carousel" #slickModal="slick-carousel"
                [config]="slideConfig" (init)="slickInit($event)">
                <div ngxSlickItem *ngFor="let data of dentalImages;let i=index;" class="slide">
                  <div class="teeth_img teeth_hover pointer_link">
                    <img [defaultImage]="defaultImage" [lazyLoad]="data.url" [errorImage]="defaultImage"
                      [alt]="data.title" />
                  </div>
                </div>
              </ngx-slick-carousel>
            </div>
            <div class="payment_sub_title"></div>
            <div>
              <div class="payment-access-benefits">
                <h4 class="font_16 font-weight-700 mb-3"><b>Benefits</b></h4>
                <div class="d-flex align-items-center mb-3">
                  <img src="assets/images/green_tick.png" alt="tick" class="green_tick mr-2" />
                  <p class="font_16">{{benefits_one}}</p>
                </div>
                <div class="d-flex align-items-center mb-3" *ngIf="benefits_two!=''">
                  <img src="assets/images/green_tick.png" alt="tick" class="green_tick mr-2" />
                  <p class="font_16">{{benefits_two}}</p>
                </div>
                <div class="d-flex align-items-center mb-3" *ngIf="benefits_three!=''">
                  <img src="assets/images/green_tick.png" alt="tick" class="green_tick mr-2" />
                  <p class="font_16">{{benefits_three}}</p>
                </div>
              </div>
              <div class="d-md-flex align-items-center justify-content-center">
                <p class="text-center mr-3">
                  A$<b class="font_40">{{payment_amount}}</b> /Report
                </p>
              </div>
              <div class="text-center">
                <button (click)="makePayment()"
                  class="btn button_links btn_width_1 continue_btn btn_color mx-0 mt-2 justify-content-center align-items-center">
                  {{payment_button_text}}
                </button>
              </div>
            </div>
          </div>
          <div class="p_30" *ngIf="payment_type==''&&ohr_flow!='2'">
            <div class="text-center">
              <button (click)="makePayment()"
                class="btn button_links btn_width_1 continue_btn btn_color mx-0 mt-2 justify-content-center align-items-center">
                {{payment_button_text}}
              </button>
            </div>
          </div>

          <div class="p_30" *ngIf="payment_type!=''&&ohr_flow=='2'&&show_benefits_div==true">
            <h4 class="font_20 font-weight-700 mb-2 text-center">
              <b>{{benefits_title}}</b>
            </h4>
            <p class="text-center font_14 mb-2">{{benefits_sub_title}}</p>
            <div class="payment-access-carousel mb-5">
              <ngx-slick-carousel class="payment_access_teeth_carousel" #slickModal="slick-carousel"
                [config]="slideConfig" (init)="slickInit($event)">
                <div ngxSlickItem *ngFor="let data of dentalImages;let i=index;" class="slide">
                  <div class="teeth_img teeth_hover pointer_link">
                    <img [defaultImage]="defaultImage" [lazyLoad]="data.url" [errorImage]="defaultImage"
                      [alt]="data.title" />
                  </div>
                </div>
              </ngx-slick-carousel>
            </div>
            <div class="payment_sub_title"></div>
            <div>
              <div class="payment-access-benefits">
                <h4 class="font_16 font-weight-700 mb-3"><b>Benefits</b></h4>
                <div class="d-flex align-items-center mb-3">
                  <img src="assets/images/green_tick.png" alt="tick" class="green_tick mr-2" />
                  <p class="font_16">{{benefits_one}}</p>
                </div>
                <div class="d-flex align-items-center mb-3" *ngIf="benefits_two!=''">
                  <img src="assets/images/green_tick.png" alt="tick" class="green_tick mr-2" />
                  <p class="font_16">{{benefits_two}}</p>
                </div>
                <div class="d-flex align-items-center mb-3" *ngIf="benefits_three!=''">
                  <img src="assets/images/green_tick.png" alt="tick" class="green_tick mr-2" />
                  <p class="font_16">{{benefits_three}}</p>
                </div>
              </div>
              <div class="d-md-flex align-items-center justify-content-center">
                <p class="text-center mr-3">
                  A$<b class="font_40">{{payment_amount}}</b> /Report
                </p>
              </div>
              <div class="text-center">
                <button (click)="makePayment()"
                  class="btn button_links btn_width_1 continue_btn btn_color mx-0 mt-2 justify-content-center align-items-center">
                  {{payment_button_text}}
                </button>
              </div>
            </div>
          </div>
          <div class="p_30" *ngIf="ohr_flow=='2'&& show_benefits_div==false">
            <div class="text-center" *ngIf="payment_type!=''">
              <button (click)="show_benefits()"
                class="btn button_links btn_width_1 continue_btn btn_color mx-0 mt-2 justify-content-center align-items-center">
                Get my personalised report
              </button>
            </div>
            <div class="text-center" *ngIf="payment_type==''">
              <button (click)="makePayment()"
                class="btn button_links btn_width_1 continue_btn btn_color mx-0 mt-2 justify-content-center align-items-center">
                Get my personalised report
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!---Australia code Ends--->
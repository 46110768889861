import { Component, ChangeDetectorRef, ElementRef, ViewChild, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { LyDialog } from '@alyle/ui/dialog';
import { ImgCropperEvent } from '@alyle/ui/image-cropper';
import * as $ from 'jquery';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer } from '@angular/platform-browser';
import * as Bowser from "bowser";
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { environment } from 'src/environments/environment';
import { MessageService } from 'src/app/profile-pic-service.service';
import { Services } from 'src/app/helpers/Services';
import { AusCropperDialogComponent } from 'src/app/global_components/aus-cropper-dialog/aus-cropper-dialog.component';
import { NgxImageCompressService } from 'ngx-image-compress';
declare const loadScript: any;
declare var gtag;
declare const fbq;

@Component({
  selector: 'app-upload-teeth-images',
  templateUrl: './upload-teeth-images.component.html',
  styleUrls: ['./upload-teeth-images.component.css']
})
export class UploadTeethImagesComponent implements OnInit {
  youtube_1: any;
  youtube_2: any;
  youtube_3: any;
  youtube_4: any;
  youtube_5: any;
  modalRef: BsModalRef;
  cropped?: string;
  termsAndConditions: any = '-1';
  @ViewChild('fileInput') el: ElementRef;
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  uploadImageInputForm: FormGroup;
  submitted = false;
  defaultImage = 'assets/images/upload-image-loader.gif';
  // imageUrl: any;
  // editFile: boolean = true;
  folder: any;
  todaysDataTime;
  cropped_url_blob: Blob;
  // static httpClient: any;
  appservices: Services;
  apirequest: any[] = [];
  ohrdata: any = [];
  ohrStoredImages: any = [];
  extra_images: any = [];
  // imageError: any;
  length: any = 0;
  // urls = new Array<string>();
  // unique_key: any;
  // today = new Date();
  responseData: any=[];
  imagesInfo: any;
  count: any;
  object: object = {};
  images = [];
  flag: boolean = false;
  isfrontImage: any;
  input: any;
  displayImage: any;
  displayTitle: any;
  count_value: number = 0;
  isUploadButtonDisabled: boolean = true;
  displayHeader: any = 'Oral Health Rating';
  widget_flow_type: any = '';
  currentDate: any = '';
  get_contact_information_later: any = '';
  guest_session_uuid: any = '';
  guest_user_id: any = '';
  analyzingText: any = 'Smilo.ai is analysing the pictures you have submitted';
  applyStyle: string = 'loaderImage';
  // Inherited from Contact Form 
  isSubmitted = false;
  InputForm: FormGroup;
  user: any;
  user_details: any;
  countryCodeNumber: any;
  location: any;
  country_name: any = '';
  // isDigitsOnly: any;
  reg: any;
  // privacy_site: string = environment.PRIVACY;
  // terms_site: string = environment.TERMS;
  // consent_form: string = environment.CONSENT_FORM;
  // staticImage: string = "assets/images/sign-up.png";
  isButtonDisabled: boolean = true;
  practiceBranches: any = '';
  userSelectedAddress: string = '';
  isBranchesButtonDisabled: boolean = true;
  display_branches_div: boolean = false;
  display_contactForm_div: boolean = true;
  // checkboxChecked: boolean = false;
  firstName: string = '';
  lastName: string = '';
  preSelectedBranch: any = '';
  uploadImageBtnSelected: any = false;
  header_display_msg: any = '';
  signInValidNumber: any = '';
  phoneSignIn: any = '';
  signInPhoneNumber: any = '';
  signInSelectedCountryCode: any = '';
  signInSelectedCountry: any = '';
  contactus_country_name: any = '';
  redirecting_url: any = '';
  sendSubjectMessage: any = '';
  // profile_img: any = 'assets/images/default-profile.png';
  contactFormBtn: boolean = false;
  showContactForm: boolean = false;
  showLoadingForm: boolean = false;
  backgroundProcessing: boolean = true;
  contactFormSubmitted: boolean = false;
  ohr_flow: any;
  forthSlideVisited: boolean = false;
  fifthSlideVisited: boolean = false;
  sixthSlideVisited: boolean = false;
  uploadImgClass: string = 'v1';
  after_image_upload_div: string = 'after_image_upload_div';
  imagesTitle: any;
  displayPreviousBtn: string = 'visibility_none';

  slideConfig = {
    "swipe": false,
    "swipeToSlide": false,
    "dots": true,
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "nextArrow": "<div onclick=\"Window.myComponent.checkingImageExist('right',this)\" class='nav-btn icon-right-arrow next-slide blue_color font_30'></div>",
    "prevArrow": "<div onclick=\"Window.myComponent.checkingImageExist('left',this)\" class='nav-btn icon-left-arrow prev-slide blue_color font_30'></div>",
    "infinite": false
  };

  slickInit(e) {

  }

  checkingImageExist(movementArea: any, e: any) {
    setTimeout(() => {
      this.disableArrow();
    }, 500);
    var continueFurther = true;
    if (movementArea == 'left') {
      $('.save_continue_btn_span').html('Save & Continue');
    }
    if (movementArea == 'right') {
      let OHRflow = this.ohr_flow;
      let OHRlength = this.getOHRDataLength();
      let currentIndex = ($('.slick-active').attr('data-slick-index'));
      if (currentIndex >= 0 && ($('#uploadImage0').length == 0 || $('#uploadImage1').length == 0 || $('#uploadImage2').length == 0)) {
        continueFurther = false;
      }
    }
    $('.upload-teeth-continue-btn').attr("disabled", true);
    setTimeout(() => {
      let currentIndex = ($('.slick-active').attr('data-slick-index'));
      if (!continueFurther) {
        if ($('#uploadImage2').length > 0) $('.upload-teeth-continue-btn').attr("disabled", false);
        return false;
      }
      if (currentIndex >= 3) { this.uploadImgClass = 'v2'; }
      this._cd.detectChanges();

      if (currentIndex == '3') { this.forthSlideVisited = true; }
      if (currentIndex == '4') { this.fifthSlideVisited = true; }
      if (currentIndex == '5') {
        $('.save_continue_btn_span').html('Get your Results');
        this.sixthSlideVisited = true;
      }
      if ($('#uploadImage' + currentIndex).length > 0) {
        $('.upload-teeth-continue-btn').attr("disabled", false);
      }

      if (($('#uploadImage0').length != 0 && $('#uploadImage1').length != 0 && $('#uploadImage2').length != 0))
        $('.upload-teeth-continue-btn').attr("disabled", false);

    }, 500);
    if (this.ohrdata.length >= 3) {
      $('.upload-teeth-continue-btn').attr("disabled", false);
    }
  }

  // src:any;
  constructor(private _dialog: LyDialog, private httpClient: HttpClient, private router: Router,
    private _cd: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private sanitizer: DomSanitizer,
    private messageService: MessageService,
    private imageCompress: NgxImageCompressService,) {
    this.appservices = new Services;
    Window["myComponent"] = this;

  }
  public getDeviceInfo() {
    let userAgent = Bowser.parse(window.navigator.userAgent);
    let browser_details = userAgent.browser.name + ' ' + userAgent.os.name + ' ' + userAgent.browser.version;
    return browser_details;
  }

  getLocationAddress() {
    let locationDetails;
    this.httpClient.get(environment.IPDATA_API + "?api-key=" + environment.IPDATA_KEY).subscribe((res: any) => {
      locationDetails = {
        "calling_code": res["calling_code"], "city": res["city"], "country_code": res["country_code"], "country_name": res["country_name"],
        "ip": res["ip"], "state": res["state"], "time_zone": res["time_zone"]["name"], "time_zone_offset": res["time_zone"]["offset"]
      };
      this.appservices.setSession("location_details", locationDetails, true);
    });
  }

  ngAfterViewInit() {
    let OHRflow = this.ohr_flow;
    let OHRlength = this.getOHRDataLength();

    $(".slick-dots").find('li').each(function () {
      $(this).find("button").prop("disabled", true);
    });
    $('.slick-arrow').addClass('display_none');
    $('.slick-arrow').addClass('slick-disabled');
    if ($("#agree").length > 0) {
      $("#agree").change(function () {
        OHRlength = $('.base64Image').length;
        $('.upload-teeth-continue-btn').attr("disabled", true);
        if (this.checked && OHRlength > 0) {
          $('.upload-teeth-continue-btn').removeAttr('disabled');
        }
      });
    }

  }
  ngOnInit(): void {
    this.appservices.setSession("current_url", "upload-teeth-images", false);

    this.get_contact_information_later = this.appservices.getSession("get_contact_information_later", false);
    this.guest_session_uuid = this.appservices.getSession("guest_session_uuid", false);
    this.guest_user_id = this.appservices.getSession("guest_user_id", false);

    this.ohr_flow = this.appservices.getSession("ohr_flow", false);

    this.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_ohr_entered', true);
    this.appservices.setSession("bannerTitleText", this.displayHeader, false);
    $('.sec_inner_content1').show();
    $('.analysing_sec').hide();

    this.currentDate = new Date();
    this.todaysDataTime = formatDate(this.currentDate, 'yyyyMMddhhmmss', 'en-US');
    this.appservices.setSession("ohr_unique_key", this.todaysDataTime, false);
    this.uploadImageInputForm = this.formBuilder.group({
      file0: ['', [Validators.required]],
      file1: ['', [Validators.required]],
      file2: ['', [Validators.required]],
      file3: ['', [Validators.required]],
      file4: ['', [Validators.required]],
      file5: ['', [Validators.required]]
    });
    this.extra_images = [{ "image": '', "title": "Any additional images" }];
    this.imagesInfo = [
      { "url": 'assets/images/teeth_front.png', "title": "Teeth Front View" },
      { "url": 'assets/images/teeth_right.png', "title": "Teeth Right View" },
      { "url": 'assets/images/teeth_left.png', "title": "Teeth Left View" },
      { "url": 'assets/images/teeth_upper.png', "title": "Teeth Upper Jaw" },
      { "url": 'assets/images/teeth_lower.png', "title": "Teeth Lower Jaw" },
      { "url": 'assets/images/teeth_ref.png', "title": "Any additional images" }
    ];
    this.responseData = [
      { "url": 'assets/images/Aus_images/teeth_1.png', "title": "Upload front view", "base64": '', "file_name": '', "content": "Take a selfie of your teeth and mouth using the front camera." },
      { "url": 'assets/images/Aus_images/teeth_2.png', "title": "Upload right view", "base64": '', "file_name": '', "content": "Take a selfie of your teeth and mouth using the front camera." },
      { "url": 'assets/images/Aus_images/teeth_3.png', "title": "Upload left view", "base64": '', "file_name": '', "content": "Take a selfie of your teeth and mouth using the front camera." },
      { "url": 'assets/images/Aus_images/teeth_4.png', "title": "Upload upper jaw", "base64": '', "file_name": '', "content": "Take a selfie of your teeth and mouth using the front camera." },
      { "url": 'assets/images/Aus_images/teeth_5.png', "title": "Upload lower jaw", "base64": '', "file_name": '', "content": "Take a selfie of your teeth and mouth using the front camera." },
      { "url": 'assets/images/Aus_images/teeth_6.png', "title": "Additional images", "base64": '', "file_name": '', "content": "Add any other images you like." }
    ];

    this.imagesTitle = [
      { "title": "Front view" },
      { "title": "Right view" },
      { "title": "Left view" },
      { "title": "Upper jaw" },
      { "title": "Lower jaw" },
      { "title": "Additional picture" }
    ];

    setTimeout(function () {
      $('.alert_close').remove();
    }, 30000);

    this.youtube_1 = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/VCH8zdrlfyU/?enablejsapi=1&version=3&wmode=transparent");
    this.youtube_2 = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/bIKvTz4ix38/?enablejsapi=1&version=3&wmode=transparent");
    this.youtube_3 = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/nBx7CQCO7LM/?enablejsapi=1&version=3&wmode=transparent");
    this.youtube_4 = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/OGTQLTImHk0/?enablejsapi=1&version=3&wmode=transparent");
    this.youtube_5 = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/cLEklsoh4ic/?enablejsapi=1&version=3&wmode=transparent");

    // Inherited from Contact Form   
    this.preSelectedBranch = this.appservices.getSession("user_selected_practice_branch", false);

    this.practiceBranches = this.appservices.getSession("branches_list", false);
    if (this.practiceBranches != undefined && this.practiceBranches != '') {
      this.practiceBranches = JSON.parse(this.practiceBranches);
      if (this.practiceBranches.length > 0) {
        this.display_branches_div = true;
        this.display_contactForm_div = false;
      }
    }

    this.reg = '/^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/';
    window.scrollTo(0, 0);
    $(window).scrollTop(0);
    if (!this.appservices.getSession("token", false)) {
      this.router.navigate(['get-started/' + this.appservices.getSession("token", false)]);
    }
    this.getLocationAddress();
    this.callAppDetailsAPI();
    this.location = this.appservices.getSession("location_details", true);
    if (localStorage.getItem('Input_form_data') != '')
      this.user_details = JSON.parse(localStorage.getItem('Input_form_data'));
    let emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$";
    this.InputForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.minLength(1)]],
      lastName: ['', [Validators.required, Validators.minLength(1)]],
      email: ['', [Validators.required, Validators.pattern(emailPattern)]],
      // phoneNumber: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]],
      phoneNumber: ['', []],
      //termsAndConditions: ['', Validators.requiredTrue]
    });
    if (this.user_details) {
      this.InputForm.controls["firstName"].setValue(this.user_details.firstName);
      this.InputForm.controls["lastName"].setValue(this.user_details.lastName);
      this.InputForm.controls["email"].setValue(this.user_details.email);
      this.InputForm.controls["phoneNumber"].setValue(this.user_details.phoneNumber);
      this.isButtonDisabled = false;
    }
    if (!this.appservices.hasSession("contactus_country_name")) {
      this.appservices.setSession("contactus_country_name", 'au', false);
    }
  }

  get f() { return this.uploadImageInputForm.controls; }
  displayInputBox(id) {
    if (id == 5) {
      $(".input_box").removeClass('d-none').addClass('d-flex');
      $(".form_control").val($("#lblName5").html());
      $(".submit_btn").addClass('pt-4');
      $("#lblName5").hide();
    }
  }

  CopyToLabel(id) {
    var txtName = $("#txtName");
    var lblName = $("#lblName5");
    if (!this.allLetter(txtName.val())) {
      window.scrollTo(0, 0);
      $(window).scrollTop(0);
      this.appservices.serviceMessages('vs-messages', 'Label must contains alphabets and spaces only', 'danger');
    }
    else if (txtName.val().trim().length < 5) {
      window.scrollTo(0, 0);
      $(window).scrollTop(0);
      this.appservices.serviceMessages('vs-messages', 'Label must be minimum 5 characters', 'danger');
    }
    else {
      this.appservices.serviceMessages('vs-messages', '', '');
      lblName.html(txtName.val()).show();
      $(".input_box").removeClass('d-flex').addClass('d-none');
      this.pushImageDataInToArray(5, lblName.html(), '');
    }
  }

  onSubmit() {
    let message = 'Please upload at least three images (Front view, Left view, Right view, Upper jaw or Lower Jaw) to continue';
    this.count = $('.base64Image').length;
    this.isfrontImage = $('#uploadImage0').length;
    this.displayPreviousBtn = 'visibility_block';
    this.after_image_upload_div = 'after_image_upload_div';
    this._cd.detectChanges();

    if (this.count < 3) {
      setTimeout(() => {
        this.disableArrow();
      }, 500);
      if (this.count == 1 && this.ohr_flow == '2' && $('#agree').is(':checked')) {
        $('.visibility_block a').addClass('visibility_none');
        setTimeout(() => {
          $('.visibility_block').removeClass('visibility_block').addClass('visibility_none');
        }, 500);
      }

      if (this.getOHRDataLength() > 0 && this.getOHRDataLength() <= 2) {
        $(".next-slide").trigger("click");
        $('.prev-btn').removeAttr("disabled");
        $('.prev-btn').removeClass("disabled");
        $('.next-btn').attr("disabled", true);
        $('.upload-teeth-continue-btn').attr("disabled", true);
      }
    }
    if (this.count >= 3) {

      if (this.isfrontImage == 0) {
        window.scrollTo(0, 0);
        $(window).scrollTop(0);
        this.appservices.serviceMessages('vs-messages', 'Teeth Front View Image is required', 'danger');
      }
      else {
        if (this.forthSlideVisited == false || this.fifthSlideVisited == false || this.sixthSlideVisited == false) {
          $(".next-slide").trigger("click");
          $('.upload-teeth-continue-btn').attr("disabled", true);
          return false;
        }
        else {
          let currentIndex = ($('.slick-active').attr('data-slick-index'));
          if (currentIndex != 5) {
            $(".next-slide").trigger("click");
            $('.upload-teeth-continue-btn').attr("disabled", true);
            return false;
          }
        }
        if (this.get_contact_information_later == 'yes') {
          this.showContactForm = true;
          this._cd.detectChanges();
          this.sendSubjectMessage = { backgroundProcessing: this.backgroundProcessing };
          this.messageService.sendMessage(this.sendSubjectMessage);
        }
        else if (this.ohr_flow == 2) {
          this.showLoadingForm = true;
        }
        this.isUploadButtonDisabled = false;
        $('.sec_inner_content1').hide();
        if (this.ohr_flow != 2)
          $('.analysing_sec').show();
        this.appservices.setSession("extra_images", this.extra_images, true);
        this.appservices.setSession("ohr_data", this.ohrdata, true);
        this.processOHRImages();
      }
    }
  }

  onAgree(event) {
    if (event.target.checked)
      this.termsAndConditions = "-1";
  }

  allLetter(inputtxt) {
    var letters = /^[a-zA-Z ]*$/;
    if (inputtxt.match(letters)) {
      return true;
    }
    else {
      return false;
    }
  }

  disableArrow() {
    let currentIndexPosition = ($('.slick-active').attr('data-slick-index'));
    $('.prev-btn').removeAttr("disabled");
    $('.prev-btn').removeClass("disabled");
    if (currentIndexPosition == '0') {
      $('.prev-btn').attr("disabled", true);
      $('.prev-btn').addClass("disabled");
      if ($('#uploadImage0').length > 0) {
        $('.next-btn').removeAttr("disabled");
      }
      this.displayPreviousBtn = 'visibility_none';
      this._cd.detectChanges();
    }
    if (currentIndexPosition == '1') {
      $('.upload-teeth-continue-btn').attr("disabled", false);
      $('.next-btn').removeAttr("disabled");
      if ($('#uploadImage1').length == 0) {
        $('.next-btn').attr("disabled", true);
        $('.icon-right-arrow').addClass('slick-disabled');
        $('.upload-teeth-continue-btn').attr("disabled", true);
      }
    }
    if (currentIndexPosition == '2') {
      if ($('#uploadImage2').length == 0) {
        $('.icon-right-arrow').addClass('slick-disabled');
      }
    }
    if (currentIndexPosition == '5') {
      $('.next-btn').attr("disabled", true);
    }
  }

  processOHRImages() {
    this.appservices.sendPageViewAnalyticsData(gtag, '', this.router.url, 'OHR – Processing input images');
    let extra_image = this.appservices.getSession("extra_images", true);
    if (extra_image[0].image)
      this.apirequest["extra_images"] = this.extra_images;
    this.apirequest["images"] = this.appservices.getSession("ohr_data", true);
    this.apirequest["oral_unique_id"] = this.appservices.getSession("ohr_unique_key", false);
    this.apirequest["get_contact_information_later"] = this.get_contact_information_later;
    this.apirequest["guest_user_id"] = this.guest_user_id;

    if (this.ohr_flow == '2')
      this.apirequest["image_upload_function"] = 'true';

    this.apirequest["process_ml_function"] = 'true';

    this.input = this.appservices.generteJSONFromArray(this.apirequest);
    this.appservices.callJSONAPI(this.appservices.PROCESS_ORAL_HEALTH_IMAGES, this.input, 'post', this, '').subscribe(
      (data: any) => {
        if (data.status) {
          this.appservices.callJSONAPI(this.appservices.FETCHINGINDIVIDUALSCORES + "?oral_unique_id=" + this.appservices.getSession("ohr_unique_key", false) + "&timezone=Asia/Calcutta&get_contact_information_later=" + this.get_contact_information_later + "&guest_user_id=" + this.guest_user_id, "", 'get', this, '127.0.0.1')
            .subscribe(
              (data: any) => {
                if (data.status) {
                  this.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_ohr_pic_selected', true);
                  this.appservices.setSession("created_date", data.result.created_date, false);
                  this.appservices.setSession("is_all_images_invalid", data.result.is_all_images_invalid, false);
                  this.appservices.setSession("process_images", data.result.scores, true);
                  if (this.appservices.hasSession("process_images")) {
                    this.appservices.callJSONAPI(this.appservices.GETPRACTICEPAYMENTDETAILS + "?widget_token=" + this.appservices.getSession("token", false) + "&timezone=Asia/Calcutta&selected_branch_id=" + this.appservices.getSession("user_selected_practice_branch", false) + "&get_contact_information_later=" + this.get_contact_information_later + "&guest_user_id=" + this.guest_user_id, "", 'get', this, '127.0.0.1').subscribe(
                      (data: any) => {
                        this.sendSubjectMessage = {
                          backgroundProcessing: false
                        };
                        this.messageService.sendMessage(this.sendSubjectMessage);

                        if (data.status) {
                          this.appservices.setSession("widget_flow_type", '', false);
                          if (data.result.ohr_flow != '0') {
                            this.appservices.setSession("widget_flow_type", "payment", false);
                            this.appservices.setSession("payment_title", "Your Oral Health Rating", false);
                            if (!this.appservices.isEmpty(data.result.title)) {
                              this.appservices.setSession("payment_title", data.result.title, false);
                            }

                            this.appservices.setSession("payment_sub_title", "The smilo.ai rating is for awareness only. Any information or result provided by Smilo.ai is indicative only and not a diagnosis.", false);
                            if (!this.appservices.isEmpty(data.result.sub_title)) {
                              this.appservices.setSession("payment_sub_title", data.result.sub_title, false);
                            }

                            this.appservices.setSession("benefits_title", "Get Benifited with More Insights", false);
                            if (!this.appservices.isEmpty(data.result.benefits_title)) {
                              this.appservices.setSession("benefits_title", data.result.benefits_title, false);
                            }

                            this.appservices.setSession("benefits_sub_title", "Visuals of AI Annotated Personalized Results", false);
                            if (!this.appservices.isEmpty(data.result.benefits_sub_title)) {
                              this.appservices.setSession("benefits_sub_title", data.result.benefits_sub_title, false);
                            }

                            this.appservices.setSession("benefits_one", "Personalized Insights", false);
                            if (!this.appservices.isEmpty(data.result.benefits_one)) {
                              this.appservices.setSession("benefits_one", data.result.benefits_one, false);
                            }

                            if (!this.appservices.isEmpty(data.result.benefits_two)) {
                              this.appservices.setSession("benefits_two", data.result.benefits_two, false);
                            }

                            if (!this.appservices.isEmpty(data.result.benefits_three)) {
                              this.appservices.setSession("benefits_three", data.result.benefits_three, false);
                            }

                            if (!this.appservices.isEmpty(data.result.amount)) {
                              this.appservices.setSession("payment_amount", data.result.amount, false);
                            }

                            if (!this.appservices.isEmpty(data.result.title_for_button)) {
                              this.appservices.setSession("title_for_button", data.result.title_for_button, false);
                            }

                            this.appservices.setSession("payment_button_text", "Make Payment", false);
                            if (data.result.payment_enabled == '0') {
                              this.appservices.setSession("payment_button_text", "Get my personalised report", false);
                            }
                            if (!this.appservices.isEmpty(data.result.button_text)) {
                              this.appservices.setSession("payment_button_text", data.result.button_text, false);
                            }

                            if (!this.appservices.isEmpty(data.result.gateway_name)) {
                              this.appservices.setSession("payment_type", data.result.gateway_name, false);
                              if (data.result.gateway_name === 'stripe') {
                                if (!this.appservices.isEmpty(data.result.api_key)) {
                                  this.appservices.setSession("api_key", data.result.api_key, false);
                                }
                                if (!this.appservices.isEmpty(data.result.secret_key)) {
                                  this.appservices.setSession("secret_key", data.result.secret_key, false);
                                }
                              }
                            }
                          }
                          this.widget_flow_type = this.appservices.getSession("widget_flow_type", false);
                          if (this.widget_flow_type === "payment") {
                            let user_id = this.appservices.getSession("user_id", false);
                            this.appservices.callJSONAPI(this.appservices.CALCULATINGSCORES + "?device_details=" + this.getDeviceInfo() + "&oral_unique_id=" + this.appservices.getSession("ohr_unique_key", false) + "&widget_token=" + this.appservices.getSession("token", false) + "&timezone=Asia/Calcutta&selected_branch_id=" + this.appservices.getSession("user_selected_practice_branch", false) + "&payment_transaction=true&get_contact_information_later=" + this.get_contact_information_later + "&guest_user_id=" + this.guest_user_id + "&user_id=" + user_id, "", 'get', this, '127.0.0.1')
                              .subscribe(
                                (data: any) => {
                                  if (data.status) {
                                    this.appservices.setSession("health_score_id", data.result.scores.score_id, false);
                                    this.appservices.setSession("overall_score", data.result.scores.overall_score, false);
                                    this.appservices.setSession("redirecting_url", 'payment-access-report', false);

                                    if (this.get_contact_information_later == "yes") {
                                      this.changeDetails();
                                      this._cd.detectChanges();
                                    } else {
                                      this.router.navigate(['payment-access-report']);
                                    }
                                  } else {
                                    this.router.navigate(['no-internet']);
                                  }
                                },
                                (error) => {
                                  this.router.navigate(['no-internet']);
                                }
                              );
                          } else {
                            if (this.get_contact_information_later == "yes" && this.widget_flow_type === "payment") {
                              this.changeDetails();
                              this._cd.detectChanges();
                              this.appservices.setSession("redirecting_url", 'process-ohr-images', false);
                            } else if (this.get_contact_information_later == "yes" && this.widget_flow_type === "") {
                              this.backgroundProcessing = false;
                              this.sendSubjectMessage = {
                                backgroundProcessing: this.backgroundProcessing
                              };
                              this.messageService.sendMessage(this.sendSubjectMessage);

                              this.appservices.setSession("redirecting_url", 'process-ohr-images', false);
                              if (this.contactFormSubmitted === true) {
                                setTimeout(() => {
                                  this.onContactFormSubmit();
                                }, 1000);
                              }
                            } else {
                              this.router.navigate(['process-ohr-images']);
                            }
                          }
                        } else {
                          this.router.navigate(['no-internet']);
                        }
                      },
                      (error) => { this.router.navigate(['no-internet']); }
                    );
                  }
                } else {
                  this.router.navigate(['no-internet']);
                }
              }, (error) => { this.router.navigate(['no-internet']); });
        } else { this.router.navigate(['no-internet']); }
      },
      (error) => {
        this.router.navigate(['no-internet']);
      }
    );
  }

  onInstructions() {
    let htmlElement: HTMLIFrameElement;
    let src;
    let elements = document.getElementsByClassName("yt_players");
    for (let i = 0; i <= elements.length; i++) {
      htmlElement = <HTMLIFrameElement>elements[0];
      src = htmlElement.getAttribute("src");
      htmlElement.setAttribute("src", src);
    }
  }

  // openCropperDialog(event: Event, rowid, title) {
  //   $("body").addClass("modal-open");
  //   this.appservices.serviceMessages('vs-messages', '', '');
  //   let user_id = this.appservices.getSession("user_id", false);
  //   this._dialog.open<AusCropperDialogComponent, Event>(AusCropperDialogComponent, {
  //     data: event,
  //     width: 320,
  //     disableClose: true
  //   }).afterClosed.subscribe((result?: ImgCropperEvent) => {

  //     $("body").removeClass("modal-open");
  //     if (result) {
  //       $('#uploadImage' + (rowid + 1)).removeClass('display_block').addClass('display_none');

  //       this.count_value = this.count_value + 1;
  //       $('.please_wait_msg' + rowid).html('Please wait...');
  //       $('.continue_btn').html('Please wait...').attr('disabled', true);
  //       $('#overlay1' + rowid).addClass('overlay');
  //       $('.avatar_preview' + rowid).removeClass('edit_icon_hover');
  //       $('.upload_preview_span' + rowid).addClass("spinner-border");
  //       $('.upload_inner_section' + rowid).addClass("image_brightness");

  //       this.flag = true;
  //       this.responseData[rowid].base64 = result.dataURL;
  //       this.cropped_url_blob = this.appservices.convertDataUrlToBlob(result.dataURL);
  //       let fileExt = result.dataURL.split(';')[0].split('/')[1];
  //       this.folder = 'oralhealthscore_images/';
  //       $('.img_' + rowid).remove();
  //       if (this.get_contact_information_later == 'yes') {
  //         this.responseData[rowid].file_name = this.guest_session_uuid + '/' + this.todaysDataTime + '/image_' + (rowid == 5 ? 7 : rowid + 1) + "." + fileExt;
  //       }
  //       else {
  //         this.responseData[rowid].file_name = user_id + '/' + this.todaysDataTime + '/image_' + (rowid == 5 ? 7 : rowid + 1) + "." + fileExt;
  //       }

  //       let thisobject = this;
  //       let greetingPromise = this.appservices.uploadToS3(this.cropped_url_blob, this.responseData[rowid].file_name, this.folder, fileExt);
  //       greetingPromise.then(function (resolveOutput) {

  //         $('#uploadImage' + (rowid + 1)).removeClass('display_none').addClass('display_block');
  //         $('.errorImageDiv').removeClass('display_block').addClass('display_none');
  //         thisobject.pushImageDataInToArray(rowid, $("#lblName5").html(), resolveOutput["Location"]);
  //         thisobject.count_value = thisobject.count_value - 1;
  //         $('.avatar_preview' + rowid).addClass('edit_icon_hover');
  //         $('#pointer_link' + rowid).addClass('pointer_link');
  //         $('#checkMeOut').prop('checked'); // true

  //         $('#upload_image_div' + rowid).addClass('display_none');
  //         $('#uploadImage' + rowid).addClass('right_zero');

  //         if (thisobject.ohr_flow != '2') {
  //           if (rowid != '5')
  //             $('#after_image_upload_text_div' + rowid).html(thisobject.imagesTitle[rowid].title + ' picture uploaded successfully.<br> Please click on Save & Continue.');
  //           else
  //             $('#after_image_upload_text_div' + rowid).html(thisobject.imagesTitle[rowid].title + ' uploaded successfully.<br> Please click on Get your Results.');
  //         }

  //         thisobject.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_ohr_image' + (rowid + 1), true);

  //         if (rowid == '0') {
  //           $('.icon-right-arrow').removeClass('slick-disabled');
  //           $('.next-btn').removeAttr('disabled');
  //         }
  //         else
  //           $('.slick-arrow').removeClass('slick-disabled');
  //         $('.next-btn').removeAttr('disabled');
  //         if (thisobject.getOHRDataLength() >= 3 && $('#uploadImage0').length > 0) {
  //           $('#num2').removeClass('disabled');
  //         }
  //         if (rowid == 5) {
  //           $('.next-btn').attr("disabled", true);
  //           $('.save_continue_btn_span').html('Get your Results');
  //         }

  //         if (thisobject.ohr_flow == '2') {
  //           thisobject.appservices.setSession("ohr_data", thisobject.ohrdata, true);
  //           let extra_image = thisobject.appservices.getSession("extra_images", true);
  //           if (!thisobject.appservices.isEmpty(extra_image)) {
  //             thisobject.apirequest["extra_images"] = thisobject.extra_images;
  //           }
  //           thisobject.apirequest["images"] = thisobject.appservices.getSession("ohr_data", true);
  //           thisobject.apirequest["ohrStoredImages"] = thisobject.ohrStoredImages;

  //           thisobject.apirequest["oral_unique_id"] = thisobject.appservices.getSession("ohr_unique_key", false);

  //           thisobject.apirequest["get_contact_information_later"] = thisobject.get_contact_information_later;
  //           thisobject.apirequest["guest_user_id"] = thisobject.guest_user_id;

  //           thisobject.apirequest["image_upload_function"] = 'true';

  //           thisobject.apirequest["process_ml_function"] = 'false';

  //           thisobject.input = thisobject.appservices.generteJSONFromArray(thisobject.apirequest);
  //           thisobject.appservices.callJSONAPI(thisobject.appservices.PROCESS_ORAL_HEALTH_IMAGES, thisobject.input, 'post', thisobject, '').subscribe(
  //             (data: any) => {
  //               if (data.status) {

  //                 if (rowid != '5')
  //                   $('#after_image_upload_text_div' + rowid).html(thisobject.imagesTitle[rowid].title + ' picture uploaded successfully.<br> Please click on Save & Continue.');
  //                 else
  //                   $('#after_image_upload_text_div' + rowid).html(thisobject.imagesTitle[rowid].title + ' uploaded successfully.<br> Please click on Get your Results.');

  //                 $('#overlay1' + rowid).removeClass('overlay');
  //                 $('.upload_preview_span' + rowid).removeClass("spinner-border");
  //                 $('.upload_inner_section' + rowid).removeClass("image_brightness");
  //                 $('.please_wait_msg' + rowid).html('');
  //                 $('.upload-teeth-continue-btn').removeAttr('disabled');
  //               }
  //               else {
  //                 thisobject.router.navigate(['no-internet']);
  //               }
  //             });
  //         }
  //         else {
  //           $('#overlay1' + rowid).removeClass('overlay');
  //           $('.upload_preview_span' + rowid).removeClass("spinner-border");
  //           $('.upload_inner_section' + rowid).removeClass("image_brightness");
  //           $('.please_wait_msg' + rowid).html('');
  //           $('.upload-teeth-continue-btn').removeAttr('disabled');
  //         }
  //         if (thisobject.count_value == 0)
  //           $('.continue_btn').html('Continue').attr('disabled', false);
  //       }, function (rejectOutput) {
  //       });
  //       this.uploadImageInputForm.patchValue({
  //         file: result.dataURL
  //       });
  //       this._cd.markForCheck();
  //     }
  //   });
  // }


  currentSelectedRow: any;
  displaySkipBtn: string = "visibility_none";

  readFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject();
      };
    });
  }

  getImageDimensions(file) {
    return new Promise((resolve, reject) => {
      const URL = window.URL || window.webkitURL;
      const Img = new Image();
      Img.src = URL.createObjectURL(file);
      Img.onload = (e: any) => {
        resolve(Img);
      };
      Img.onerror = function (error) {
        reject();
      };
    });
  }

  async openCropperDialog(event: Event, rowid, title) {
    this.currentSelectedRow = rowid;
    this.appservices.setSession(
      "currentSelectedRow",
      this.currentSelectedRow,
      false
    );
    $("body").addClass("modal-open");
    this.appservices.serviceMessages("vs-messages", "", "");
    let user_id = this.appservices.getSession("user_id", false);

    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      var file = target.files[0];

      var fileExt = file.name.split(".").pop();
      $(".please_wait_msg" + rowid).html("Please wait...");
      $(".continue_btn").html("Please wait...").attr("disabled", true);
      $(".avatar_preview" + rowid).removeClass("edit_icon_hover");
      $(".upload_preview_span" + rowid).addClass("spinner-border");
      $(".upload_inner_section" + rowid).addClass("image_brightness");

      if (!$("#upload_image_div" + rowid).is(":visible")) {
        if (screen.width > 576) {
          $("#overlay1" + rowid).css("width", "100%");
        } else {
          $("#overlay1" + rowid).css("width", "100%");
        }
        $("#overlay1" + rowid).addClass("overlay");
      } else {
        $("#overlay1" + rowid).addClass("overlay");
      }

      var uploadedImageHeight = 0;
      var uploadedImageWidth = 0;
      // compressing the file if the image is greater than 1mb
      if (Number(file.size / 1048576) >= 1) {
        await this.readFile(file).then(async (data) => {
          await this.imageCompress
            .compressFile(data.toString(), 0, 50, 75)
            .then(async (result) => {
              var blobFile = this.appservices.convertDataUrlToBlob(result);
              file = new File([blobFile], file.name, { type: file.type });
              await this.getImageDimensions(file).then(
                async (imageDimensionsData) => {
                  uploadedImageHeight = <any>imageDimensionsData["height"];
                  uploadedImageWidth = <any>imageDimensionsData["width"];
                }
              );
            });
        });
      } else {
        await this.getImageDimensions(file).then(
          async (imageDimensionsData) => {
            uploadedImageHeight = <any>imageDimensionsData["height"];
            uploadedImageWidth = <any>imageDimensionsData["width"];
          }
        );
      }
      //max file size is 4 mb
      // if((file.size/1048576)<=4)
      // {
      // uploadToS3(file, file_name, folder_name, type)
      this.folder = "oralhealthscore_images/";
      let thisUploadObject = this;
      let greetingPromise = this.appservices.uploadToS3(
        file,
        target.files[0].name,
        this.folder,
        fileExt
      );
      greetingPromise.then(function (resolveOutput) {
        thisUploadObject.apirequest = [];
        thisUploadObject.apirequest["fileLocation"] = resolveOutput["Location"];
        if (thisUploadObject.ohr_flow == "2") {
          thisUploadObject.apirequest["get_contact_information_later"] = "yes";
        }
        thisUploadObject.input =
          thisUploadObject.appservices.generteJSONFromArray(
            thisUploadObject.apirequest
          );
        thisUploadObject.appservices
          .callJSONAPI(
            thisUploadObject.appservices.GOOGLE_VISION_API,
            thisUploadObject.input,
            "post",
            thisUploadObject,
            ""
          )
          .subscribe((data: any) => {
            //this.vision_api_result= data.result;
            $("#overlay1" + rowid).removeClass("overlay");
            $(".upload_preview_span" + rowid).removeClass("spinner-border");
            $(".upload_inner_section" + rowid).removeClass("image_brightness");
            $(".please_wait_msg" + rowid).html("");
            thisUploadObject.appservices.setSession(
              "currentOhrImage",
              resolveOutput["Location"],
              true
            );
            thisUploadObject.appservices.setSession(
              "currentOhrImageCoordinates",
              data,
              true
            );

            thisUploadObject.appservices.setSession(
              "uploadedImageHeight",
              uploadedImageHeight,
              false
            );
            thisUploadObject.appservices.setSession(
              "uploadedImageWidth",
              uploadedImageWidth,
              false
            );
            thisUploadObject._dialog
              .open<AusCropperDialogComponent, Event>(AusCropperDialogComponent, {
                data: event,
                width: 320,
                disableClose: true,
              })
              .afterClosed.subscribe((result?: ImgCropperEvent) => {
                $("body").removeClass("modal-open");
                if (result) {
                  $('#uploadImage' + (rowid + 1)).removeClass('display_block').addClass('display_none');

                  $("#upload_image_div" + rowid)
                    .removeClass("display_block")
                    .addClass("display_none");
                  if (rowid == 3 || rowid == 4) {
                    thisUploadObject.displaySkipBtn = "visibility_none";
                  }
                  if (screen.width > 576) {
                    $("#overlay1" + rowid).css("width", "100%");
                  }
                  $("#uploadImage" + (rowid + 1))
                    .removeClass("display_block")
                    .addClass("display_none");

                  thisUploadObject.count_value =
                    thisUploadObject.count_value + 1;
                  $(".please_wait_msg" + rowid).html("Please wait...");
                  $(".continue_btn")
                    .html("Please wait...")
                    .attr("disabled", true);
                  $("#overlay1" + rowid).addClass("overlay");
                  $(".avatar_preview" + rowid).removeClass("edit_icon_hover");
                  $(".upload_preview_span" + rowid).addClass("spinner-border");
                  $(".upload_inner_section" + rowid).addClass(
                    "image_brightness"
                  );
                  $('.img_' + rowid).remove();
                  thisUploadObject.flag = true;

                  thisUploadObject.responseData[rowid].base64 = result.dataURL;
                  thisUploadObject.cropped_url_blob =
                    thisUploadObject.appservices.convertDataUrlToBlob(
                      result.dataURL
                    );
                  let fileExt = result.dataURL.split(";")[0].split("/")[1];
                  thisUploadObject.folder = "oralhealthscore_images/";
                  if (thisUploadObject.get_contact_information_later == "yes") {
                    thisUploadObject.responseData[rowid].file_name =
                      thisUploadObject.guest_session_uuid +
                      "/" +
                      thisUploadObject.todaysDataTime +
                      "/image_" +
                      (rowid == 5 ? 7 : rowid + 1) +
                      "." +
                      fileExt;
                  } else {
                    thisUploadObject.responseData[rowid].file_name =
                      user_id +
                      "/" +
                      thisUploadObject.todaysDataTime +
                      "/image_" +
                      (rowid == 5 ? 7 : rowid + 1) +
                      "." +
                      fileExt;
                  }

                  let thisobject = thisUploadObject;
                  let greetingPromise = thisobject.appservices.uploadToS3(
                    thisobject.cropped_url_blob,
                    thisobject.responseData[rowid].file_name,
                    thisobject.folder,
                    fileExt
                  );
                  greetingPromise.then(
                    function (resolveOutput) {
                      $("#uploadImage" + (rowid + 1))
                        .removeClass("display_none")
                        .addClass("display_block");
                      $(".errorImageDiv")
                        .removeClass("display_block")
                        .addClass("display_none");

                      thisobject.pushImageDataInToArray(
                        rowid,
                        $("#lblName5").html(),
                        resolveOutput["Location"]
                      );
                      thisobject.count_value = thisobject.count_value - 1;
                      $(".avatar_preview" + rowid).addClass("edit_icon_hover");
                      $("#pointer_link" + rowid).addClass("pointer_link");
                      $("#checkMeOut").prop("checked"); // true
                      $("#uploadImage" + rowid).addClass("right_zero");
                      $('#upload_image_div' + rowid).addClass('display_none');//aus
                      if (thisobject.ohr_flow != "2") {
                        if (rowid != "5")
                          $("#after_image_upload_text_div" + rowid).html(
                            thisobject.imagesTitle[rowid].title +
                            " picture uploaded successfully.<br> Please click on Save & Continue."
                          );
                        else
                          $("#after_image_upload_text_div" + rowid).html(
                            thisobject.imagesTitle[rowid].title +
                            " uploaded successfully.<br> Please click on Get your Results."
                          );
                      }

                      thisobject.appservices.sendAnalyticsData(
                        '',
                        '',
                        "smilo_event",
                        "sa_wg_ohr_image" + (rowid + 1),
                        true
                      );

                      if (rowid == "0") {
                        $(".icon-right-arrow").removeClass("slick-disabled");
                        $(".next-btn").removeAttr("disabled");
                      } else $(".slick-arrow").removeClass("slick-disabled");
                      $(".next-btn").removeAttr("disabled");
                      if (
                        thisobject.getOHRDataLength() >= 3 &&
                        $("#uploadImage0").length > 0
                      ) {
                        $("#num2").removeClass("disabled");
                        // $('.upload-teeth-continue-btn').removeAttr('disabled');
                      }
                      if (rowid == 5) {
                        $(".next-btn").attr("disabled", true);
                        $(".save_continue_btn_span").html("Get your Results");
                      }

                      if (thisobject.ohr_flow == "2") {
                        thisobject.appservices.setSession(
                          "ohr_data",
                          thisobject.ohrdata,
                          true
                        );
                        let extra_image = thisobject.appservices.getSession(
                          "extra_images",
                          true
                        );
                        if (!thisobject.appservices.isEmpty(extra_image)) {
                          thisobject.apirequest["extra_images"] =
                            thisobject.extra_images;
                        }
                        thisobject.apirequest["images"] =
                          thisobject.appservices.getSession("ohr_data", true);
                        thisobject.apirequest["ohrStoredImages"] =
                          thisobject.ohrStoredImages;

                        thisobject.apirequest["oral_unique_id"] =
                          thisobject.appservices.getSession(
                            "ohr_unique_key",
                            false
                          );

                        thisobject.apirequest["get_contact_information_later"] =
                          thisobject.get_contact_information_later;
                        // thisobject.apirequest["guest_session_user_id"]         = thisobject.guest_session_uuid;
                        thisobject.apirequest["guest_user_id"] =
                          thisobject.guest_user_id;

                        thisobject.apirequest["image_upload_function"] = "true";
                        thisobject.apirequest["process_ml_function"] = "false";

                        thisobject.input =
                          thisobject.appservices.generteJSONFromArray(
                            thisobject.apirequest
                          );
                        // thisobject.input = thisobject.appservices.generteJSONFromArray(thisobject.apirequest);
                        thisobject.appservices
                          .callJSONAPI(
                            thisobject.appservices.PROCESS_ORAL_HEALTH_IMAGES,
                            thisobject.input,
                            "post",
                            thisobject,
                            ""
                          )
                          .subscribe((data: any) => {
                            if (data.status) {
                              if (rowid != "5")
                                $("#after_image_upload_text_div" + rowid).html(
                                  thisobject.imagesTitle[rowid].title +
                                  " picture uploaded successfully.<br> Please click on Save & Continue."
                                );
                              else
                                $("#after_image_upload_text_div" + rowid).html(
                                  thisobject.imagesTitle[rowid].title +
                                  " uploaded successfully.<br> Please click on Get your Results."
                                );

                              $("#overlay1" + rowid).removeClass("overlay");
                              $(".upload_preview_span" + rowid).removeClass(
                                "spinner-border"
                              );
                              $(".upload_inner_section" + rowid).removeClass(
                                "image_brightness"
                              );
                              $(".please_wait_msg" + rowid).html("");
                              $(".upload-teeth-continue-btn").removeAttr(
                                "disabled"
                              );

                              $("#overlay1" + rowid).css("width", "");
                            } else {
                              // this.appservices.setSession(  //aus
                              //   "has_technical_error",
                              //   "true",
                              //   false
                              // );
                              thisobject.router.navigate(["no-internet"]);
                            }
                          });
                      } else {
                        $("#overlay1" + rowid).removeClass("overlay");
                        $(".upload_preview_span" + rowid).removeClass(
                          "spinner-border"
                        );
                        $(".upload_inner_section" + rowid).removeClass(
                          "image_brightness"
                        );
                        $(".please_wait_msg" + rowid).html("");
                        $(".upload-teeth-continue-btn").removeAttr("disabled");

                        $("#overlay1" + rowid).css("width", "");
                      }
                      if (thisobject.count_value == 0)
                        $(".continue_btn")
                          .html("Continue")
                          .attr("disabled", false);
                    },
                    function (rejectOutput) { }
                  );
                  thisobject.uploadImageInputForm.patchValue({
                    file: result.dataURL,
                  });
                  thisobject._cd.markForCheck();
                }
              });
          });
      });
    }
  }

  getOHRDataLength() {
    return this.ohrdata.length;
  }

  get myForm() {
    return this.uploadImageInputForm.get('file');
  }

  pushImageDataInToArray(rowid, title, s3Location) {
    if (rowid != 5) {
      if (this.ohrdata.length == 0) {
        this.ohrdata.push({ "image": this.responseData[rowid].file_name, "title": this.responseData[rowid].title });
        this.ohrStoredImages.push({ "image": this.responseData[rowid].file_name, "base64": s3Location, "title": this.responseData[rowid].title, id: rowid });
      }
      else {
        let found = false;
        this.ohrdata.forEach(element => {
          if (element.image == this.responseData[rowid].file_name) {
            found = true;
          }
        });
        if (found == false) {
          this.ohrdata.push({ "image": this.responseData[rowid].file_name, "title": this.responseData[rowid].title });
          this.ohrStoredImages.push({ "image": this.responseData[rowid].file_name, "base64": s3Location, "title": this.responseData[rowid].title, id: rowid });
        }
      }
    }
    else {
      this.extra_images = [];
      this.extra_images.push({ "image": this.responseData[rowid].file_name, "title": title });
      this.ohrStoredImages.push({ "image": this.responseData[rowid].file_name, "base64": s3Location, "title": this.responseData[rowid].title, id: rowid });
      this.appservices.setSession("extra_images", this.extra_images, true);
    }
  }

  closeModal() {
    this.modalRef.hide();
  }

  openModal(template: TemplateRef<any>) {
    // e.stopPropagation();
    loadScript();
    this.modalRef = this.modalService.show(template, {
      class: 'custom_notifications_modal vertical_align_center',
      animated: true,
      backdrop: 'static'
    });
  }

  openViewModal(template: TemplateRef<any>, id) {
    this.displayImage = this.imagesInfo[id].url;
    this.displayTitle = id != 5 ? this.imagesInfo[id].title : $("#lblName5").html();
    this.modalRef = this.modalService.show(template, {
      class: 'custom_notifications_modal vertical_align_center',
      animated: true,
      backdrop: 'static'
    });
  }

  // Inherited from Contact Form 
  async onEnterData(event) {
    // this.signInHasError(event);
    let nameType = event.target.getAttribute('formControlName');
    let nameVal = event.target.value;
    if (nameType === 'firstName') {
      this.firstName = nameVal;
    }
    else if (nameType === 'lastName') {
      this.lastName = nameVal;
    }
    else if (nameType === 'phoneNumber') {
      this.isSubmitted = false;
      // await this.signInHasError(event);
    }
    if (this.InputForm.valid) {
      this.isButtonDisabled = false;
    }
    else
      this.isButtonDisabled = true;
  }

  onCheckboxChange(e, type) {
    this.appservices.serviceMessages('vs-messages', '', '');
  }

  onContactFormSubmit() {
    this.isSubmitted = true;
    // if(!this.signInValidNumber) { return false; }
    if (this.InputForm.invalid) {
      return;
    }
    if (this.InputForm.valid) {
      localStorage.setItem('Input_form_data', JSON.stringify(this.InputForm.value));
      this.user_details = JSON.parse(localStorage.getItem('Input_form_data'));
      this.appservices.setUserEmail(this.InputForm.value.email.trim());
      this.contactFormSubmitted = true;
      if (this.backgroundProcessing === false) {
        this.callContactAPI();
      }
      else if (this.backgroundProcessing === true) {
        this.showContactForm = false;
        this.showLoadingForm = true;
      }
    }
  }
  callContactAPI() {
    if (true) {
      $('.coninue_btn').html('Please wait...').attr('disabled', true);
      this.user_details = JSON.parse(localStorage.getItem('Input_form_data'));
      this.apirequest = [];
      this.apirequest["first_name"] = this.user_details.firstName;
      this.apirequest["last_name"] = this.user_details.lastName;
      this.apirequest["email"] = this.user_details.email;
      this.apirequest["phone"] = this.user_details.phoneNumber;
      this.apirequest["widget_token"] = this.appservices.getSession("token", false);
      let dail_code = this.appservices.getSession("dial_code", false) ? this.appservices.getSession("dial_code", false).toString() : "1";
      this.apirequest["dial_code"] = dail_code;

      this.apirequest["country_name"] = this.country_name;

      this.apirequest["branch_id"] = this.appservices.getSession("user_selected_practice_branch", false);

      this.input = "\"user\":" + this.appservices.generteJSONFromArray(this.apirequest);
      this.appservices.callJSONAPI(this.appservices.WIDGET_LOGIN, this.input, 'post', this, '').subscribe(
        (data: any) => {
          if (data.status) {
            this.appservices.setSession("existing_user", 'no', false);
            this.header_display_msg = 'Smilo.ai will create an account with auto generated password for you and send an email with login details. please use login details to view/download Oral Health Rating and Design my smile report.';
            if (data.result.existing_user === 'true') {
              this.appservices.setSession("existing_user", 'yes', false);
            }

            let full_name = data.result.user.first_name + data.result.user.last_name;
            this.appservices.setSession("is_logged_in", "true", false);
            this.appservices.setSession("user_access_token", data.result.user.user_access_token, false);
            this.appservices.setSession("first_name", data.result.user.first_name, false);
            this.appservices.setSession("last_name", data.result.user.last_name, false);
            this.appservices.setSession("full_name", full_name, false);
            this.appservices.setSession("email", data.result.user.email, false);
            this.appservices.setSession("user_id", data.result.user.user_id, false);

            if (data.result.user.phone)
              this.appservices.setSession("phoneNumber", data.result.user.phone, false);

            if (data.result.user.dial_code)
              this.appservices.setSession("dial_code", data.result.user.dial_code, false);

            if (data.result.user.city)
              this.appservices.setSession("city", data.result.user.city, false);

            if (data.result.user.state_id)
              this.appservices.setSession("state_id", data.result.user.state_id, false);

            if (data.result.user.country_id)
              this.appservices.setSession("country_id", data.result.user.country_id, false);
            if (data.result.user.dial_country_code)
              this.appservices.setSession("country_name", data.result.user.dial_country_code, false);

            this.appservices.setSession("profile_picture", data.result.user.profile_picture, false);

            this.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_personal_details_entrered', true);
            this.apirequest = [];
            this.apirequest["report_id"] = this.appservices.getSession("ohr_unique_key", false);
            this.apirequest["user_id"] = this.appservices.getSession("user_id", false);
            this.apirequest["guest_user_id"] = this.guest_user_id;
            this.apirequest["widget_token"] = this.appservices.getSession("token", false);
            this.apirequest["overall_score"] = this.appservices.getSession("overall_score", false);
            this.apirequest["selected_branch_id"] = this.appservices.getSession("selected_branch_id", false);
            this.apirequest["health_score_id"] = this.appservices.getSession("health_score_id", false);
            this.apirequest["timezone"] = 'Asia/Calcutta';
            this.apirequest["device_details"] = this.getDeviceInfo();
            let report_input = this.appservices.generteJSONFromArray(this.apirequest);
            this.appservices.callJSONAPI(this.appservices.UPDATE_OHR_USER_ID, report_input, 'post', this, '').subscribe(
              (data: any) => {
                if (data.status) {
                  this.callMedicalQueSaveAPI();
                }
                else {
                  this.router.navigate(['no-internet']);
                }
              });
          } else {
            this.appservices.serviceMessages('vs-messages', data.message, 'danger');
            if (!this.appservices.getSession("token", false)) {
              this.router.navigate(['get-started/' + this.appservices.getSession("token", false)]);
            } else {
              this.router.navigate(['no-internet']);
            }
          }
        },
        (error) => {
          this.router.navigate(['no-internet']);
        }
      );
    }
  }

  callMedicalQueSaveAPI() {
    this.apirequest = [];
    this.apirequest["widget_token"] = this.appservices.getSession("token", false);
    this.apirequest["questions"] = this.appservices.getSession("questions_array", true);
    let input = this.appservices.generteJSONFromArray(this.apirequest);
    this.appservices.callJSONAPI(this.appservices.MEDICAL_QUESTIONNAIRE_SAVE, input, 'post', this, '').subscribe(
      (data: any) => {
        if (data.status) {
          this.redirecting_url = this.appservices.getSession("redirecting_url", false);
          if (!this.appservices.isEmpty(this.redirecting_url)) {
            setTimeout(() => {
              this.appservices.setSession("hasRedirectedFromContactForm", 'true', false);
              this.router.navigate([this.redirecting_url]);
            }, 500);
          }
          else {
            this.uploadImageBtnSelected = this.appservices.getSession("uploadImageBtnSelected", false);
            if (this.uploadImageBtnSelected != undefined && this.uploadImageBtnSelected == 'false') {
              this.router.navigate(['oral-health-score-preference']);
            }
            else if (this.uploadImageBtnSelected != undefined && this.uploadImageBtnSelected == 'true') {
              this.router.navigate(['practice-images']);
            }
          }
        } else {
          this.appservices.serviceMessages('vs-messages', data.message, 'danger');
          this.router.navigate(['no-internet']);
        }
      },
      (error) => {
        this.router.navigate(['no-internet']);
      }
    );
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  get ci() { return this.InputForm.controls; }

  nameLength(classname) {
    if ($("." + classname).val().length > 30) {
      $("." + classname).val($("." + classname).val().substr(0, 30));
    }
  }

  numericOnly(event) {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  countryChange(country: any) {
    this.country_name = country.iso2;
    this.countryCodeNumber = country.dialCode;
    this.appservices.setSession("country_name", this.country_name, false);
    this.appservices.setSession("dial_code", this.countryCodeNumber, false);
    this.appservices.setSession("contactus_country_name", this.country_name, false);
  }

  telInputObject(obj) {
    if (this.appservices.hasSession("contactus_country_name")) {
      this.contactus_country_name = this.appservices.getSession("contactus_country_name", false);
      obj.setCountry(this.contactus_country_name.toUpperCase());
    }
    else {
      obj.setCountry('au');
    }
  }

  setValidators() {
    if (this.country_name == 'au') {
      this.InputForm.get('phoneNumber').setValidators([Validators.required, Validators.pattern(/^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/)]);
      this.InputForm.get('phoneNumber').updateValueAndValidity();
    }
    else {
      this.InputForm.get('phoneNumber').setValidators([Validators.required, Validators.pattern('^[0-9]*$')]);
      this.InputForm.get('phoneNumber').updateValueAndValidity();
    }
  }

  callAppDetailsAPI() {
    this.appservices.callJSONAPI(this.appservices.APPDETAILS + "?", '', 'get', this, '127.0.0.1').subscribe(
      (data: any) => {
        if (data.status) {
          this.appservices.setSession("app_details", data.result.app_details, true);
          this.appservices.setSession("smile_design", data.result.app_details.smile_design, true);
          this.appservices.setSession("gum_types", data.result.app_details.smile_design.gum_types, true);
          this.appservices.setSession("teeth_shade", data.result.app_details.smile_design.teeth_shade, true);
          this.appservices.setSession("teeth_template", data.result.app_details.smile_design.teeth_template, true);
        }
        else {
          this.router.navigate(['no-internet']);
        }
      },
      (error) => {
        this.router.navigate(['no-internet']);
      }
    );
  }

  onSelectPractice(selectedPractice) {
    if (selectedPractice.target.value != '') {
      this.isBranchesButtonDisabled = false;
      this.appservices.setSession("user_selected_practice_branch", selectedPractice.target.value, false);
      let selectedIndex: number = selectedPractice.target["selectedIndex"];
      this.userSelectedAddress = selectedPractice.target.options[selectedIndex].getAttribute("data-isocode");
    }
    else {
      this.userSelectedAddress = '';
      this.isBranchesButtonDisabled = true;
    }
  }

  moveToContactForm() {
    this.display_branches_div = false;
    this.display_contactForm_div = true;
  }

  hasError1(event: any): void {
    if (!event && this.InputForm.value.cellnumber1) {
      this.InputForm.get('cellnumber1').setErrors(['invalid_cell_phone', true]);
    }
  }

  signInHasError(status: any) {
    if (status == null) {
      this.signInValidNumber = false;
      this.phoneSignIn.setValue(null);
    } else {
      this.signInValidNumber = status;
    }
  };

  signInGetNumber(n: string) {
    this.signInPhoneNumber = n;
  };

  signInOnCountryChange(c: any) {
    this.signInSelectedCountryCode = '+' + c.dialCode;
    let up: string = c.iso2;
    this.signInSelectedCountry = up.toUpperCase();
    this.country_name = c.iso2;
    this.countryCodeNumber = c.dialCode;
    this.appservices.setSession("country_name", this.country_name, false);
    this.appservices.setSession("dial_code", this.countryCodeNumber, false);
    this.isSubmitted = false;
  };

  changeDetails() {
    this.backgroundProcessing = false;
    this.sendSubjectMessage = { backgroundProcessing: this.backgroundProcessing };
    this.messageService.sendMessage(this.sendSubjectMessage);
    this.contactFormBtn = false;
    if (this.contactFormSubmitted === true) {
      setTimeout(() => {
        this.onContactFormSubmit();
      }, 500);
    }
  }

  updateImgAfterLoad() {
    this.applyStyle = 'uploadedImage';
    this._cd.detectChanges();
  }

  trigger_upload_btn() {
    $(".upload_here_btn").trigger("click");
  }

  moveCarousel(movementArea: any) {
    if (movementArea === 'left') {
      this.sixthSlideVisited = false;
      $('.next-btn').removeAttr('disabled');
      $(".prev-slide").trigger("click");
      setTimeout(() => {
        this.after_image_upload_div = 'after_image_upload_div_v2';
        this._cd.detectChanges();
        $('.errorImageDiv').removeClass('display_block').addClass('display_none');
      }, 500);
    }
    else {
      $(".next-slide").trigger("click");
    }
  }

  checkImageUploaded() {
    $('.errorImageDiv').removeClass('display_block').addClass('display_none');
    if ($(".upload-teeth-continue-btn").is(":disabled")) {
      $('.errorImageDiv').removeClass('display_none').addClass('display_block');
    }
  }
}

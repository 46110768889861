<!-- India section starts -->
<div class="loader-section" *ngIf="!flag && !isDomainAus">
  <div class="loader_wrapper">
    <div class="loading_div">
      <div class="circle-chekmark analyze_loader">
        <div class="loding-circle">
          <div class="circle-chekmark-border"></div>
          <svg width="200" height="200" class="circle-svg">
            <circle fill="none" stroke="#0074C8" stroke-width="18" cx="100" cy="100" r="90" class="circle"
              stroke-linecap="round" transform="rotate(-90 100 100) " />
          </svg>
        </div>
        <div class="zoomin-circle_tick">
          <div class="circle-chekmark-dark"></div>
          <svg width="400" height="400" class="tick-svg">
            <polyline fill="none" stroke="#ffffff" stroke-width="50" points="88,214 173,284 304,138"
              stroke-linecap="round" stroke-linejoin="round" class="tick" />
          </svg>
        </div>
      </div>
      <div class="art_intelli_para_sec">
        <p class="text-center">Please wait</p>
      </div>
    </div>
  </div>
</div>

<div class="section_wrapper select-reason_wrapper position-relative ind-section" *ngIf="flag && !isDomainAus">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <a href="javascript:void(0)" class="back-btn" (click)="onBack()">
          <i class="sh-chevron-left"></i> <span>back</span>
        </a>
        <div class="wrapper-container">
          <div class="vs-messages"></div>
          <div class="section-heading mb-lg-5 text-lg-center">
            <h1 class="mb-1">Select reason for your visit</h1>
            <p>Select the Option that Best Fits Your Needs</p>
          </div>
          <form autocomplete="off" [formGroup]="InputForm" class="contact_info_form"
            onkeydown="return event.key != 'Enter';">
            <div *ngFor="let data of responsedata; let i = index" id="div_{{ i }}">
              <div class="title_sec" *ngIf="i != 4">
                <div class="">
                </div>
              </div>

              <div class="row" *ngIf="i == 0">
                <div class="col-md-12">
                  <div class="select-reason_wrap d-flex flex-wrap flex-lg-nowrap">
                    <div id="general_checkup"
                      class="select-card position-relative d-flex w-100 flex-lg-column justify-content-between text-lg-center"
                      [ngClass]="
                        medicalQuestionSelectedOption === 'radio3'
                          ? 'medical-questions-selected-option'
                          : ''
                      " (click)="onCheckboxChange($event, 'radio3')">
                      <div class="card-detail d-flex flex-column">
                        <div class="checkbox">
                          <img src="assets/images/uncheck-circle.svg" alt="untick" class="untick" />

                          <img src="assets/images/check-circle.svg" alt="tick" class="tick" />
                        </div>

                        <label class="form-check-label input_name post_label pointer_link mt-1" for="radio3">
                          <input type="radio" class="form-check-input" id="radio3" name="optradio" />

                          <h3 class="mb-1">General Checkup</h3>

                          <p class="mb-lg-2">
                            Evaluate Overall<br />
                            Dental Health
                          </p>
                        </label>
                      </div>

                      <div class="card-img">
                        <img [defaultImage]="defaultImage" [lazyLoad]="staticImage3" [errorImage]="defaultImage"
                          alt="General Checkup" />
                      </div>
                    </div>

                    <div
                      class="select-card position-relative d-flex w-100 flex-lg-column justify-content-between text-lg-center"
                      [ngClass]="
                        medicalQuestionSelectedOption === 'radio1'
                          ? 'medical-questions-selected-option'
                          : ''
                      " (click)="onCheckboxChange($event, 'radio1')">
                      <div class="card-detail d-flex flex-column">
                        <div class="checkbox">
                          <img src="assets/images/uncheck-circle.svg" alt="untick" class="untick" />

                          <img src="assets/images/check-circle.svg" alt="tick" class="tick" />
                        </div>

                        <label class="form-check-label input_name post_label pointer_link mt-1" for="radio1">
                          <input type="radio" class="form-check-input" id="radio1" name="optradio" />

                          <h3 class="mb-1">Toothache</h3>
                          <p class="mb-lg-2">
                            Aching, Chipped<br />
                            or Broken Tooth
                          </p>
                        </label>
                      </div>

                      <div class="card-img">
                        <img [defaultImage]="defaultImage" [lazyLoad]="staticImage1" [errorImage]="defaultImage"
                          alt="Toothache" />
                      </div>
                    </div>

                    <div
                      class="select-card position-relative d-flex w-100 flex-lg-column justify-content-between text-lg-center"
                      [ngClass]="
                        medicalQuestionSelectedOption === 'radio2'
                          ? 'medical-questions-selected-option'
                          : ''
                      " (click)="onCheckboxChange($event, 'radio2')">
                      <div class="card-detail d-flex flex-column">
                        <div class="checkbox">
                          <img src="assets/images/uncheck-circle.svg" alt="untick" class="untick" />

                          <img src="assets/images/check-circle.svg" alt="tick" class="tick" />
                        </div>

                        <label class="form-check-label input_name post_label pointer_link mt-1" for="radio2">
                          <input type="radio" class="form-check-input" id="radio2" name="optradio" />

                          <h3 *ngIf="isUserFromIndia" class="mb-1">
                            Sensitivity & Gum
                          </h3>
                          <p *ngIf="isUserFromIndia" class="mb-lg-2">
                            Bleeding gums, Gum<br />
                            recession, Brush abrasions
                          </p>

                          <p *ngIf="!isUserFromIndia" class="med_head Nunito-Bold">
                            Cosmetic options
                          </p>

                          <span *ngIf="!isUserFromIndia" class="med_span">Whitening, Veeners,<br />

                            Aligners</span>
                        </label>
                      </div>

                      <div class="card-img">
                        <img [defaultImage]="defaultImage" [lazyLoad]="staticImage2" [errorImage]="defaultImage"
                          alt="Cosmetic treatment" />
                      </div>
                    </div>
                    <!-- Teeth Whitening 1 banuba -->
                    <!-- <div
                      class="select-card position-relative d-flex w-100 flex-lg-column justify-content-between text-lg-center"
                      [ngClass]="
                                            medicalQuestionSelectedOption === 'radio4' ? 'medical-questions-selected-option'
                                            : ''" (click)="onCheckboxChange($event, 'radio4')">
                      <div class="card-detail d-flex flex-column">
                        <div class="checkbox">
                          <img src="assets/images/uncheck-circle.svg" alt="untick" class="untick" />
                          <img src="assets/images/check-circle.svg" alt="tick" class="tick" />
                        </div>

                        <label class="form-check-label input_name post_label pointer_link mt-1" for="radio4">
                          <input type="radio" class="form-check-input" id="radio4" name="optradio" />

                          <h3 *ngIf="isUserFromIndia" class="mb-1">
                            Teeth Whitening 1
                          </h3>
                          <p *ngIf="isUserFromIndia" class="mb-lg-2">
                            Content to be <br />added here
                          </p>
                        </label>
                      </div>

                      <div class="card-img">
                        <img [defaultImage]="defaultImage" [lazyLoad]="staticImage3" [errorImage]="defaultImage"
                          alt="Cosmetic treatment" />
                      </div>
                    </div> -->
                    <!-- Teeth Whitening 2 -->
                    <div *ngIf="!isBupaFlow"
                      class="select-card position-relative d-flex w-100 flex-lg-column justify-content-between text-lg-center"
                      [ngClass]="
                        medicalQuestionSelectedOption === 'radio4' ? 'medical-questions-selected-option'
                        : ''" (click)="onCheckboxChange($event, 'radio4')">
                      <div class="card-detail d-flex flex-column">
                        <div class="checkbox">
                          <img src="assets/images/uncheck-circle.svg" alt="untick" class="untick" />
                          <img src="assets/images/check-circle.svg" alt="tick" class="tick" />
                        </div>

                        <label class="form-check-label input_name post_label pointer_link mt-1" for="radio4">
                          <input type="radio" class="form-check-input" id="radio4" name="optradio" />

                          <h3 *ngIf="isUserFromIndia" class="mb-1">
                            Teeth Whitening
                          </h3>
                          <p *ngIf="isUserFromIndia" class="mb-lg-2">
                            Check Your Whitening Options
                          </p>
                        </label>
                      </div>

                      <div class="card-img">
                        <img [defaultImage]="defaultImage" [lazyLoad]="staticImage3" [errorImage]="defaultImage"
                          alt="Cosmetic treatment" />
                      </div>
                    </div>
                  </div>

                  <div class="action-button">
                    <div class="pt-sm-5 mt-sm-3">
                      <button class="btn btn-primary btn-rounded-corner btn-icon-right btn-lg mx-auto"
                        [disabled]="continueBtnDisabled" (click)="onContinue()">
                        Continue
                        <i class="icon_arrow icon-right-arrow"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <app-footer></app-footer>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- India section ends -->

<!-- Australia section starts -->
<div class="dynamic_wrapper position-relative text-center medical_questions aus-section" *ngIf="isDomainAus">
  <div>
    <div class="loading_div analysing_sec sub_wrapper p-3" *ngIf="!flag">
      <div class="img_section text-center position-relative">
        <div class="loader_img_sec mt-4">
          <img src="assets/images/loader.gif" />
        </div>
        <div class="analyzing_text mb-4">
          <p class="thank_you_head">Please Wait</p>
        </div>
      </div>
    </div>
    <div *ngIf="flag" class="dynamic_wrapper_custom">
      <div class="vs-messages"></div>
      <form autocomplete="off" [formGroup]="InputForm" class="contact_info_form"
        onkeydown="return event.key != 'Enter';">
        <div *ngFor="let data of responsedata let i = index" id=div_{{i}}>
          <div class="title_sec" *ngIf="i!=4">
            <div class="">
            </div>
          </div>
          <div class="row mx-0 main_div" *ngIf="i==0">
            <h5 class="Nunito-Bold font_12 select_opt w-100 text-center">SELECT AN OPTION</h5>
            <h5 class="sub_head mb-3 text-center w-100">For your dental check, you have a few options. Select your
              preferred option below.</h5>
            <div id="general_checkup" class="col-md-4 medical-questions-options-div p-0 pointer_link"
              [ngClass]="medicalQuestionSelectedOption === 'radio3' ? 'medical-questions-selected-option' : ''"
              (click)="onCheckboxChange($event,'radio3')">
              <div class="bg_div">
                <div class="checkbox">
                  <img src="assets/images/untick.png" alt="untick" class="untick">
                  <img src="assets/images/tick.png" alt="tick" class="tick">
                </div>
                <div class="bg_div_responsive">
                  <div class="select_image">
                    <img [defaultImage]="defaultImage" [lazyLoad]="staticImage3" [errorImage]="defaultImage"
                      alt="General Checkup">
                  </div>

                  <label class="form-check-label input_name post_label pointer_link mt-1" for="radio3">
                    <input type="radio" class="form-check-input" id="radio3" name="optradio">
                    <p class="med_head Nunito-Bold">General Check</p>
                    <span class="med_span">General check</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-md-4 medical-questions-options-div p-0 pointer_link"
              [ngClass]="medicalQuestionSelectedOption === 'radio1' ? 'medical-questions-selected-option' : ''"
              (click)="onCheckboxChange($event,'radio1')">
              <div class="bg_div">
                <div class="checkbox">
                  <img src="assets/images/untick.png" alt="untick" class="untick">
                  <img src="assets/images/tick.png" alt="tick" class="tick">
                </div>
                <div class="bg_div_responsive">
                  <div class="select_image">
                    <img [defaultImage]="defaultImage" [lazyLoad]="staticImage1" [errorImage]="defaultImage"
                      alt="Toothache">
                  </div>

                  <label class="form-check-label input_name post_label pointer_link mt-1" for="radio1">
                    <input type="radio" class="form-check-input" id="radio1" name="optradio">
                    <p class="med_head Nunito-Bold">Toothache</p>
                    <span class="med_span">Aching, Chipped or <br>Broken teeth</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-md-4 medical-questions-options-div p-0 pointer_link"
              [ngClass]="medicalQuestionSelectedOption === 'radio2' ? 'medical-questions-selected-option' : ''"
              (click)="onCheckboxChange($event,'radio2')">
              <div class="bg_div">
                <div class="checkbox">
                  <img src="assets/images/untick.png" alt="untick" class="untick">
                  <img src="assets/images/tick.png" alt="tick" class="tick">
                </div>
                <div class="bg_div_responsive">
                  <div class="select_image">
                    <img [defaultImage]="defaultImage" [lazyLoad]="staticImage2" [errorImage]="defaultImage"
                      alt="Cosmetic treatment">
                  </div>

                  <label class="form-check-label input_name post_label pointer_link mt-1" for="radio2">
                    <input type="radio" class="form-check-input" id="radio2" name="optradio">
                    <p class="med_head Nunito-Bold">Cosmetic options</p>
                    <span class="med_span">Whitening, Veeners,<br> Aligners</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="fixed_div w-100">
              <div class="col-lg-12 col-md-12 col-sm-12 mt-4 termsAndConditionsDiv ">
                <div class="center-block" style="margin:0px auto">
                  <div class="checkbox_rounded d-flex align-items-center justify-content-center">
                    <span class="position-relative mt-1">
                      <input id="agree" [checked]="checkBoxValue"
                        (change)="isCheckboxChecked($event.target.checked ? 'on' : 'off')" type="checkbox" name="agree">
                      <label class="checkbox_label" for="agree"></label>
                    </span>
                    <span class="check_text agree_text font_16 ml-3"><span class="grey_color">I have read and agree to
                        Smilo.ai's </span><a target="_blank" href="{{consent_form}}">Consent Form</a>, <a
                        target="_blank" href="{{terms_site}}">Terms and Conditions</a>, and <a target="_blank"
                        href="{{privacy_site}}">Privacy Policy</a></span>
                  </div>
                  <div *ngIf="termsAndConditions=='0'"
                    class="error font_14 checkbox_rounded d-flex align-items-center justify-content-center">
                    <div class="text-left termsandconditionstext font_16 mt-1 mb-1">Please Accept Terms and Conditions
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn button_links btn_width_2 continue_btn mt-2 mb-lg-0 mb-2"
                [disabled]="continueBtnDisabled" (click)="onContinue()">Agree and Continue</button>
            </div>
          </div>
        </div>
      </form>
      <app-footer></app-footer>
    </div>
  </div>
</div>
<!-- Australia section end -->
import { Component, OnInit } from "@angular/core";
import { environment } from "../../../src/environments/environment";
import { Services } from "../../app/helpers/Services";
import { HttpClient } from "@angular/common/http";
// import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Router, ActivatedRoute } from "@angular/router";

import { v4 as uuidv4 } from "uuid";
import { async } from "rxjs/internal/scheduler/async";
import { DomainService } from "src/core/service/domain/domain.service";
declare var gtag;
declare const fbq;

@Component({
  selector: "app-get-started",
  templateUrl: "./get-started.component.html",
  styleUrls: ["./get-started.component.css"],
})
export class GetStartedComponent implements OnInit {
  appservices: Services;
  apirequest: any[] = [];
  input: any;
  token: string;
  flag: boolean = false;
  branchesList: any = {};
  widget_service_type: any = "0";
  guest_session_uuid: any = "";
  guest_user_ip_addr: any = "";
  public isDomainAus: any;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private domainService: DomainService
  ) {
    this.isDomainAus = this.domainService.isAusRegionDomain("");
    this.appservices = new Services();
  }
  async getLocationAddress() {
    var res: any = await this.httpClient
      .get(environment.IPDATA_API + "?api-key=" + environment.IPDATA_KEY)
      .toPromise();

    let locationDetails = {
      calling_code: "91",
      city: "Kolkata",
      country_code: "IN",
      country_name: "India",
      ip: "103.75.160.75",
      state: "undefined",
      time_zone: "Asia/Kolkata",
      time_zone_offset: "+0530",
    };
    this.guest_user_ip_addr = res["ip"];
    this.appservices.setSession("location_details", locationDetails, true);
  }
  async ngOnInit() {
    localStorage.setItem("Input_form_data", "");
    this.appservices.setSession("city", "", false);
    this.appservices.setSession("state_id", "", false);
    this.appservices.setSession("country_id", "", false);
    this.appservices.setSession("user_selected_practice_branch", "", false);
    this.appservices.setSession("isUserLoggedIn", "false", false);
    this.appservices.setSession("country_name", "", false);

    this.appservices.setSession("shade_id", "", false);
    this.appservices.setSession("gum_image", "", false);
    this.appservices.setSession("template_id", "", false);
    this.appservices.setSession("template_image", "", false);
    this.appservices.setSession("teeth_shade_id", "", false);
    this.appservices.setSession("teeth_shade_img", "", false);

    await this.getLocationAddress();
    this.token = this.route.snapshot.params.token;
    this.appservices.sendAnalyticsData(
      "",
      "",
      "smilo_event",
      "sa_wg_open",
      true
    );
    this.guest_session_uuid = uuidv4();
    if (!this.token) this.token = environment.SMILO_TOKEN;
    this.appservices.clearSession();
    this.appservices
      .callJSONAPI(
        this.appservices.APPDETAILS + "?",
        "",
        "get",
        this,
        "127.0.0.1"
      )
      .subscribe(
        (data: any) => {
          // console.log(data);
          if (data.status) {
            this.appservices.setSession(
              "app_details",
              data.result.app_details,
              true
            );
            this.appservices.setSession(
              "smile_design",
              data.result.app_details.smile_design,
              true
            );
            this.appservices.setSession(
              "gum_types",
              data.result.app_details.smile_design.gum_types,
              true
            );
            this.appservices.setSession(
              "teeth_shade",
              data.result.app_details.smile_design.teeth_shade,
              true
            );
            this.appservices.setSession(
              "teeth_template",
              data.result.app_details.smile_design.teeth_template,
              true
            );
          } else {
          }
        },
        (error) => {
          this.appservices.showDebugMessage(error);
        }
      );
    // console.log("called");
    this.appservices
      .callJSONAPI(
        this.appservices.VERIFY_WIDGIT_TOKEN +
          "?token=" +
          this.token +
          "&guest_session_user_id=" +
          this.guest_session_uuid +
          "&guest_user_ip_addr=" +
          this.guest_user_ip_addr,
        "",
        "get",
        this,
        ""
      )
      .subscribe(
        (data: any) => {
          this.flag = true;
          if (data.status) {
            this.appservices.setSession("payment_successful", "false", false);
            this.appservices.setSession(
              "practice_id",
              data.result.practice.id,
              false
            );
            if (data.result.practice.is_dabur_flow) {
              this.appservices.setSession(
                "isDaburFlow",
                data.result.practice.is_dabur_flow == "true",
                false
              );
            } else {
              this.appservices.setSession("isDaburFlow", "false", false);
            }
            /*Bupa Implementation */
            if (this.token == 'BWzZ2nPAnR881r0fkhsOHsjDiZgsEdFJsR7oX3PxyW5CUVZETL') {
              this.appservices.setSession(
                "isBupaFlow",
                true,
                false
              );
            } else {
              this.appservices.setSession("isBupaFlow", "false", false);
            }
            if (data.result.practice.images_selection_count) {
              this.appservices.setSession(
                "images_selection_count",
                data.result.practice.images_selection_count,
                false
              );
            } else {
              this.appservices.setSession("images_selection_count", 5, false);
            }

            if (data.result.practice.is_otp_verified) {
              this.appservices.setSession(
                "user_otp_verification_process",
                data.result.practice.is_otp_verified === "true",
                false
              );
            } else {
              this.appservices.setSession(
                "user_otp_verification_process",
                "false",
                false
              );
            }
            if (data.result.practice.is_request_for_appointment) {
              this.appservices.setSession(
                "is_request_for_appointment",
                data.result.practice.is_request_for_appointment === "true",
                false
              );
            } else {
              this.appservices.setSession(
                "is_request_for_appointment",
                "false",
                false
              );
            }
            if (data.result.practice.is_request_for_coupan_code) {
              this.appservices.setSession(
                "is_request_for_coupan_code",
                data.result.practice.is_request_for_coupan_code === "true",
                false
              );
            } else {
              this.appservices.setSession(
                "is_request_for_coupan_code",
                "false",
                false
              );
            }
            if (data.result.practice.is_purchase_detailed_report) {
              this.appservices.setSession(
                "is_purchase_detailed_report",
                data.result.practice.is_purchase_detailed_report === "true",
                false
              );
            } else {
              this.appservices.setSession(
                "is_purchase_detailed_report",
                "false",
                false
              );
            }

            if (data.result.practice.widget_benefit_1)
              this.appservices.setSession(
                "widget_benefit_one",
                data.result.practice.widget_benefit_1,
                false
              );

            if (data.result.practice.widget_benefit_2)
              this.appservices.setSession(
                "widget_benefit_two",
                data.result.practice.widget_benefit_2,
                false
              );

            if (data.result.practice.widget_benefit_3)
              this.appservices.setSession(
                "widget_benefit_three",
                data.result.practice.widget_benefit_3,
                false
              );

            if (!this.appservices.isEmpty(data.result.practice.fb_pixel_id)) {
              this.appservices.setSession(
                "fb_pixel_id",
                data.result.practice.fb_pixel_id,
                false
              );
              fbq("init", data.result.practice.fb_pixel_id);
              fbq("track", "PageView");
            } else {
              this.appservices.setSession("fb_pixel_id", "", false);
            }
            if (window.location.host.toLowerCase() != "get.smilo.ai") {
              if (!this.appservices.isEmpty(data.result.practice.gtm_id)) {
                if (environment.ENV == "Production")
                  gtag("config", data.result.practice.gtm_id, {
                    send_page_view: false,
                    debug_mode: true,
                  });
                else
                  gtag("config", data.result.practice.gtm_id, {
                    send_page_view: false,
                    debug_mode: true,
                  });
                this.appservices.setSession(
                  "gtm_id",
                  data.result.practice.gtm_id,
                  false
                );
              } else this.appservices.setSession("gtm_id", "", false);
            } else {
              this.appservices.setSession("gtm_id", "UA-162266546-1", false);
            }
            if (data.result.is_d4w && this.isDomainAus) {
              let d4wObj = {
                is_d4w: data.result.is_d4w,
                d4w_practice_id: data.result.d4w_practice_id,
              };
              this.appservices.setSession("is_d4w", d4wObj, true);
            }

            if (
              data.result.practice.selected_branch_id != undefined &&
              data.result.practice.selected_branch_id != ""
            )
              this.appservices.setSession(
                "user_selected_practice_branch",
                data.result.practice.selected_branch_id,
                false
              );
            this.branchesList = data.result.practice.branches;
            this.appservices.setSession("has_branches", "no", false);
            if (
              this.branchesList != undefined &&
              this.branchesList.length > 0
            ) {
              this.appservices.setSession("has_branches", "yes", false);
              this.appservices.setSession(
                "branches_list",
                JSON.stringify(this.branchesList),
                false
              );
            }

            if (!this.appservices.isEmpty(data.result.practice.cta_type)) {
              this.appservices.setSession(
                "cta_type",
                data.result.practice.cta_type,
                false
              );
              this.appservices.setSession(
                "button_text",
                data.result.practice.button_text,
                false
              );
              this.appservices.setSession(
                "button_url",
                data.result.practice.button_url,
                false
              );
            }

            this.appservices.setSession("token", this.token, false);

            if (data.result.practice.button_details.length > 0) {
              this.appservices.setSession(
                "button_text_details",
                JSON.stringify(data.result.practice.button_details),
                false
              );
            }

            if (
              !this.appservices.isEmpty(data.result.practice.widget_button_type)
            ) {
              this.widget_service_type =
                data.result.practice.widget_button_type;
              this.appservices.setSession(
                "widget_button_type",
                data.result.practice.widget_button_type,
                false
              );
            } else {
              this.appservices.setSession("widget_button_type", "0", false);
            }

            if (!this.appservices.isEmpty(data.result.practice.payment_title)) {
              this.appservices.setSession(
                "payment_title",
                data.result.practice.payment_title,
                false
              );
            }

            if (
              !this.appservices.isEmpty(data.result.practice.payment_sub_title)
            ) {
              this.appservices.setSession(
                "payment_sub_title",
                data.result.practice.payment_sub_title,
                false
              );
            }

            if (
              !this.appservices.isEmpty(data.result.practice.benefits_title)
            ) {
              this.appservices.setSession(
                "benefits_title",
                data.result.practice.benefits_title,
                false
              );
            }

            if (
              !this.appservices.isEmpty(data.result.practice.benefits_sub_title)
            ) {
              this.appservices.setSession(
                "benefits_sub_title",
                data.result.practice.benefits_sub_title,
                false
              );
            }

            if (!this.appservices.isEmpty(data.result.practice.benefits_one)) {
              this.appservices.setSession(
                "benefits_one",
                data.result.practice.benefits_one,
                false
              );
            }

            if (!this.appservices.isEmpty(data.result.practice.benefits_two)) {
              this.appservices.setSession(
                "benefits_two",
                data.result.practice.benefits_two,
                false
              );
            }

            if (
              !this.appservices.isEmpty(data.result.practice.benefits_three)
            ) {
              this.appservices.setSession(
                "benefits_three",
                data.result.practice.benefits_three,
                false
              );
            }

            if (!this.appservices.isEmpty(data.result.practice.amount)) {
              this.appservices.setSession(
                "payment_amount",
                data.result.practice.amount,
                false
              );
            }
            if (!this.appservices.isEmpty(data.result.practice.display_medical_history_form)) {
              this.appservices.setSession("display_medical_history_form", data.result.practice.display_medical_history_form, false);
            }

            if (
              !this.appservices.isEmpty(data.result.practice.title_for_button)
            ) {
              this.appservices.setSession(
                "title_for_button",
                data.result.practice.title_for_button,
                false
              );
            }

            if (
              !this.appservices.isEmpty(
                data.result.practice.payment_button_text
              )
            ) {
              this.appservices.setSession(
                "payment_button_text",
                data.result.practice.payment_button_text,
                false
              );
            }

            if (
              !this.appservices.isEmpty(data.result.practice.payment_gateway)
            ) {
              this.appservices.setSession(
                "payment_gateway",
                data.result.practice.payment_gateway,
                false
              );
            }

            if (!this.appservices.isEmpty(data.result.practice.payment_type)) {
              this.appservices.setSession(
                "payment_type",
                data.result.practice.payment_type,
                false
              );
              if (data.result.practice.payment_type === "stripe") {
                if (!this.appservices.isEmpty(data.result.practice.api_key)) {
                  this.appservices.setSession(
                    "api_key",
                    data.result.practice.api_key,
                    false
                  );
                }
                if (
                  !this.appservices.isEmpty(data.result.practice.secret_key)
                ) {
                  this.appservices.setSession(
                    "secret_key",
                    data.result.practice.secret_key,
                    false
                  );
                }
              }
            }

            // console.log(" data.result.practice.payment_enabled :  " + data.result.practice.payment_enabled );

            if (
              !this.appservices.isEmpty(data.result.practice.payment_enabled)
            ) {
              if (data.result.practice.payment_enabled == "1")
                this.appservices.setSession(
                  "widget_flow_type",
                  "payment",
                  false
                );
            }

            if (!this.appservices.isEmpty(data.result.practice.ohr_flow)) {
              this.appservices.setSession(
                "ohr_flow",
                data.result.practice.ohr_flow,
                false
              );
            }

            this.appservices.setSession(
              "get_contact_information_later",
              "",
              false
            );
            this.appservices.setSession("guest_user_id", "", false);

            this.appservices.setSession("redirecting_url", "", false);

            if (
              !this.appservices.isEmpty(
                data.result.practice.get_contact_information_later
              )
            ) {
              this.appservices.setSession(
                "get_contact_information_later",
                data.result.practice.get_contact_information_later,
                false
              );
              this.appservices.setSession(
                "guest_session_uuid",
                this.guest_session_uuid,
                false
              );
              if (
                !this.appservices.isEmpty(data.result.practice.guest_user_id)
              ) {
                this.appservices.setSession(
                  "guest_user_id",
                  data.result.practice.guest_user_id,
                  false
                );
              }
            }

            if (
              !this.appservices.isEmpty(
                data.result.practice.bypass_select_service
              )
            ) {
              this.appservices.setSession(
                "bypass_select_service",
                data.result.practice.bypass_select_service,
                false
              );

              if (
                this.widget_service_type == "2" ||
                data.result.practice.bypass_select_service == "0"
              ) {
                this.router.navigate(["select-service"]);
              } else {
                this.widget_service_type = this.appservices.getSession(
                  "widget_button_type",
                  false
                );
                if (this.widget_service_type == "0") {
                  this.appservices.setSession(
                    "uploadImageBtnSelected",
                    false,
                    false
                  );

                  if (this.isDomainAus == true) {
                    this.router.navigate(["purpose-of-visit"]);
                  } else {
                    this.router.navigate(["getting-started"]); // india
                  }
                } else if (this.widget_service_type == "1") {
                  this.appservices.setSession(
                    "uploadImageBtnSelected",
                    true,
                    false
                  );
                  this.router.navigate(["contact-information"]);
                }
              }
            }
          } else {
            this.router.navigate(["invalid-access"]);
          }
        },
        (error) => {
          this.appservices.showDebugMessage(error);
        }
      );
  }
}
